import styled from 'styled-components';
import { colors } from '../../styles';

export const titleColor = {
  primary: colors.white,
  secondary: colors.white,
  inline: colors.black,
  inlineBlue: colors.newBlue,
  third: colors.black,
  fourth: colors.white,
};

export const bgColor = {
  primary: colors.newBlue,
  secondary: colors.black,
  inline: colors.transparent,
  inlineBlue: colors.transparent,
  third: colors.white,
  fourth: colors.newBlue,
};

export const Container = styled.a`
  width: ${(props) => props.width || ''};
  height: ${(props) => props.height || 48}px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.93rem;
  cursor: ${(props) => (props.buttonLoading ? 'default' : 'pointer')};
  outline: none;

  transition: background-color 0.3s ease-out;

  background-color: ${(props) => bgColor[props.buttonType]};
  color: ${(props) => titleColor[props.buttonType]} !important;

  border: transparent;
  border-radius: ${props => props.borderRadius || 10}px;
  padding: ${(props) => props.paddingVertical || 20}px ${props => props.paddingHorizontal || 35}px;
  margin: ${(props) => props.marginTop || 0}px
    ${(props) => props.marginRight || 0}px
    ${(props) => props.marginBottom || 0}px
    ${(props) => props.marginLeft || 0}px;

  &:active {
    transform: ${(props) => (props.buttonLoading ? 'scale(1)' : 'scale(0.98)')};
  }

  &:hover {
    transition: all ease-out 0.3s;
    .move-top {
      margin-top: -2px;
      transition: all ease-out 0.3s;
    }
  }
  
  &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
      .move-top {
        margin-top: 0;
      }
    }
    :disabled:active {
      transform: scale(1);
    }
  }

  //
  // ONLY PRIMARY TYPE
  &.primary:hover {
    background-color: #0f80ff;
    background-color: ${(props) => props.buttonLoading ? colors.primary : '#0f80ff'};
  }

  &.primary:disabled:hover {
    background-color: ${colors.primary};
  }

  //
  // ONLY SECONDARY TYPE
  &.secondary:hover {
    background-color: #000000;
  }

  &.secondary:disabled:hover {
    background-color: ${colors.black};
  }  
  
  //
  // ONLY SECONDARY TYPE
  &.third:hover {
    background-color: #ffffff;
  }

  &.third:disabled:hover {
    background-color: ${colors.white};
  }
  
  
  //
  // ONLY INLINE TYPE
  &.inline, &.inlineBlue {
    transition: 0.3s ease-out;
    color: ${colors.black} !important;
    border: 2px solid ${(props) => props.noInlineBorder ? colors.transparent : colors.black};
    background-color: ${colors.transparent};
    padding: ${(props) => props.paddingVertical || 19}px ${props => props.paddingHorizontal || 19}px; 
  }

  &.inlineBlue {
    border: 1px solid ${colors.newBlue} !important;
  }

  /* &.inline:hover {
    border-width: ${(props) => (props.buttonLoading ? '1px' : '2px')};
    padding: ${(props) => (props.padding ? props.padding - 2 : 18)}px;
    background-color: ${(props) => props.grayHover ? colors.gray : colors.transparent}; 
  } */

  &.inline:disabled:hover {
    border-width: 1px;
    padding: ${(props) => props.paddingVertical || 19}px ${props => props.paddingHorizontal || 19}px;
  }
`;
