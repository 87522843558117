import styled from 'styled-components';
import { colors } from '~/styles';
import { transparentize } from "polished";

export const FaqContainer = styled.div`
  padding: 100px 0;
  background: ${colors.whiteLight};

  .accordion-line {
    border-bottom: 1px solid #d9d9d9 !important;
  }
  .accordion-panel-anim {
    overflow: hidden;
    display: block !important;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    margin-bottom: 98px;
  }

  .accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: ${colors.transparent};
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    padding: 40px 0;

    position: relative;
    cursor: pointer;
    transition: 0.3s all ease-out;

    &.active {
      padding-bottom: 60px;
      transition: 0.3s all ease-out;
    }

    .accordion-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding-bottom: 10px;
      background-color: ${colors.whiteLight};
      transition: 0.3s all ease-out;
      z-index: 99;
    }

    .accordion-description {
      opacity: 0;
      margin-top: 24px;

      position: absolute;
      top: 10px;
      transition: 0.3s all ease-out;
      z-index: 9;
    }

    .accordion-description.active {
      opacity: 1;
      top: 50px;
      transition: 0.3s all ease-out;
    }
  }

  .accordion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: ${colors.transparent};
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: 20px;
    padding-top: 40px;

    a {
      font-size: 17px;
      color: #272937;

      &:hover {
        color: #272937;
      }
    }

    .accordion-arrow {
      width: 32px;
      height: 32px;
      padding: 8px;
      background: ${transparentize(0.9, `#0F90FF`)};
      border-radius: 50px;
    }
  }
`;
