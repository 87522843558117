import React from 'react';
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { colors } from "~/styles";
import * as Text from "~/styles/text";
import * as Style from "./TermosDeUso.styles";

import Footer from "~/components/Footer";
import Menu from "~/components/Menu";

import FileEN from "../../../i18n/termos-de-uso-en.md";
import FilePT from "../../../i18n/termos-de-uso-pt.md";

function TermosDeUso() {
  const { t, i18n } = useTranslation();

  const [content, setContent] = useState("");


  useEffect(() => {
    if(localStorage.i18nextLng === 'pt-BR') {
      fetch(FilePT)
        .then((res) => res.text())
        .then((text) => setContent(text));
    } else {
      fetch(FileEN)
      .then((res) => res.text())
      .then((text) => setContent(text));
    }
    
  }, [localStorage.i18nextLng]);

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  {t("useTerms.title")}
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col>
              <p>
                {/* <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} /> */}

                <iframe src="https://storage.googleapis.com/static.capitual.net/site/files/termos-de-uso.pdf#view=FitH" width="100%" height="800px" type="application/pdf"></iframe>
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default TermosDeUso;