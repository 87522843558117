import EditIcon from '~/images/menu/edit.svg';
import PressIcon from '~/images/menu/press.svg';
import BriefcaseIcon from '~/images/menu/briefcase.svg';
import BusinessIcon from '~/images/menu/business.svg';
import OtcIcon from '~/images/menu/otc.svg';
import CapLabsIcon from '~/images/menu/caplabs.svg';
import LogoCIcon from '~/images/menu/logo-c.svg';
import CapPayIcon from '~/images/menu/cappay.svg';
import SettleIcon from '~/images/menu/settle.svg';
import HeartIcon from '~/images/menu/heart.svg';
import SupportIcon from '~/images/menu/support.svg';
import StatusIcon from '~/images/menu/status.svg';
import LimiterIcon from '~/images/menu/limiter.svg';

import baseUrl from "~/config";

export const menuOptions = [
  {
    menuTitle: "menu.about.title",
    submenu: [
      {
        icon: HeartIcon,
        route: "",
        label: "menu.about.about",
        enbabled: false,
      },
      {
        icon: EditIcon,
        route: "https://blog.capitual.com",
        label: "menu.about.blog",
        enbabled: true,
      },
      {
        icon: PressIcon,
        route: "https://blog.capitual.com/imprensa/",
        label: "menu.about.pressroom",
        enbabled: true,
      },
      {
        icon: BriefcaseIcon,
        route: baseUrl.ENDPOINT[0] + "/careers",
        label: "menu.about.careers",
        enbabled: true,
      }
    ]
  },
  {
    menuTitle: "menu.products.title",     
    submenu: [
      {
        icon: LogoCIcon,
        route:  baseUrl.ENDPOINT[0] + "#CapConta",
        route2: "#CapConta",
        label: "menu.products.account",
        enbabled: true,
      },
      {
        icon: BusinessIcon,
        route:  "https://business.capitual.com/",
        route2: "#CapWallets",
        label: "menu.products.corporate",
        enbabled: true,
      },
      {
        icon: SettleIcon,
        route: "",
        label: "menu.products.settle",
        enbabled: false,
      },
      {
        icon: CapPayIcon,
        route: "https://cappay.capitual.com/",
        label: "menu.products.capPay",
        enbabled: true,
      },      
      {
        icon: OtcIcon,
        route: baseUrl.ENDPOINT[0] + "/products/capotc",
        label: "menu.products.capOtc",
        enbabled: true,
      },
      {
        icon: CapLabsIcon,
        route: "",
        label: "menu.products.capLabs",
        enbabled: false,
      },      
    ]
  },
  // {
  //   menuTitle: "menu.portfolio.title",     
  //   submenu: [
  //     {
  //       icon: CoinsIcon,
  //       route: "",
  //       label: "menu.portfolio.criptocurrencies",
  //       enbabled: true,
  //     },
  //     {
  //       icon: Money2Icon,
  //       route: "",
  //       label: "menu.portfolio.fiat",
  //       enbabled: true,
  //     },
  //     {
  //       icon: MetalIcon,
  //       route: "",
  //       label: "menu.portfolio.metals",
  //       enbabled: true,
  //     },
  //     {
  //       icon: ClipboardIcon,
  //       route: "",
  //       label: "menu.portfolio.tokens",
  //       enbabled: false,
  //     }
  //   ]
  // },
  {
    menuTitle: "menu.help.title",
    submenu: [
      {
        icon: SupportIcon,
        route: "https://help.capitual.com/",
        label: "menu.help.helpCenter",
        enbabled: true,
      },
      {
        icon: StatusIcon,
        route: "https://status.capitual.com/",
        label: "menu.help.status",
        enbabled: true,
      },
      {
        icon: LimiterIcon,
        route: "https://exchange.capitual.com/",
        label: "menu.help.fees",
        enbabled: true,
      }
    ]
  }
];