import React from "react";
import * as Style from "./Precatorio.styles";

import Banner from "./Sections/1-Banner";
import Advantages from "./Sections/2-Advantages";
import WhatFor from "./Sections/3-WhatFor";
import PrecatorioSection from "./Sections/4-PrecatorioSection";
import DownloadApp from "./Sections/5-DownloadApp";
import Faq from "./Sections/6-Faq";

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";

function Precatorio() {
  return (
    <Style.Container>
      <Menu isDarkMenu />

      <Banner />

      <Advantages />

      <WhatFor />

      <PrecatorioSection />

      <DownloadApp />

      <Faq />

      <Footer />
    </Style.Container>
  );
}

export default Precatorio;
