import React, { useEffect, useState } from "react";
import { colors } from "~/styles";
import * as Style from "./2.DetailsVacancy.styles";
import * as Text from "~/styles/text";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "~/hooks/windowDimension";
import baseUrl from "~/config";
import axios from "axios";

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";

const pageWidth = [1045];

function DetailsVacancy() {
  const location = useLocation();
  const navigate = useHistory();

  const [pageData, setPageData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const { width } = useWindowDimensions();

  useEffect(() => {
    axios.get(`${baseUrl.ENDPOINT[1]}/api/vacancies?populate=deep`)
      .then(res => {
        setCategoryData(res.data.data);

        let path;

        res.data.data.map((item) => {
          item.attributes.carrers.data.some((subitem) => {
            if (subitem.attributes.url_path === location.pathname) {
              path = subitem.attributes.url_path;
            }
          })
        });

        if (path === location.pathname) {
          console.log('its okay');
        } else {
          navigate.push("/");
        }

      });

    axios.get(`${baseUrl.ENDPOINT[1]}/api/capitual-com-carrer-detail`)
      .then(res => {
        setPageData(res.data.data);
      });
  }, []);

  return (
    <>
      {categoryData?.map((item) => (
        <>
          {item?.attributes?.carrers?.data.map((subItem) => (
           subItem?.attributes?.url_path === location.pathname && (

            <Style.Container>
              <Style.Header>
                <Menu />

                <Container>
                  <Row>
                    <Col>
                      <div className="banner">
                        <div className="header-icon-container">
                          <img src={item?.attributes?.Icon?.data?.attributes?.url} />

                          <Text.Heading1Medium
                            fontSize="22px"
                            color={colors.white}
                          >
                            {item?.attributes?.Title}
                          </Text.Heading1Medium>
                        </div>
                        <Text.Heading1Medium
                          fontSize="45px"
                          lineHeight={130}
                          color={colors.white}
                        >
                          {subItem?.attributes?.Title}
                        </Text.Heading1Medium>

                        <Text.Heading1Medium
                          fontSize="18px"
                          color={colors.white}
                          marginTop={10}
                        >
                          {subItem?.attributes?.Subtitle}
                        </Text.Heading1Medium>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Style.Header>

              <div className="content">
                <Container>
                  <Row>
                    <Col className="mb-60">
                      <Text.Heading2
                        fontSize="31px"
                        lineHeight={130}
                        marginBottom={30}
                      >
                        {subItem?.attributes?.DescriptionTitle}
                      </Text.Heading2>


                      <div className="description-content">
                        <Text.Heading1Regular
                          fontSize="18px"
                          lineHeight={160}
                          marginBottom={20}
                        >
                          {subItem?.attributes?.DescriptionSubtitle}
                        </Text.Heading1Regular>
                      </div>

                    </Col>
                  </Row>

                  {
                    subItem?.attributes?.RequirementsList.map((requirementItem) => (
                      <Row key={requirementItem.id} className="description-container">
                        <Col xl={4}>
                          <Text.Heading1Medium fontSize="31px" className="description-container-title">
                            {requirementItem?.Title}
                          </Text.Heading1Medium>
                        </Col>
    
                        <Col xl={8}>
                          <div className="description-content">
                            <Text.Heading1Regular fontSize="18px">
                              {requirementItem?.Description}
                            </Text.Heading1Regular>
                          </div>
                        </Col>
                      </Row>
                    ))
                  }
                </Container>

                <Container className="mt-100">
                  <Row>
                    <Col />
                    <Col>
                      <a href={subItem?.attributes?.vacancyLink || 'https://capitual.factorialhr.com.br/#jobs'} target="_blank" className="link-button">Candidate-se agora</a>
                    </Col>
                    <Col />
                  </Row>
                </Container>
              </div>

              <Style.WorkWithUsContainer>
                <Text.Heading2Bold
                  fontSize="44px"
                  marginBottom={30}
                  textAlign="center"
                >
                  {pageData?.attributes?.OurTeamTitle}
                </Text.Heading2Bold>

                <Text.Heading1Regular
                  fontSize="21px"
                  textAlign="center"
                  width={width > pageWidth[0] ? `50%` : `90%`}
                >
                  <p>{pageData?.attributes?.OurTeamSubtitle} <a href={`mailto:${pageData?.attributes?.Mail}`}>{pageData?.attributes?.Mail}</a> {pageData?.attributes?.OurTeamSubtitle2} <a href={`mailto:${pageData?.attributes?.Mail}?subject=Vaga ${subItem?.attributes?.Title}`}>“Vaga {subItem?.attributes?.Title}”</a>. {pageData?.attributes?.OurTeamSubtitle3}</p>
                </Text.Heading1Regular>

                {/* <div className="description-content">
                  <Text.Heading1Regular fontSize="18px" textAlign="center">
                    {pageData?.attributes?.ThankText}
                  </Text.Heading1Regular>
                </div> */}
              </Style.WorkWithUsContainer>

              <Footer />
            </Style.Container>
           )
          ))}
        </>
      ))}
    </>
  );
}

export default DetailsVacancy;
