import React, { useState, useEffect } from "react";
import { Reveal, Fade } from "react-reveal";
import { useTranslation, Trans } from "react-i18next";
import useDetectDevice from "~/hooks/detectDevice";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import colors from "~/styles/colors";

import { menuOptions } from "~/data/menu";

import * as Text from "~/styles/text";

import LogoDark from "~/images/logo-dark.svg";
import CloseIcon from "~/images/menu/close.svg";

import ArrowDownIcon from "~/images/menu/arrow-down-gray.svg";
import QrCode from "~/images/onlink_to_capitual.svg";
import Close from "~/images/close.png";
import { Container } from "./MobileMenu.styles";

function MenuModal(props) {
  const { t, i18n } = useTranslation();

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalFade, setModalFade] = useState(false);
  const [openAccordions, setOpenAccordions] = useState([]);
  const { isMobile, isDesktop } = useDetectDevice();

  const openModal = () => {
    setModalIsVisible(true);
  };

  const closeModal = () => {
    if (modalIsVisible === true) {
      setModalFade(false);
    }

    setTimeout(() => setModalIsVisible(false), 350);
  };

  useEffect(() => {
    if (modalIsVisible === true) {
      setModalFade(true);
    }
  }, [modalIsVisible]);

  const redirectToStore = () => {
    window.location.href = "https://onelink.to/capitual";
  };

  return (
    <Container>
      <div className="modal-menu-container">
        <div className="header-modal-menu">
          <a href="/">
            <img src={LogoDark} width={130} alt="Capitual" />
          </a>

          <div
            className="close-button-modal"
            onClick={() => props.CloseButton(false)}
          >
            <img src={CloseIcon} alt="close button" />
          </div>
        </div>

        <hr style={{ opacity: "10%" }} />

        <a
          className="tbuttonn fbuttonl-mobile sbuttono w-100 mb-20"
          onClick={() => (isMobile() ? redirectToStore() : openModal())}
        >
          {t("getStarted.downloadApp")}
        </a>

        <Accordion
          allowZeroExpanded
          onChange={(open) => setOpenAccordions(open)}
        >
          {menuOptions.map((item) => (
            <AccordionItem uuid={item.menuTitle} key={item.menuTitle}>
              <AccordionItemHeading>
                <AccordionItemButton className="accordion-item">
                  <a>{t(item.menuTitle)}</a>

                  <img
                    style={{
                      transform: openAccordions.includes(item.menuTitle)
                        ? "rotate(-180deg)"
                        : "",
                      transition: "all 0.5s ease",
                    }}
                    src={ArrowDownIcon}
                    alt=""
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-panel-anim">
                <AccordionItemState>
                  {({ expanded }) => (
                    <AnimatePresence>
                      {expanded && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 1 }}
                        >
                          <ul className="sub-menu-content pl-0 accordion-menu">
                            {item.submenu.map((subItem) => (
                              <a
                                key={subItem.label}
                                href={subItem.route}
                                className={
                                  !subItem.enbabled && `disabled`
                                }
                              >
                                <li>
                                  <img src={subItem.icon} alt="" />
                                  <p>{t(subItem.label)}</p>
                                </li>
                              </a>
                            ))}
                          </ul>
                          <div style={{ height: 2 }} />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                </AccordionItemState>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <Fade when={modalIsVisible} duration={350}>
        <div
          className={`modal ${modalIsVisible && `visible`}`}
          style={{ zIndex: 999999999999 }}
        >
          <Fade right when={modalFade} duration={400}>
            <div className="modal-content2">
              <div className="scontainero" style={{ height: "80%" }}>
                <div className="closeButton" onClick={() => closeModal()}>
                  <img
                    src={Close}
                    alt="fechar"
                    style={{ alignSelf: "flex-end" }}
                    width="16"
                  />
                </div>

                <div className="has-container-centered">
                  <Text.Heading1Bold
                    width="60%"
                    fontSize="28px"
                    textAlign="center"
                    marginBottom={30}
                    data-reveal-delay={400}
                  >
                    {t("modal.title")}
                  </Text.Heading1Bold>

                  <Text.Small
                    width="85%"
                    fontSize="14px"
                    textAlign="center"
                    marginBottom={60}
                    data-reveal-delay={400}
                  >
                    {t("modal.description")}
                  </Text.Small>

                  <img src={QrCode} alt="QrCode" width="160" />

                  <Text.Small
                    width="85%"
                    fontSize="14px"
                    color={colors.grayMedium}
                    textAlign="center"
                    marginTop={60}
                    data-reveal-delay={400}
                  >
                    {t("modal.tip")}
                  </Text.Small>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Fade>
    </Container>
  );
}

export default MenuModal;
