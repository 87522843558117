import React, { useState } from "react";
import * as Style from './Faq.styles';
import * as Text from '~/styles/text';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import useWindowDimensions from "~/hooks/windowDimension";
import { faq } from "~/data/precatorio";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import ArrowDownBlueIcon from "~/images/menu/arrow-down-blue.svg";

const pageWidth = [1045, 570];

function Faq() {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();

  const [openAccordions, setOpenAccordions] = useState([]);

  return (
    <Style.FaqContainer id="Faq">
      <Container>
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <div className="text-container">
              <Text.Heading1Bold fontSize="44px" marginBottom={20}>
                {t("precatorio.faq.title")}
              </Text.Heading1Bold>

              <Text.Heading1Regular fontSize="21px">
                {t("precatorio.faq.description")}
              </Text.Heading1Regular>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <Accordion
              onChange={(open) => setOpenAccordions(open)}
              allowMultipleExpanded
              allowZeroExpanded
            >
              {faq.map((item) => (
                <AccordionItem
                  uuid={item.id}
                  className="accordion-line"
                  key={item.id}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="accordion-item">
                      <Text.Heading1Bold
                        fontSize={pageWidth[1] < width ? `19px` : `18px`}
                      >
                        {t(item.title)}
                      </Text.Heading1Bold>

                      <div className="accordion-arrow">
                        <img
                          style={{
                            transform: openAccordions.includes(item.id)
                              ? "rotate(-180deg)"
                              : "",
                            transition: "all 0.5s ease",
                          }}
                          src={ArrowDownBlueIcon}
                          alt="arrow"
                        />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel className="accordion-panel-anim">
                    <AccordionItemState>
                      {({ expanded }) => (
                        <AnimatePresence>
                          {expanded && (
                            <motion.div
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: "auto" }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.5 }}
                            >
                              <Text.Heading1Regular fontSize="17px">
                                {t(item.description)}
                              </Text.Heading1Regular>
                              <div style={{ height: 30 }} />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      )}
                    </AccordionItemState>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </Style.FaqContainer>
  );
}

export default Faq;
