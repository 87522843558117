import React from "react";
import * as Style from './Banner.styles';
import * as Text from '~/styles/text';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { colors } from "~/styles";
import useWindowDimensions from "~/hooks/windowDimension";
import useDetectDevice from "~/hooks/detectDevice";

import GenericButton from "~/components/GenericButton";

const pageWidth = [1045, 570];

function Banner() {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const { isMobile, isDesktop } = useDetectDevice();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <Style.BannerSection>
      <Container>
        <Row>
          <Col lg={6}>
            <Text.Heading1Medium
              marginBottom={10}
              color="#026FF4"
              fontSize="40px"
            >
              {t("precatorio.home.title")}
            </Text.Heading1Medium>

            <Text.Heading1Bold
              marginBottom={30}
              color={colors.black}
              fontSize="52px"
            >
              {t("precatorio.home.subtitle")}
            </Text.Heading1Bold>

            {isMobile() ||
              (pageWidth[0] < width && (
                <>
                  <Col lg={11}>
                    <Text.Heading1Regular
                      marginBottom={10}
                      color={colors.black}
                      fontSize="24px"
                      lineHeight={130}
                    >
                      {t("precatorio.home.description")}
                    </Text.Heading1Regular>
                  </Col>

                  <GenericButton
                    grayHover
                    buttonType="inline"
                    onClick={() => redirectTo("#PrecatoryAvailable")}
                    marginTop={30}
                    paddingVertical={25}
                  >
                    <Text.Heading5Bold fontSize="15px">
                      {t("precatorio.home.button")}
                    </Text.Heading5Bold>
                  </GenericButton>
                </>
              ))}
          </Col>
        </Row>
      </Container>
    </Style.BannerSection>
  );
}

export default Banner;
