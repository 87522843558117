import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Reveal, Fade } from "react-reveal";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "~/hooks/windowDimension";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import * as Style from "./1.Careers.styles";
import * as Text from "~/styles/text";
import { colors } from "~/styles";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import baseUrl from "~/config";
import axios from "axios";
import useDetectDevice from "~/hooks/detectDevice";

import ArrowDownGrayIcon from "~/images/menu/arrow-down-gray.svg";
import WifiIcon from "~images/careers/wifi.svg";

import Menu from "~/components/Menu";
import GenericButton from "~/components/GenericButton";
import Footer from "~/components/Footer";

const pageWidth = [1045, 570, 991];

function Careers() {
  const { t, i18n } = useTranslation();
  const navigate = useHistory();

  const { width } = useWindowDimensions();
  const [openAccordions, setOpenAccordions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [pageData, setPageData] = useState([]);

  const { isMobile, isDesktop } = useDetectDevice();

  const redirectTo = (route) => {
    window.location.href = baseUrl.ENDPOINT[0] + route;
  };

  useEffect(() => {
    axios
      .get(`${baseUrl.ENDPOINT[1]}/api/vacancies?populate=deep`)
      .then((res) => {
        setCategoryData(res.data.data);
      });

    axios
      .get(`${baseUrl.ENDPOINT[1]}/api/capitual-com-carrer?populate=deep`)
      .then((res) => {
        setPageData(res.data.data);
      });
  }, []);

  return (
    <Style.Container>
      <Menu isDarkMenu={!!isMobile()} />

      <Style.BannerSection
        backgroundImage={
          pageData?.attributes?.Banner?.[0]?.Background?.data?.attributes?.url
        }
      >
        <div className="banner">
          <Text.Heading1Bold
            color={pageData?.attributes?.Banner?.[0]?.TextColor}
            fontSize="60px"
            marginBottom={25}
          >
            {pageData?.attributes?.Banner?.[0]?.Title}
          </Text.Heading1Bold>

          <Text.Heading1Regular
            width={width > pageWidth[0] ? `60%` : `100%`}
            textAlign="center"
            color={pageData?.attributes?.Banner?.[0]?.TextColor}
            fontSize="21px"
          >
            {pageData?.attributes?.Banner?.[0]?.Subtitle}
          </Text.Heading1Regular>
        </div>
      </Style.BannerSection>

      <Style.DescriptionContainer>
        <Container>
          <Row>
            <Col sm={12} md={1} lg={3} xl={3} />
            <Col>
              <Text.Heading2Bold
                textAlign="center"
                fontSize="44px"
                marginBottom={35}
              >
                {pageData?.attributes?.MainTitle}
              </Text.Heading2Bold>
            </Col>
          </Row>

          <Row>
            <Col xl={1} />
            <Col xl={10}>
              <Text.Heading1Regular
                className="description-container-subtitle"
                fontSize="21px"
                marginBottom={20}
              >
                {pageData?.attributes?.MainSubtitle}
              </Text.Heading1Regular>
            </Col>
            <Col xl={1} />
          </Row>

          <div className="align-center">
            <GenericButton
              buttonType="fourth"
              marginTop={46}
              paddingVertical={15}
              href={pageData?.attributes?.CTALink}
            >
              <Text.Heading5 fontSize="15px" color={colors.white}>
                {pageData?.attributes?.CTAText}
              </Text.Heading5>
            </GenericButton>
          </div>
        </Container>
      </Style.DescriptionContainer>

      <Style.CardContainer>
        <Container>
          <Row>
            <Col>
              <Text.Heading2Bold fontSize="44px" marginBottom={20}>
                {pageData?.attributes?.BenefitsContainerTitle}
              </Text.Heading2Bold>

              <Text.Heading3 fontSize="21px" marginBottom={70}>
                {pageData?.attributes?.BenefitsContainerSubtitle}
              </Text.Heading3>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="align-center">
            {pageData?.attributes?.BenefitsCard.map((item) => (
              <Col sm={12} md={6} lg={6} xl={4}>
                <div className="card-item" key={item.title}>
                  <div className="icon-container">
                    <img src={item.Icon.data.attributes.url} alt={item.title} />
                  </div>

                  <Text.Heading3Bold
                    fontSize="21px"
                    marginTop={20}
                    marginBottom={12}
                  >
                    {item.Title}
                  </Text.Heading3Bold>

                  <Text.Heading1Regular
                    fontSize="17.5px"
                    lineHeight={135}
                    marginBottom={20}
                  >
                    {item.Subtitle}
                  </Text.Heading1Regular>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Style.CardContainer>

      <Style.OpportunitiesContainer id="vacancies">
        <div className="column align-center">
          <Text.Heading1Bold
            fontSize="64px"
            textAlign="center"
            marginBottom={20}
          >
            {pageData?.attributes?.VacancyContainerTitle}
          </Text.Heading1Bold>

          <Text.Heading1Regular
            fontSize="21px"
            textAlign="center"
            marginBottom={70}
          >
            {pageData?.attributes?.VacancyContainerSubtitle}
          </Text.Heading1Regular>
        </div>

        <Container>
          <Row>
            <Col sm="12">
              <Accordion
                onChange={(open) => setOpenAccordions(open)}
                allowMultipleExpanded
                allowZeroExpanded
              >
                {categoryData?.map((item) => (
                  <>
                    {item?.attributes?.carrers?.data?.length >= 1 && (
                      <AccordionItem
                        uuid={item?.id}
                        className="accordion-line"
                        key={item?.id}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton className="accordion-item">
                            <div className="accordion-item-title">
                              <img
                                src={
                                  item?.attributes?.Icon?.data?.attributes?.url
                                }
                                alt={
                                  item?.attributes?.Icon?.data?.attributes?.name
                                }
                              />

                              <Text.Heading1Bold
                                fontSize={
                                  pageWidth[1] < width ? `19px` : `18px`
                                }
                              >
                                {item?.attributes?.Title}
                              </Text.Heading1Bold>

                              {item?.attributes?.carrers?.data?.length >= 1 && (
                                <div className="quantity">
                                  <Text.Heading3
                                    fontSize="16px"
                                    color={colors.newBlue}
                                  >
                                    {item?.attributes?.carrers?.data?.length}
                                  </Text.Heading3>
                                </div>
                              )}
                            </div>

                            <div className="accordion-arrow">
                              <img
                                style={{
                                  transform: openAccordions.includes(item?.id)
                                    ? "rotate(-180deg)"
                                    : "",
                                  transition: "all 0.5s ease",
                                }}
                                src={ArrowDownGrayIcon}
                                alt="arrow"
                              />
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel className="accordion-panel-anim">
                          <AccordionItemState>
                            {({ expanded }) => (
                              <AnimatePresence>
                                {expanded && (
                                  <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.5 }}
                                  >
                                    {item?.attributes?.carrers?.data.map(
                                      (subitem) => (
                                        <div className="subitem-accordion-opened">
                                          <Container>
                                            <Row>
                                              <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <div className="column">
                                                  <div className="subitem-accordion-opened-header">
                                                    <Text.Heading1Medium
                                                      fontSize="21px"
                                                      marginBottom={7}
                                                    >
                                                      {
                                                        subitem?.attributes
                                                          ?.Title
                                                      }
                                                    </Text.Heading1Medium>

                                                    <img src={WifiIcon} />
                                                  </div>

                                                  <Text.Heading1Regular
                                                    fontSize="16px"
                                                    color={colors.grayMedium}
                                                  >
                                                    {
                                                      subitem?.attributes
                                                        ?.Subtitle
                                                    }
                                                  </Text.Heading1Regular>
                                                </div>
                                              </Col>
                                              <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={6}
                                                xl={6}
                                              >
                                                <div className="buttton">
                                                  <GenericButton
                                                    buttonType="inline"
                                                    onClick={() =>
                                                      redirectTo(
                                                        subitem?.attributes
                                                          ?.url_path
                                                      )
                                                    }
                                                    width={
                                                      pageWidth[2] > width &&
                                                      `100%`
                                                    }
                                                  >
                                                    <Text.Heading5 fontSize="15px">
                                                      Detalhes da vaga
                                                    </Text.Heading5>
                                                  </GenericButton>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Container>
                                        </div>
                                      )
                                    )}
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            )}
                          </AccordionItemState>
                        </AccordionItemPanel>
                      </AccordionItem>
                    )}
                  </>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Style.OpportunitiesContainer>

      <Footer />
    </Style.Container>
  );
}

export default Careers;
