import React from "react";
import { colors } from "~/styles";
import * as Style from "./TabelaDeTemporalidade.styles";
import * as Text from "~/styles/text";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";
import GenericButton from "~/components/GenericButton";

import DownloadIcon from "~/images/legal/download.svg";

function TabelaDeTemporalidade() {
  const { t, i18n } = useTranslation();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Tabela de Temporalidade
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col className="mb-30">
              <table id="table">
                <tr className="main-table">
                  <th>Titular do dado pessoal</th>
                  <th>Tipo de dado pessoal</th>
                  <th>Documento base</th>
                  <th>Prazo de retenção</th>
                  <th>Finalidade da retenção</th>
                  <th>Legislação</th>
                  <th>Base legal disposta na LGPD</th>
                </tr>
                <tr>
                  <td>Clientes do Capitual ou terceiros que prestam serviços ao Capitual;</td>
                  <td>Nome Completo;<br /><br />Dados contidos em documentos oficiais (CPF, RG, Naturalidade, Filiação, data de nascimento);<br /> Endereço completo; <br />Selfie;</td>
                  <td>Dossiê para abertura de conta;<br /><br />Processo de verificação;</td>
                  <td>Mínimo de 10 anos</td>
                  <td>Viabilizar a prestação de contas junto às autoridades públicas;</td>
                  <td>Art. 37 Lei 9.430/96;<br /><br />Art. 173 e 174 do CTN;<br /><br />Art. 67 da Circular 3.978 do BACEN</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
                <tr>
                  <td>Clientes do Capitual ou terceiros que prestam serviços ao Capitual;</td>
                  <td>Informações financeiras;<br /><br/>Nome completo;<br /><br/>Agência;<br /><br/>Conta corrente;<br /><br/>CAP-U;<br /><br/>CPF;</td>
                  <td>Processo de verificação;<br /><br/>Comprovantes de transação;</td>
                  <td>Mínimo de 10 anos</td>
                  <td>Viabilizar a prestação de contas junto às autoridades públicas;</td>
                  <td>Art. 37 Lei 9.430/96;<br /><br />Art. 173 e 174 do CTN;<br /><br />Art. 67 da Circular 3.978 do BACEN</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
                <tr>
                  <td>Pessoa física que não é cliente do Capitual;</td>
                  <td>Nome Completo;<br /><br />CPF;<br /><br />Data de Nascimento;<br /><br />E-mail;<br /><br />Telefone;<br /><br />Agência e Conta;</td>
                  <td>Dossiê enviado por parceiro;<br /><br />Settle Hero</td>
                  <td>Mínimo de 10 anos</td>
                  <td>Viabilizar a prestação de contas junto às autoridades públicas;</td>
                  <td>Art. 37 Lei 9.430/96;<br /><br />Art. 173 e 174 do CTN;<br /><br />Art. 67 da Circular 3.978 do BACEN</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
                <tr>
                  <td>Clientes do Capitual;</td>
                  <td>Informações sobre o dispositivo de acesso (computador ou dispositivo móvel);<br /><br />Características pessoais (preferências de navegação, perfil comportamental);</td>
                  <td>Dados do usuário;</td>
                  <td>01 ano; </td>
                  <td>Marketing;<br /><br />Comprovação do histórico de registros;<br /><br />Correção de bugs;</td>
                  <td>Art. 15 e 17 do Marco Civil da Internet;</td>
                  <td>Art. 7º, I da LGPD - mediante o fornecimento de consentimento pelo titular;<br /><br />Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
                <tr>
                  <td>Candidatos a vagas de trabalho no Capitual;</td>
                  <td>Nome Completo;<br /><br />Endereço;<br /><br />Telefone;<br /><br />E-mail;<br /><br />Sexo;<br /><br />Idade;<br /><br />Informações profissionais e de escolaridade;</td>
                  <td>Currículo;<br /><br />Ficha de entrevista;</td>
                  <td>03 meses</td>
                  <td>Viabilizar a realização de processo seletivo;</td>
                  <td> - </td>
                  <td>Art. 7ª, IX da LGPD - quando necessário para atender aos interesses legítimos do controlador ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais;</td>
                </tr>
                <tr>
                  <td>Colaboradores do Capitual;</td>
                  <td>Nome completo;<br /><br />Endereço;<br /><br />Telefone;<br /><br />E-mail;<br /><br />CPF;<br /><br />RG;<br /><br />CNPJ;<br /><br />Informações profissionais; (CTPS, E-social, PIS/COFINS)<br /><br />Título de Eleitor;<br /><br />Informações financeiras; (Agência e Conta)</td>
                  <td>Contrato de trabalho;<br /><br />Comprovantes de pagamento;<br /><br />Comunicado de aviso prévio;<br /><br />Atestados médicos;<br /><br />Termo de rescisão contratual;</td>
                  <td>05 anos;</td>
                  <td>Defesa em eventuais ações judiciais ou administrativas;</td>
                  <td>Art. 7º, inciso VI, da Lei 13.709/18;</td>
                  <td>Art. 7ª, V da LGPD - quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;<br /><br />Art. 7ª, VI da LGPD - para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem); </td>
                </tr>
                <tr>
                  <td>Colaboradores do Capitual;</td>
                  <td>Nome completo;<br /><br />Endereço completo;<br /><br />Filiação;<br /><br />Data de Nascimento;<br /><br />CPF;<br /><br />RG;<br /><br />PIS/COFINS;<br /><br />E-social;<br /><br />Título de eleitor;<br /><br />CTPS;<br /><br />Reservista;<br /><br />Telefone;<br /><br />E-mail;<br /><br />Escolaridade;<br /><br />Estado Civil;</td>
                  <td>Ficha de registro;</td>
                  <td>30 anos;</td>
                  <td>Comprovação de tempo de serviço para fins de concessão de benefícios previdenciário;</td>
                  <td>Lei nº 8.212/91 e 8.213/91;</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;<br /><br />Art. 7ª, V da LGPD - quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;</td>
                </tr>
                <tr>
                  <td>Colaboradores do Capitual;</td>
                  <td>Nome Completo;<br /><br />RG;<br /><br />CPF;<br /><br />Data de Nascimento;<br /><br />Sexo;<br /><br />Dados de saúde;</td>
                  <td>Laudo admissional;<br /><br />Laudo Demissional;</td>
                  <td>20 anos;</td>
                  <td>Comprovação de atendimento ao Programa de Controle Médico de Saúde Ocupacional (PCMSO);</td>
                  <td>Item 7.4.5.1 da Portaria SSST nº 24/94;</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
                <tr>
                  <td>Colaboradores do Capitual;</td>
                  <td>Nome Completo;<br /><br />Informações financeiras;<br /><br />Informações profissionais;<br /><br />Dados contidos em documentos oficiais (CPF, RG, Naturalidade, Filiação, data de nascimento);</td>
                  <td>Comprovantes/Recibos <br />/Guias/DARFs/Listas de registro de recolhimento do PIS/PASEP, CSLL, IR, COFINS, FGTS (GFIP e GRFC/RFP), previdência social (GPS) e benefícios reembolsados pelo INSS.</td>
                  <td>Até 30 anos dependendo do tributo.<br /><br />PIS/PASEP/Previ dência Social (GPS)/Benefícios reembolsados pelo INSS/ Folha de pagamento – 10 anos;<br /><br />COFINS, CSLL, IR – 5 anos;<br /><br />FGTS – 30 anos;</td>
                  <td>Comprovação do recolhimento de taxas/tributos e Defesa em eventuais ações judiciais ou administrativas de cunho fiscal, previdenciário ou trabalhista;</td>
                  <td>Art. 3º, do Decreto-lei nº 2.052/83;<br /><br />Art.173 e 174 do CTN; <br /><br />Art. 32, da Lei nº 8.212/91</td>
                  <td>Art. 7º, II da LGPD - para o cumprimento de obrigação legal ou regulatória pelo controlador;</td>
                </tr>
              </table>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default TabelaDeTemporalidade;
