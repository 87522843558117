import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Reveal, Fade } from "react-reveal";

import * as Style from "./Home.styles";
import * as Text from "~/styles/text";
import { colors } from "~/styles";
import Menu from "~/components/Menu";
import GenericButton from "~/components/GenericButton";

import ArrowRightIcon from "~/images/home/arrow-right-blue.svg";
import CriptoIcon from "~/images/home/cripto.svg";
import FiatIcon from "~/images/home/fiat.svg";
import MetalIcon from "~/images/home/metal.svg";
import TokenIcon from "~/images/home/document.svg";

import CapContaImage from "~/images/home/capconta.png";
import CapWalletsImage from "~/images/home/capwallets.png";
import CapBusinessImage from "~/images/home/capbusiness.png";
import CapPayImage from "~/images/home/cappay.png";
import CapSettleImage from "~/images/home/capsettle.png";
import Footer from "~/components/Footer";

const arrayCard = [
  {
    icon: CriptoIcon,
    title: "Criptomoedas",
    description:
      "Negocie, armazene, saque, movimente e converta suas criptomoedas instantaneamente em um sistema integrado de wallets.",
  },
  {
    icon: FiatIcon,
    title: "Moedas Fiduciárias",
    description:
      "Tenha controle total do seu patrimônio fiat. Gerencie Dólar, Euro, Libra e Real de forma simples e ágil em sua conta multimoedas.",
  },
  {
    icon: MetalIcon,
    title: "Metais Preciosos",
    description:
      "Diversifique a sua carteira investindo em metais preciosos sem precisar sair do app.",
  },
  {
    icon: TokenIcon,
    title: "Tokens de Precatório",
    description:
      "Equilibre o seu portfólio de investimentos com a segurança dos ativos judiciais tokenizados.",
  },
];

function Home() {
  const { t, i18n } = useTranslation();

  return (
    <Style.Container>
      <Menu />

      <Style.BannerSection>
        <Container>
          <Row>
            <Col lg={7}>
              <Fade bottom>
                <Text.Heading1Bold
                  fontSize="62px"
                  marginBottom={30}
                  marginTop={40}
                  marginRight={50}
                  color={colors.white}
                  data-reveal-delay={200}
                
                >
                  {t("home.title")}
                </Text.Heading1Bold>
              </Fade>

              <Fade bottom>
                <Text.Heading1Regular
                  fontSize="24px"
                  marginBottom={10}
                  color={colors.white}
                  data-reveal-delay={400}
                  lineHeight={140}
                  marginRight={190}
                >
                  {t("home.headline")}
                </Text.Heading1Regular>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Style.BannerSection>

      <Style.CardContainer>
        <Container>
          <Row>
            <Col>
              <Text.Heading2Bold
                fontSize="37px"
                marginBottom={60}
                textAlign="center"
              >
                {" "}
                No Capitual, você gerencia um portfólio diversifismo deve ser,
                prático como você precisa.
              </Text.Heading2Bold>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            {arrayCard.map((item) => (
              <Col sm={12} md={6} lg={4} xl={3}>
                <div className="card-item" key={item.title}>
                  <img src={item.icon} alt={item.title} />

                  <Text.Heading3Bold
                    fontSize="21px"
                    marginTop={20}
                    marginBottom={12}
                  >
                    {item.title}
                  </Text.Heading3Bold>

                  <Text.Heading1Regular
                    fontSize="17.5px"
                    lineHeight={135}
                    marginBottom={20}
                  >
                    {item.description}
                  </Text.Heading1Regular>

                  <GenericButton
                    buttonType="inline"
                    noInlineBorder
                    height={20}
                    paddingVertical={0.1}
                    paddingHorizontal={1}
                    onClick={() => {}}
                  >
                    <div className="button-content">
                      <Text.Heading5Bold color={colors.primary}>
                        {t("precatorio.whatFor.button")}
                      </Text.Heading5Bold>

                      <img src={ArrowRightIcon} alt="" />
                    </div>
                  </GenericButton>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Style.CardContainer>

      <Style.CapContaContainer>
        <Container>
          <Row>
            <Col xl={6}>
              <img src={CapContaImage} />
            </Col>

            <Col xl={{ span: 5, offset: 1 }}>
              <Text.Heading1Regular
                fontSize="25px"
                lineHeight={113.5}
                color={colors.primary}
              >
                CapConta e Cartão
              </Text.Heading1Regular>

              <Text.Heading1Bold
                fontSize="50px"
                lineHeight={113.5}
                marginTop={24}
                marginBottom={20}
              >
                Onde o melhor das criptomoedas e dos serviços bancários
                tradicionais se encontram.
              </Text.Heading1Bold>

              <Text.Heading1Regular
                fontSize="21px"
                lineHeight={131.5}
                marginBottom={40}
              >
                Gerencie suas finanças e envie pagamentos e transferências
                bancárias com rapidez.
              </Text.Heading1Regular>

              <GenericButton buttonType="primary">
                <Text.Heading5Bold fontSize="15px" color={colors.white}>
                  Conhecer CapWallets
                </Text.Heading5Bold>
              </GenericButton>
            </Col>
          </Row>
        </Container>
      </Style.CapContaContainer>

      <Style.CapWalletsContainer>
        <Container>
          <Row>
            <Col xl={5}>
              <Text.Heading1Regular
                fontSize="25px"
                lineHeight={113.5}
                color={colors.primary}
              >
                CapWallets
              </Text.Heading1Regular>

              <Text.Heading1Bold
                fontSize="50px"
                lineHeight={113.5}
                marginTop={24}
                marginBottom={20}
              >
                Seja qual for sua necessidade, as CapWallets estão prontas para
                o trabalho.
              </Text.Heading1Bold>

              <Text.Heading1Regular
                fontSize="21px"
                lineHeight={131.5}
                marginBottom={40}
              >
                As mais versáteis e poderosas carteiras do mercado. Crie,
                gerencie e acompanhe todas as suas carteiras em tempo real.
              </Text.Heading1Regular>

              <GenericButton buttonType="primary">
                <Text.Heading5Bold fontSize="15px" color={colors.white}>
                  Conhecer CapWallets
                </Text.Heading5Bold>
              </GenericButton>
            </Col>

            <Col xl={{ span: 5, offset: 1 }}>
              <img src={CapWalletsImage} alt="" />
            </Col>
          </Row>
        </Container>
      </Style.CapWalletsContainer>

      <Style.CapBusinessContainer>
        <Container>
          <Row>
            <Col>
              <img src={CapBusinessImage} className="business-image" alt="" />
            </Col>

            <Col>
              <Text.Heading1Regular
                fontSize="25px"
                lineHeight={113.5}
                color="#9DE6F7"
              >
                CapBusiness
              </Text.Heading1Regular>

              <Text.Heading1Bold
                fontSize="50px"
                lineHeight={113.5}
                marginTop={24}
                marginBottom={20}
                color={colors.white}
              >
                Sob medida para que seu negócio prospere com a nova economia.
              </Text.Heading1Bold>

              <Text.Heading1Regular
                fontSize="21px"
                lineHeight={131.5}
                marginBottom={40}
                color={colors.white}
              >
                Os serviços e ferramentas do CapBusiness estabelecem segurança e
                versatilidade para gerenciar negócios e equipes de todas as
                complexidades, tamanhos e segmentos.
              </Text.Heading1Regular>

              <GenericButton buttonType="third">
                <Text.Heading5Bold fontSize="15px">
                  Conhecer CapBusiness
                </Text.Heading5Bold>
              </GenericButton>
            </Col>
          </Row>
        </Container>
      </Style.CapBusinessContainer>

      <Style.CapPayContainer>
        <Container>
          <Row>
            <Col xl={5}>
              <Text.Heading1Regular
                fontSize="25px"
                lineHeight={113.5}
                color={colors.primary}
              >
                CapPay
              </Text.Heading1Regular>

              <Text.Heading1Bold
                fontSize="50px"
                lineHeight={113.5}
                marginTop={24}
                marginBottom={20}
              >
                O gateway de pagamentos com criptomoedas mais avançado do mundo.
              </Text.Heading1Bold>

              <Text.Heading1Regular
                fontSize="21px"
                lineHeight={131.5}
                marginBottom={56}
              >
                Receba em criptomoedas e moedas fiduciárias no seu e-commerce ou
                loja física, a 0% de taxa, com o melhor gateway de pagamentos do
                mercado.
              </Text.Heading1Regular>

              <GenericButton buttonType="primary">
                <Text.Heading5Bold color={colors.white} fontSize="15px">
                  Conhecer CapPay
                </Text.Heading5Bold>
              </GenericButton>
            </Col>

            <Col xl={{ span: 5, offset: 1 }}>
              <img src={CapPayImage} alt="" />
            </Col>
          </Row>
        </Container>
      </Style.CapPayContainer>

      <Style.CapSettleContainer>
        <Container>
          <Row>
            <Col xl={6}>
              <img src={CapSettleImage} alt="" />
            </Col>

            <Col xl={{ span: 5, offset: 1 }}>
              <Text.Heading1Regular
                fontSize="25px"
                lineHeight={113.5}
                color={colors.primary}
              >
                CapSettle
              </Text.Heading1Regular>

              <Text.Heading1Bold
                fontSize="50px"
                lineHeight={113.5}
                marginTop={24}
                marginBottom={20}
              >
                Identifique e processe os mais variados tipos de transações.
              </Text.Heading1Bold>

              <Text.Heading1Regular
                fontSize="21px"
                lineHeight={131.5}
                marginBottom={56}
              >
                Qualquer sistema apto a identificar e processar os mais variados
                tipos de transações financeiras.
              </Text.Heading1Regular>

              <GenericButton buttonType="primary">
                <Text.Heading5Bold fontSize="15px" color={colors.white}>
                  Conhecer CapSettle
                </Text.Heading5Bold>
              </GenericButton>
            </Col>
          </Row>
        </Container>
      </Style.CapSettleContainer>

      <Style.TotalMoney>
        <Text.Heading1Regular
          fontSize="30px"
          color={colors.white}
          textAlign="center"
        >
          O Capitual já movimentou tudo isso em criptomoedas:
        </Text.Heading1Regular>

        <Text.Heading1Bold
          fontSize="103.38px"
          color={colors.white}
          textAlign="center"
          marginTop={30}
          marginBottom={30}
        >
          R$ 10.447.633.434,81
        </Text.Heading1Bold>

        <Text.Heading1Regular
          fontSize="21px"
          color={colors.white}
          textAlign="center"
          marginBottom={30}
        >
          Quer fazer parte também? Clique abaixo e abra sua conta:
        </Text.Heading1Regular>

        <GenericButton buttonType="third">
          <Text.Heading5Bold fontSize="15px">
            Criar minha conta
          </Text.Heading5Bold>
        </GenericButton>
      </Style.TotalMoney>

      <Footer />
    </Style.Container>
  );
}

export default Home;
