import styled from 'styled-components';
import colors from "../../../../styles/colors";
import { breakpoints, screen } from "../../../../styles/breakpoints";

import BannerImage from "../../../../images/portfolio/precatorio/banner.png";

export const BannerSection = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100vh;
  background: url(${BannerImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;

  padding-top: 80px;

  @media (max-width: 1850px) {
    background-position: 50% center;
  }

  @media ${screen.largeDesktop} {
    background-position: 60% center;
  }

  @media (max-width: 1045px) {
    align-items: flex-end;

    background-position: 100% center;

    p {
      color: ${colors.white};
      text-shadow: 1px 1px 10px rgb(17 21 56 / 16%);
    }
  }

  @media ${screen.tablet} {
    align-items: flex-end;

    background-position: 93% center;

    p {
      color: ${colors.white};
      text-shadow: 1px 1px 10px rgb(17 21 56 / 16%);
    }
  }

  @media (max-width: 500px) {
    align-items: flex-end;

    background-position: 85% 0px;

    p {
      color: ${colors.white};
      text-shadow: 1px 1px 10px rgb(17 21 56 / 16%);
    }
  }
`;