import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './i18n';
import GlobalStyle from "./styles/global";
import './styles/fonts.css';

ReactDOM.render(
  <> 
    <Routes /> 
    <GlobalStyle /> 
  </>, 
document.getElementById("root"));

serviceWorker.unregister();
