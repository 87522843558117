import styled from 'styled-components';
import { colors } from '~/styles';

import BgBackground from "~/images/otc/bg-background.svg";
import Bubble from "~/images/otc/bubble.svg";
import BgLine from "~/images/otc/bg-line.png";

export const Container = styled.div`

  .content {
    background-color: ${colors.white};
    padding: 100px 0;

      .frequently-asked-container {
        display: flex;
        flex-direction: column;

        padding: 120px 0;
        text-align: center;
        background-color: ${colors.whiteLight};

        @media (max-width: 640px) {
          padding: 120px 24px;
        }

        .frequently-asked-title {
          font-family: "Aeonik Regular", sans-serif;
          font-size: 35px;
          color: ${colors.black};
          letter-spacing: -0.7px;
          margin-bottom: 75px;
        }

        .accordion-item {
          background-color: ${colors.whiteLight};
          text-align: left;
        }

        .subitem-accordion-opened-header {
          text-align: left;
          margin-bottom: 30px;
        }
      }

      .bg-grad-blue {
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 24px;
        background: ${colors.newBlue};
        height: 666px;
        margin: 144px 0;

        @media (max-width: 1000px) {
          height: 1050px;
          margin: 0px 0 100px 0;
        }

        @media (max-width: 900px) {
          height: 900px;
          margin: 0px 0 100px 0;
        }

        @media (max-width: 800px) {
          height: 850px;
          margin: 0px 0 100px 0;
        }

        @media (max-width: 640px) {
          height: auto;
          margin: 0px 0 100px 0;
        }
      }

      .bg-text-container {
        display: flex;
        align-items: center;
        justify-items: center;

        height: 100%;

        .bg-grad-title {
          font-family: "Aeonik Medium", sans-serif;
          font-size: 50px;
          color: ${colors.white};
          line-height: 120%;

          @media (max-width: 1000px) {
            margin-bottom: 60px;
          }

          @media (max-width: 800px) {
            font-size: 40px;
            margin-bottom: 30px;
          }
        }
      }

      .bg-grad-blue h3, .bg-grad-blue p, .bg-grad-blue a {
        color: white;
        transition: all ease 0.5s;
      }

      .bg-grad-blue a:hover {
        transform: translateX(10px);
        transition: all ease-out 0.5s;
      }

      @media (min-width: 641px) {
        .bg-grad-blue {
          padding-left: 75px;
          padding-right: 48px
        }
      }

      @media (max-width: 640px) {
        .bg-grad-blue {
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      .features-title {
        font-family: "Aeonik Medium", sans-serif;
        line-height: normal;
        font-size: 55px;
      }

      .features-subtitle {
        font-family: "Helvetica Now Display Regular", sans-serif;
        font-size: 20px;
        line-height: 35px;
        color: #272937;
      }

      input {
        font-family: "Aeonik Regular", sans-serif;
        font-size: 17px;
        color: ${colors.white};
        line-height: 135%;

        width: 100%;
        margin-bottom: 5px;
        background: transparent;
        padding: 17.5px 0;
        border: none;
        border-bottom: 2px solid rgba(248, 249, 251, 0.50);

        &::placeholder {
          color: ${colors.white};
        }
      }
  }
`;

export const StripedContainer = styled.div`
  background-color: #f8f9fb;

  background-image: url(${BgLine});
  background-repeat: repeat-y;
  background-position: 50% top;

  padding-bottom: 168px;

  .banner,
  .process-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 168px;
    position: relative;
    z-index: 999;

    .banner-texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      max-width: 75%;
      text-align: center;

      @media (max-width: 1150px) {
        max-width: 90%;
      }

      @media (max-width: 640px) {
        max-width: 75%;

        & a {
          margin: auto;
        }
      }

      .banner-title {
        font-family: "Aeonik Medium", sans-serif;
        font-size: 80px;
        line-height: 108.5%;
        letter-spacing: -2.4px;
        color: ${colors.black};

        @media (max-width: 640px) {
          font-size: 50px;
          text-align: center;
        }
      }

      .banner-subtitle {
        max-width: 50%;
        color: ${colors.grayMedium};
        font-size: 20px;
        margin-top: 32px;
        margin-bottom: 48px;
        line-height: 35px; 

        @media (max-width: 640px) {
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }

  .card-container {
    width: 100%;
    padding: 150px 0 50px 0;

    position: relative;

    .bg-background {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: -7vh;
      z-index: 2;

      @media (min-width: 2300px) {
        width: 85%;
        left: 5vw;
        top: -24vh;
      }

      @media (min-width: 2000px) {
        top: -14vh;
      }

      @media (max-width: 1550px) {
        top: 1vh;
      }

      @media (min-width: 1101px) and (max-width: 1300px) {
        top: 11vh;
      }

      @media (max-width: 1100px) {
        display: none;
      }
    }

    .card-content {
      position: relative;
      z-index: 999;

      @media (max-width: 640px) {
        padding: 0 24px;
      }

      .card-item {
        width: 270px;
        max-height: 360px;
        background-color: ${colors.white};
        padding: 40px 32px;
        border-radius: 15px;
        margin-top: 40px;

        @media (max-width: 950px) {
          width: 100%;
          max-height: 330px;
        }

        @media (max-width: 640px) {
          width: 100%;
        }

        .card-title {
          margin-bottom: 24px;
        }

        .card-subtitle {
          font-size: 19px;
          color: ${colors.grayMedium};
        }
      }
    }
  }

  .process-container {
    position: relative;
    z-index: 999;
   
    .banner-texts {
      max-width: 70%;

      .banner-title {
        font-size: 70px;

        @media (max-width: 640px) {
          font-size: 50px;
          text-align: center;
        }
      }

      .banner-subtitle {
        font-family: "Helvetica Now Display Regular", sans-serif;
        font-size: 20px;
        line-height: 175%;
        margin-top: 72px;
        max-width: 100%;
        color: ${colors.black};
      }
    }
  }

  .process-content {
    position: relative;
    padding: 0;
    z-index: 999;

    @media (max-width: 640px) {
      padding: 0 34px;
    }

    .process-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      text-align: center;

      position: relative;
      margin-top: 20px;
    }

    .card-line-container {
      .card-line {
        height: 50%;
        border-bottom: 2px solid ${colors.grayMedium}; 
      }

      @media (max-width: 1041px) {
        display: none;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    margin-top: 72px;
    width: 100%;
    z-index: 999;

    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: space-between;

      height: 140px;
      text-align: center;
    }

    .tooltip-button-container {
      position: relative;

      img {
        position: absolute;
        bottom: -80px;
        left: 2%;
      }
    }
  }

  .cripto-actives-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding-top: 200px;

    position: relative;

    /* background-image: url(${Bubble});
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: -20vw -3vh; */

    .bubble {
      width: 55%;
      position: absolute;
      bottom: 76px;
      left: -67px;
      z-index: 1;

      @media (max-width: 1100px) {
        bottom: 222px;
        left: -124px;
      }

      @media (max-width: 1000px) {
        bottom: 232px;
        left: -145px;
      }

      @media (max-width: 900px) {
        bottom: 309px;
        left: -105px;
      }

      @media (max-width: 801px) {
        bottom: 814px;
        left: -67px;
      }

      @media (max-width: 800px) {
        display: none;
      }
    }

    .pc-container {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      margin-bottom: 120px;

      z-index: 999;

      .main-img {
        width: 100%;

        @media (max-width: 640px) {
          width: 90%;
          display: block;
          margin: auto;
        }
      }

      .card-cripto {
        position: absolute;
        left: -50px;
        bottom: 120px;

        @media (max-width: 990px) {
          display: none;
        }
      }

      .credit-used {
        position: absolute;
        top: -78px;
        right: -40px;

        @media (max-width: 990px) {
          display: none;
        }
      }
    }

    .cripto-actives-content {
      width: 100%;
      z-index: 999;

      .cripto-title {
        color: ${colors.grayMedium};
        font-family: "Aeonik Regular", sans-serif;
        font-size: 35px;
        letter-spacing: -0.7px;
        margin-bottom: 67px;
      }

      .card-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.disabled {
          filter: grayscale(1);
        }
      }

      .cripto-empty-items {
        @media (max-width: 980px) {
          display: none;
        }
      }
    }
  }
`;

export const FormButton = styled.button`
  width: 100%;
  height: 48px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.black};
  padding: 30px;
  border-radius: 50px;
  margin-top: 30px;
  border: none;

  transition: all ease-out 0.3s;

  &:hover {
    background-color: #000000;
    transition: all ease-out 0.3s;

    .move-top {
      margin-top: -2px;
      transition: all ease-out 0.3s;
    }
  }
`;