import React from "react";
import * as Style from './Advantages.styles';
import * as Text from '~/styles/text';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import { adavantagesTokens} from "~/data/precatorio";

function Advantages() {
  const { t, i18n } = useTranslation();

  return (
    <Style.AdvantagesContainer>
      <Container>
        <Row>
          <Col lg={7}>
            <Text.Heading1Bold fontSize="40px" marginBottom={40}>
              {t("precatorio.advantages.title")}
            </Text.Heading1Bold>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          {adavantagesTokens.map((item) => (
            <Col md={6} lg={4} key={item.title}>
              <div className="card">
                <img src={item.icon} alt="" />

                <Text.Heading2Bold fontSize="22px" marginBottom={12}>
                  {t(item.title)}
                </Text.Heading2Bold>

                <Text.Heading3 fontSize="18px">{t(item.text)}</Text.Heading3>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Style.AdvantagesContainer>
  );
}

export default Advantages;
