import styled from 'styled-components';
import { colors } from '~/styles';

export const AdvantagesContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  background: ${colors.white};

  .card {
    padding: 30px;
    border: 1px solid ${colors.mainGray};
    border-radius: 15px;

    min-height: 240px;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;

      margin-bottom: 20px;
    }
  }
`;