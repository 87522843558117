import React, { useState, useRef, useEffect } from 'react';
import { colors } from "~/styles";
import * as Style from "./CapOtc.styles";
import * as Text from "~/styles/text";
import { Parallax, useController } from "react-scroll-parallax";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import { Reveal, Fade } from "react-reveal";
import { mask } from 'remask';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { Oval } from 'react-loader-spinner';
import { If, Then, Else } from 'react-if';

import Swal from 'sweetalert2'

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";
import GenericButton from "~/components/GenericButton";

import useDetectDevice from "~/hooks/detectDevice";

import ArrowDownGrayIcon from "~/images/menu/arrow-down-gray.svg";
import MetalIcon from "~/images/home/metal.svg";
import MockupPc from "~/images/otc/mockup-pc.png";
import CardCripto from "~/images/otc/card-cripto.png";
import CreditUsed from "~/images/otc/credit-used.png";
import BtcIcon from "~/images/otc/btc.png";
import UsdcIcon from "~/images/otc/usdc.png";
import UsdtIcon from "~/images/otc/usdt.png";
import PotencializeYourBiz from "~/images/otc/potencialize-seu-negocio.png";
import SupportWithChat from "~/images/otc/support-with-chat.png";
import QuotesSupport from "~/images/otc/quotes-support.png";
import TooltipImg from "~/images/otc/tooltip-client.png";
import ApiIcon from "~/images/otc/api-exclusiva.svg";
import SupportIcon from "~/images/otc/suporte-premium.svg";
import LiquidityIcon from "~/images/otc/liquidez-garantida.svg";
import CreditIcon from "~/images/otc/credito-operacional.svg";
import Step1Icon from "~/images/otc/step-01.svg";
import Step2Icon from "~/images/otc/step-02.svg";
import Step3Icon from "~/images/otc/step-03.svg";

import BgBackground from "~/images/otc/bg-background.png";
import Bubble from "~/images/otc/bubble.svg";

const faq = [
  {
    question: 'O que é a Mesa de Operações do Capitual?',
    answer: 'A mesa de operações do Capitual, é um serviço que permite negociações over-the-counter (OTC) de criptoativos.',
  },
  {
    question: 'Quais criptoativos são aceitos para negociação?',
    answer: 'Inicialmente, vamos trabalhar com negociações em BTC, USDT e USDC.',
  },
  {
    question: 'Preciso ter uma conta no Capitual para operar com a mesa de operações ?',
    answer: 'Não é necessário ter conta com a gente para fazer operações de OTC. Ser cliente do Capitual, te dá algumas vantagens, então se você já for nosso cliente, negociar com a nossa mesa de operações vai ser mais fácil,  pois poderá apenas preencher o formulário para operações de OTC, se você não tem conta com a gente, será necessário passar por um processo de onboarding com a submissão de alguns documentos.',
  },
  {
    question: 'Existe alguma taxa que será aplicada nas transações realizadas na mesa de operações?',
    answer: 'Não. Não aplicamos nenhuma taxa nos serviços ou operações realizadas no Capitual.',
  },
  {
    question: 'Por que fazer negociações com o Capitual e não outras corretoras do mercado?',
    answer: 'Os valores aplicados por nós, são os mais baixos praticados comparados com outras exchanges, P2P e até outros OTCs, além disso oferecemos um serviço personalizado e exclusivo. Nossa presença no exterior também nos ajuda a oferecer as melhores cotações. Sem contar que aqui, damos prioridade para a segurança e agilidade.',
  },
  {
    question: 'Como funciona o benefício de crédito operacional oferecido pelo Capitual?',
    answer: 'Sabemos que os valores dos ativos são voláteis e podem mudar a qualquer instante, é por isso que no Capitual você tem o crédito de operações. Como assim? Ao ver um valor que é interessante para suas negociações, você pode falar com um dos nossos atendentes ou ir no aplicativo web, e garantir o valor do ativo para sua negociação e você pode realizar o pagamento desses ativos até o final do expediente bancário. ',
  },
  {
    question: 'Como posso acessar meu aplicativo Web para operações OTC?',
    answer: 'No Capitual é tudo simples, descentralizado e te dá independência total! Por isso quando finalizar e seu cadastro for aprovado, poderá fazer seu login na aplicação web com um login, que será seu e-mail cadastrado e uma senha. Você pode acessar a aplicação web, aqui.',
  }
];

const swalWithBootstrapButtons = Swal.mixin({  
  customClass: {
    confirmButton: 'default-button'
  },
  buttonsStyling: false
})

function CapOtc() {
  const { t, i18n } = useTranslation();
  const { isMobile, isDesktop } = useDetectDevice();
  const { parallaxController } = useController();
  
  const [isLoading, setIsLoading] = useState(false);
  const [searchUrl, setSearchUrl] = useState(null);
  const [openAccordions, setOpenAccordions] = useState([]);
  const [registerAccount, setRegisterAccout] = useState({
    tradingName: '',
    taxpayer: '',
    email: '',
    name: '',
    phone: ''
  })

  const registerRef = useRef(null);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    if(currentUrl.searchParams.get("ref") !== null) {
      return setSearchUrl(currentUrl.searchParams.get("ref"));
    }
    
    return setSearchUrl(null);
  }, []);

  const handleInput = (e) => {
    setRegisterAccout({...registerAccount, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const turnstileRest = formData.get('cf-turnstile-response');

    setIsLoading(true);

    try {
      fetch('https://capotc.capitual.io/api/v1.0/signup/landing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          turnstileToken: turnstileRest,
          tradingName: registerAccount.tradingName,
          taxpayer: registerAccount.taxpayer,
          email: registerAccount.email,
          representative: {
            name: registerAccount.name,
            phone: registerAccount.phone,
          },
          referrer: searchUrl !== null ? searchUrl : '',
        }),
      }).then(response => response.json())
      .then(response => {

        if(response.error) {
          switch(response.data) {
            case "E_INVALID_TURNSTILE_TOKEN":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'A verificação de captcha falhou ou expirou.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;          
            case "E_ENTITY_ALREADY_EXISTS":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Já existe um processo criado com esses dados.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;
            case "E_EMAIL_ALREADY_EXISTS":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Já existe um processo com esse CNPJ.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;
            case "E_PHONE_ALREADY_EXISTS":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Já existe um processo com esse telefone.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;
            case "E_INVALID_TAXPAYER":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Este CNPJ não é válido.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;  
            case "E_INVALID_EMAIL":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Este E-mail não é válido.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;  
            case "E_INVALID_PHONE_NUMBER":
              swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: 'Este Telefone não é válido.',
                icon: 'error',
                confirmButtonText: 'Tentar novamente',
                allowOutsideClick: false
              }).then(() => { setIsLoading(false); window.location.reload() });
              break;                          
          }
        } else {
          swalWithBootstrapButtons.fire({
            title: 'Enviado!',
            text: 'A sua solicitação de abertura de conta foi enviada com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            allowOutsideClick: false
          }).then(() => { setIsLoading(false); window.location.reload() });
        }
      })
      } catch (error) {
        console.log(error);
      }
  };

  const cnpjMask = (value) => {
    if (!value) return '';
    const v = String(value);
    return mask(v, '99.999.999/9999-99');
  };

  const formatPhone = (phone) => {
    if (!phone) return '';
    const p = String(phone).includes('+') ? String(phone) : `+55 ${phone}`;
  
    return (
      p
        // the regex below replace "( )" and/or "-" and/or empty spaces by ''
        .replace(/\(|\)| |-/gm, '')
        // this regex maps the given string and separate portions of that string
        // Exemple: (\d{2}) means the first 2 characters (which can be any digit characters (numbers))
        // should be replaced by the following example, given 1234567890 ====> 12 34567890, and so on,
        // following the pattern '$1 ($2) $3 $4',
        .replace(/(\d{2})(\d{2})(\d{5})(\d{4,})/g, '$1 ($2) $3 $4')
    );
  };

  return (
    <Style.Container>
      <Menu isDarkMenu={!!isMobile()} newButton={isDesktop() &&
        <GenericButton
          grayHover
          buttonType="fourth"
          onClick={() => {}}
          paddingVertical={20}
          borderRadius={50}
          href="#register"
        >
          <Text.Heading5Bold
            fontSize="15px"
            color={ colors.white }
          >
            Iniciar o Cadastro
          </Text.Heading5Bold>
        </GenericButton>
      } />
      <Style.StripedContainer>
        <Container>
          <Row>
            <Col>
              <div className="banner">
                <div className='banner-texts'>
                  <h1 className='banner-title'>
                    Explore oportunidades em operações OTC no Capitual
                  </h1>

                  <p className="banner-subtitle">
                    Grandes negociações de criptomoedas, atendimento exclusivo, e cotações vantajosas!
                  </p>

                  <GenericButton
                    grayHover
                    buttonType="secondary"
                    onClick={() => {}}
                    paddingVertical={30}
                    borderRadius={50}
                    href="#register"
                  >
                    <Text.Heading5Bold
                      fontSize="17px"
                      color={ colors.white }
                    >
                      Iniciar o cadastro
                    </Text.Heading5Bold>
                  </GenericButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="card-container">
          <img src={BgBackground} className="bg-background" alt="" />
          <Container className='card-content'>
            <Row>
              <Col xs={12} md={6} lg={4} xl={3}>              
                <div className="card-item">
                  <img
                    src={LiquidityIcon}
                    style={{height: "110px"}}
                    alt={t("products.metals.title")}
                  />

                  <div className="ie mt-20">
                    <Text.H3Medium className="card-title">
                    
                      Liquidez garantida
                    </Text.H3Medium>
                    <Text.H5Regular className="card-subtitle mb-20">
                      Negocie com maior agilidade, tenha vantagens estratégicas e competitivas.
                    </Text.H5Regular>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>              
                <div className="card-item">
                  <img
                    src={SupportIcon}
                    style={{height: "110px"}}
                    alt={t("products.metals.title")}
                  />

                  <div className="ie mt-20">
                    <Text.H3Medium className="card-title">
                      Suporte Premium
                    </Text.H3Medium>
                    <Text.H5Regular className="sb c card-subtitle mb-20">
                      Fale com nossos agentes especializados para fazer negociações ou pedir ajuda.
                    </Text.H5Regular>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>              
                <div className="card-item">
                  <img
                    src={CreditIcon}
                    style={{height: "110px"}}
                    alt={t("products.metals.title")}
                  />

                  <div className="ie mt-20">
                    <Text.H3Medium className="card-title">
                      Crédito Operacional
                    </Text.H3Medium>
                    <Text.H5Regular className="sb c card-subtitle mb-20">
                      Garanta as melhores cotações, e use o seu crédito pré aprovado para operações.
                    </Text.H5Regular>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} xl={3}>              
                <div className="card-item">
                  <img
                    src={ApiIcon}
                    alt={t("products.metals.title")}
                  />

                  <div className="ie mt-20">
                    <Text.H3Medium className="card-title">
                      API exclusiva
                    </Text.H3Medium>
                    <Text.H5Regular className="sb c card-subtitle mb-20">
                      Customize e otimize as funcionalidades para o que você precisa. 
                    </Text.H5Regular>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col>
              <div className="process-container">
                <div className='banner-texts'>
                  <h1 className='banner-title'>
                    Do início ao fim, transparência em cada etapa do processo
                  </h1>

                  <p className="banner-subtitle">
                    Dê os primeiros passos para participar da nossa mesa de operações OTC.
                  </p>
                </div>
              </div>

              <div className="process-content">
                <Container>
                  <Row>
                    <Col md={6} lg={4} xl={3}>              
                      <div className="process-card">
                        <img
                          src={Step1Icon}
                          alt={t("products.metals.title")}
                        />

                        <div className="mt-30">
                          <Text.H5Medium className="card-title">
                            Cadastre-se e tenha acesso ao painel OTC
                          </Text.H5Medium>
                        </div>
                      </div>
                    </Col>
                    <Col className='card-line-container'>
                      <div className='card-line' />
                    </Col>
                    <Col md={6} lg={4} xl={3}>              
                      <div className="process-card">
                        <img
                          src={Step2Icon}
                          alt={t("products.metals.title")}
                        />

                        <div className="mt-30">
                          <Text.H5Medium className="card-title">
                            Envie os documentos necessários para análise
                          </Text.H5Medium>
                        </div>
                      </div>
                    </Col>
                    <Col className='card-line-container'>
                      <div className='card-line' />
                    </Col>
                    <Col md={6} lg={4} xl={3}>              
                      <div className="process-card">
                        <img
                          src={Step3Icon}
                          alt={t("products.metals.title")}
                        />

                        <div className="mt-30">
                          <Text.H5Medium className="card-title">
                            Inicie suas negociações na mesa de operações
                          </Text.H5Medium>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              <Container>
                <Row>
                  <Col>
                    <div className="button-container">
                      <GenericButton
                        width="230px"
                        grayHover
                        buttonType="primary"
                        onClick={() => {}}
                        paddingVertical={30}
                        borderRadius={50}
                        href="#register"
                      >
                        <Text.Subtitle1Medium
                          fontSize="17px"
                          color={ colors.white }
                          textAlign="center"
                        >
                          Iniciar o cadastro
                        </Text.Subtitle1Medium>
                      </GenericButton>    
                      <div className='tooltip-button-container'>
                        <GenericButton
                          width="304px"
                          buttonType="inlineBlue"
                          onClick={() => {}}
                          textAlign="center"
                          paddingVertical={30}
                          borderRadius={50}
                          marginLeft={8}
                          href="#register"
                        >
                          <Text.Subtitle1Medium
                            fontSize="17px"
                            color={ colors.newBlue }
                          >
                            Sou cliente CapBusiness
                          </Text.Subtitle1Medium>
                        </GenericButton>  
                        <img src={TooltipImg} />
                      </div>
                    </div>              
                  </Col>                
                </Row>
              </Container>              
            </Col>
          </Row>
        </Container>

        <div className="cripto-actives-container">
          <img src={Bubble} className="bubble" />
          <Container>
            <Row>
              <Col>
                <div className="pc-container">
                  <Fade big>
                    <img src={CreditUsed} className="card-cripto" alt="" />
                  </Fade>
                  <img src={MockupPc} className="main-img" />
                  <Fade big>
                    <img src={CardCripto} className="credit-used" alt="" />
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>


          <div className='cripto-actives-content'>
            <Container>
              <Row>
                <Col>
                    <h3 className='cripto-title'>
                      Criptoativos disponíveis
                    </h3>
                </Col>
              </Row>
              <Row>
                <Col className='cripto-empty-items' />
                <Col xs={12} md={4} lg={3}>              
                  <div className="card-item mb-50">
                    <img
                      src={UsdtIcon}
                      alt=""
                    />
                  </div>
                </Col> 
                <Col xs={12} md={4} lg={3}>              
                  <div className="card-item mb-50">
                    <img
                      src={UsdcIcon}
                      alt=""
                    />
                  </div>
                </Col>
                <Col xs={12} md={4} lg={3}>              
                  <div className="card-item disabled mb-50">
                    <img
                      src={BtcIcon}
                      alt=""
                    />
                    <Text.Subtitle1Medium color={colors.grayRegular} marginTop={24}>Em breve</Text.Subtitle1Medium>
                  </div>
                </Col>
                <Col className='cripto-empty-items' />                           
              </Row>
            </Container>
          </div>
        </div>
      </Style.StripedContainer>

      <div className='content'>
        <section className="ry nb">
          <div className={`features-split-inner rh ig light`}>
            <div className="nu nc">
              <div className="tcontainern mb-100">
                <Container>
                  <Row className='align-center'>
                    <Col xs={12} md={6}>
                      <div className="nl ab button-otc" data-reveal-container=".split-item">
                        <h3
                          className={`sw ol light features-title`}
                          style={{ marginBottom: 20 }}
                        >
                          Potencialize seu negócio com crédito operacional
                        </h3>
                        <p className={`sb light features-subtitle`}>Aqui você consegue garantir cotações, e finalizar as negociações até o final do horário de expediente bancário. Então se você precisa de uma cotação exclusiva, mas não consegue terminar a operação naquele momento, não se preocupa que o Capitual resolve para você!</p>
                        {/* <GenericButton
                          width="230px"
                          grayHover
                          buttonType="primary"
                          onClick={() => {}}
                          paddingVertical={30}
                          borderRadius={50}
                          marginTop={25}
                        >
                          <Text.Subtitle1Medium
                            fontSize="17px"
                            color={ colors.white }
                            textAlign="center"
                          >
                            Saiba mais
                          </Text.Subtitle1Medium>
                        </GenericButton>  */}
                      </div>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="na nf" data-reveal-container=".split-item">
                        <img src={PotencializeYourBiz} alt={t("banking.title")} style={{ width: '100%'}} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>            
              <div className="tcontainern mb-100">
                <Container>
                  <Row className='align-center'>
                    <Col xs={12} md={6}>
                      <div
                        className="na nf"
                        data-reveal-container=".split-item"
                      >
                        <img
                          src={SupportWithChat}
                          alt={t("wallets.title")}
                          style={{ width: '100%'}}
                        />
                      </div>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="nl ab button-otc" data-reveal-container=".split-item">
                        <h3
                          className={`sw ol light features-title`}
                          style={{ marginBottom: 20 }}
                        >
                          Eleve sua experiência de negociação via Chat
                        </h3>
                        <p className={`sb light features-subtitle`}>Temos pessoas especializadas para te atender! Além das nossas plataformas você pode conversar com nossos atendentes especializados dentro do expediente bancário e fazer suas negociações e pedir ajuda. Estamos prontos para te atender!</p>
                        {/* <GenericButton
                          width="230px"
                          grayHover
                          buttonType="primary"
                          onClick={() => {}}
                          paddingVertical={30}
                          borderRadius={50}
                          marginTop={25}
                        >
                          <Text.Subtitle1Medium
                            fontSize="17px"
                            color={ colors.white }
                            textAlign="center"
                          >
                            Negociar via Chat
                          </Text.Subtitle1Medium>
                        </GenericButton>  */}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="tcontainern mb-100">
                <Container>
                  <Row className='align-center'>
                    <Col xs={12} md={6}>
                      <div className="nl ab" data-reveal-container=".split-item">
                        <h3
                          className={`sw ol light features-title`}
                          style={{ marginBottom: 20 }}
                        >
                          Cotações adaptadas às suas metas financeiras
                        </h3>
                        <p className={`sb light features-subtitle`}>Oferecemos as melhores cotações, isso porque o Capitual já está no mercado a muito tempo e consegue garantir liquidez e preços competitivos.  Também há a possibilidade de negociar com nossos agentes, e fazer o melhor negócio.</p>
                      </div>
                    </Col>

                    <Col xs={12} md={6}>
                      <div className="na nf" data-reveal-container=".split-item">
                        <img src={QuotesSupport} alt={t("banking.title")} style={{ width: '100%'}} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>              
            </div>
          </div>
        </section>

        <section id="register" className="ir nb ab reveal-from-bottom pt40" ref={registerRef}>
          <div className="tcontainern">
            <div className="bg-grad-blue rh is">
              <Container>
                <Row>
                  <Col lg={6} >
                    <Fade big>
                      <div className='bg-text-container'>
                        <h4 className="bg-grad-title">Desbloqueie oportunidades únicas na nossa mesa de operações</h4>
                      </div>
                    </Fade>
                  </Col>

                  <Col lg={{ span: 5, offset: 1 }}>
                    <form method="post" onSubmit={(e) => isLoading === false ? handleSubmit(e) : e.preventDefault()}>
                      <Text.H2Medium marginBottom={15}>
                        Registre-se já para começar!
                      </Text.H2Medium>
                      <input type="text" onChange={handleInput} placeholder="Razão Social" name="tradingName" required />
                      <input type="text" value={cnpjMask(registerAccount.taxpayer)} onChange={handleInput} placeholder="CNPJ" name="taxpayer" required />
                      <input type="email" onChange={handleInput} placeholder="E-mail" name="email" required />
                      <input type="text" onChange={handleInput} placeholder="Nome" name="name" required />
                      <input type="phone" value={formatPhone(registerAccount.phone)} onChange={handleInput} placeholder="Telefone" name="phone" required />
                      <div class="cf-turnstile mt-20" data-sitekey="0x4AAAAAAAJHE6RPwzn8OW6R" name="turnstileToken" data-callback="javascriptCallback"></div>
                      <Style.FormButton type="submit">
                        <div className="move-top">
                          <If condition={isLoading}>
                            <Then>
                              <Oval
                                height={30}
                                width={30}
                                color="white"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                              />
                            </Then>

                            <Else>
                              <Text.Heading5Bold
                                fontSize="17px"
                                color={ colors.white }
                              >
                                Continuar
                              </Text.Heading5Bold>
                            </Else>
                          </If>
                        </div>
                      </Style.FormButton>
                    </form>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>

        <div className='frequently-asked-container'>
          <Container>
            <Row>
              <Col sm="12">
                <h3 className='frequently-asked-title'>
                  Perguntas frequentes
                </h3>

                <Accordion
                  onChange={(open) => setOpenAccordions(open)}
                  allowMultipleExpanded
                  allowZeroExpanded
                >
                  {faq.map((item, index) => (
                    <AccordionItem
                      key={index}
                      className="accordion-line"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="accordion-item">
                          <div className="accordion-item-title">
                            <Text.Heading1Bold
                              fontSize="19px"
                            >
                              {item.question}
                            </Text.Heading1Bold>
                          </div>

                          <div className="accordion-arrow">
                            <img
                              style={{
                                transform: openAccordions.includes(item.question)
                                  ? "rotate(-180deg)"
                                  : "",
                                transition: "all 0.5s ease",
                              }}
                              src={ArrowDownGrayIcon}
                              alt="arrow"
                            />
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel className="accordion-panel-anim">
                        <AccordionItemState>
                          {({ expanded }) => (
                            <AnimatePresence>
                              {expanded && (
                                <motion.div
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: "auto" }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <div className="subitem-accordion-opened">
                                    <Container>
                                      <Row>
                                        <Col>
                                          <div className="column">
                                            <div className="subitem-accordion-opened-header">
                                              <Text.Heading1Medium
                                                fontSize="21px"
                                                marginBottom={7}
                                              >
                                                {item.answer}
                                              </Text.Heading1Medium>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          )}
                        </AccordionItemState>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Col>
            </Row>
          </Container>  
        </div>      
      </div>

      <Footer backgroundColor={colors.white} />
  </Style.Container>
  );
}

export default CapOtc;
