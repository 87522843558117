import styled from 'styled-components';
import { colors } from '~/styles';

export const Container = styled.div`

  .content {
    padding: 60px 0;

    background-color: ${colors.white};
    overflow-y: auto;

    #table {
      width: 100%;
      border-radius: 20px;

      .main-table {
        background-color: #026FF4;
        color: white;
      }

      td, th {
        border: 2px solid ${colors.mainGray};
        padding: 8px;
        vertical-align: top;
      }

      td {
        max-width: 200px;
      }
    }
  }
`;
export const Header = styled.div`
  background-color: #026FF4;

  .banner {
    margin-top: 80px;

    padding: 96px 0;
  }
`;