import styled from 'styled-components';
import { colors } from '~/styles';
import { darken } from 'polished';
import { breakpoints, screen } from '~/styles/breakpoints';

export const Container = styled.div`
  padding: 90px 0;
  background-color: ${props => props.backgroundColor || colors.white};

  .logo {
    width: 165px;
  }

  .award-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 10px;

    img {
      width: 41%;

      margin-right: 10px;
    }

    .vertical-img {
      width: 15% !important;
      height: 25%;
    }
  }

  .social-media-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 38.7px;
      height: 36.84px;
      background-color: ${colors.white};
      border-radius: 10px;
      box-shadow: 2px 5px 5px rgb(22 24 34 / 10%);
      margin: 30.44px 12.28px 0 0;
    }
  }

  .footer-sub-menu {
    padding-left: 0;

    li {
      font-family: "Helvetica Now Display Medium", sans-serif;
      color: #757893;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 2%;

      a {
        color: ${colors.grayMedium};

        &:hover {
          color: ${colors.black} !important;
        }
      }
    }
  }

  .link {
    font-family: "Helvetica Now Display Medium", sans-serif;
    color: ${colors.primary};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;

    &:hover {
      color: ${darken(0.1,colors.primary)};
    }
  }


  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 100px;

    .translate-container {
      display: flex;
      justify-content: space-between;
      width: 150px;

      a {
        font-size: 16px;
        line-height: 24px;
        color: #757893;
      }

      a.active {
        color: ${colors.black};
      }
    }

    .date {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 991px) {
    .col {
      margin-top: 60px;
    }

    .col-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      margin-top: 30px;
      text-align: center;
    }
  }
`;
