import styled from 'styled-components';
import { colors } from '~/styles';

export const Container = styled.div`

  .content {
    padding: 60px 0;

    background-color: ${colors.white};

    ul {
      li {
        list-style-type: square;
        font-size:13px;
      }
    }

    table {
      border: 1px solid ${colors.black};
      padding: 30px;
      border-radius: 10px;
      border-collapse: unset;

      td {
        vertical-align: top;
        
        tr {
          font-family: 'Helvetica Now Display Medium', sans-serif;
          font-size: 18px;
          line-height: 160%;
          letter-spacing: 0.025em;
          color: ${colors.black};
        }
      }
    }
  }
`;

export const Header = styled.div`
  background-color: #026FF4;

  .banner {
    margin-top: 80px;

    padding: 96px 0;
  }
`;