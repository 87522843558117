import React, { useState, useEffect } from 'react';
import { colors } from '~/styles';
import * as Style from './PoliticaCookie.styles';
import * as Text from '~/styles/text';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation, Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import useDetectDevice from '~/hooks/detectDevice';

import Menu from '~/components/Menu';
import Footer from '~/components/Footer';
import baseUrl from '~/config';
import axios from 'axios';

function PoliticaCookie() {
  const { t, i18n } = useTranslation();

  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);

  const { isMobile, isDesktop } = useDetectDevice();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl.ENDPOINT[1]}/api/capitual-com-legal-privacy-policy?populate=deep`
      )
      .then((res) => {
        setPrivacyPolicyData(res.data.data);
      });
  }, []);

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Política de Cookies
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col>
              <Text.Heading1Medium
                fontSize="31px"
                lineHeight={130}
                marginTop={60}
                marginBottom={30}
              >
                Política de Cookies
              </Text.Heading1Medium>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                A presente Política de Cookies é um documento complementar à
                Política de Privacidade do Capitual Instituição de Pagamento S.A
                e descreve os cookies e tecnologias semelhantes são utilizados
                em nosso site. Para entender como tratamos seus dados pessoais
                consulte nossa Política de Privacidade disponível neste
                link:&nbsp;
                <a href="https://capitual.com/legal/privacy-policy">
                  https://capitual.com/legal/privacy-policy
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                O que são Cookies?
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Cookies são pequenos arquivos de texto que podem ou não ser
                armazenados no seu dispositivo quando você visita o nosso site.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Eles armazenam e reconhecem informações sobre a sua navegação em
                nossas páginas e retêm apenas informações relacionadas a suas
                preferências.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Assim, essa página consegue armazenar e recuperar os dados sobre
                os seus hábitos de navegação, de forma a melhorar a experiência
                de uso. Permitindo, assim, que o nosso site reconheça e lembre
                informações sobre a sua visita anterior, como suas preferências
                de idioma e outras configurações.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                É importante frisar que eles não contêm dados sensíveis nos
                termos estabelecidos pela Lei Geral de Proteção de Dados.
              </Text.Heading1Regular>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Tipos de Cookies
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Os cookies podem coletar dados para diferentes finalidades
                relacionadas às funcionalidades de nossos sites e aplicativos.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Os cookies, quanto ao seu tempo de vida, podem ser:
              </Text.Heading1Regular>
              <ul>
                <li>
                  <p>
                    <strong>Cookies de sessão ou temporários:</strong> são
                    temporários e são apagados do seu dispositivo assim que você
                    fecha seu navegador. Eles são essenciais para o
                    funcionamento básico do site e não armazenam informações
                    pessoais.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Cookies persistentes ou permanentes:</strong> são
                    cookies que permanecem no seu dispositivo por um período
                    determinado ou até que você os exclua manualmente. Eles são
                    usados para lembrar informações, como seu login e
                    preferências, para tornar sua próxima visita mais
                    conveniente.
                  </p>
                </li>
              </ul>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Os cookies, quanto a sua finalidade, podem ser:
              </Text.Heading1Regular>
              <ul>
                <li>
                  <p>
                    <strong>Cookies necessários:</strong> são cookies essenciais
                    que possibilitam a navegação e o acesso a todos os recursos
                    ofertados em nossas aplicações; sem estes, nossos serviços
                    podem apresentar mal desempenho ou até mesmo, não funcionar.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Cookies de desempenho:</strong> são cookies que
                    coletam informações anônimas sobre como os visitantes usam
                    nosso site. Eles nos ajudam a entender quais partes do site
                    são mais populares e a melhorar a experiência do usuário.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Cookies de funcionalidade:</strong> são cookies que
                    lembram as escolhas que você fez, como seu nome de usuário,
                    idioma ou região, para proporcionar uma experiência mais
                    personalizada.
                  </p>
                </li>
                <li>
                  <p>
                    <strong> Cookies de publicidade:</strong> são cookies que
                    direcionam anúncios em função dos seus interesses e limitam
                    a quantidade de vezes que o anúncio aparece.
                  </p>
                </li>
              </ul>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Por que usamos Cookies?
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                O Capitual utiliza Cookies para fornecer a melhor experiência de
                uso, tornando nossas aplicações mais fáceis, rápidas e
                personalizadas, tendo por base suas escolhas e comportamento de
                navegação.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Assim, buscamos entender como você utiliza nossas aplicações e
                ajustar o conteúdo para torná-lo mais relevante para você, além
                de lembrar de suas preferências.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Os Cookies participam deste processo porquanto armazenam, leem e
                executam os dados necessários para cumprir com o nosso objetivo.
              </Text.Heading1Regular>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Que tipo de Cookies utilizamos?
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Abaixo está a lista de cookies que podem ser usados em nosso
                site, juntamente com informações sobre seus propósitos e prazos
                de retenção:
              </Text.Heading1Regular>
              <Table responsive borderless>
                <thead>
                  <tr>
                    <th>Plataforma</th>
                    <th>Tipo</th>
                    <th>Tempo</th>
                    <th>Dados Coletados</th>
                    <th>Descrição</th>
                    <th>Período de retenção</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hotjar</td>
                    <td>Necessários</td>
                    <td>Permanente</td>
                    <td>
                      Dados de localização, tempo de duração da sessão, se é o
                      primeiro acesso, tipos de dispositivo, versão do navegador
                      e sistema operacional e endereço de IP.
                    </td>
                    <td>
                      Coleta as interações do usuário durante o acesso ao site
                      do Capitual como o objetivo de analisar essas interações e
                      melhorar a experiência.
                    </td>
                    <td>
                      30 dias
                      <br />
                      <a href="https://www.hotjar.com/legal/policies/privacy/">
                        Política da Hotjar
                      </a>
                      <br />
                      <br />
                      <a href="https://www.hotjar.com/policies/do-not-track/">
                        Hotjar - do-not-track
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Google Analytics</td>
                    <td>Necessários</td>
                    <td>Permanente</td>
                    <td>
                      Coleta as interações do usuário durante o acesso ao site
                      do Capitual como o objetivo de estabelecer métricas de
                      acesso, visualizações das páginas e taxas de rejeição.
                      Identificar além da tradicional taxa de exibição e hit de
                      uma página, localização geográfica do visitante, forma com
                      a qual chegou na página (através de links de outros sites,
                      buscador, AdSense ou diretamente pelo endereço), sistema
                      operacional, navegador, navegador e sistema operacional.
                    </td>
                    <td>Prazo de validade de 1 mês, 1 ano e até 2 anos.</td>
                    <td>
                      Prazo de validade de 1 mês, 1 ano e até 2 anos.
                      <br />
                      <a href="https://support.google.com/analytics/answer/7667196?hl=pt-BR%23:~:text=O%20per%C3%ADodo%20de%20armazenamento%20de,dados%20que%20voc%C3%AA%20j%C3%A1%20coletou.">
                        Retenção de dados - Google Analytics
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Cookies de publicidade
              </Text.Heading2>
              <Table responsive borderless>
                <thead>
                  <tr>
                    <th>Plataforma</th>
                    <th>Tipo</th>
                    <th>Tempo</th>
                    <th>Dados Coletados</th>
                    <th>Descrição</th>
                    <th>Período de retenção</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Facebook</td>
                    <td>Necessários</td>
                    <td>Permanente</td>
                    <td>
                      Informações públicas do perfil no Facebook: e-mail, foto
                      de perfil, média de idade, gênero, primeiro nome,
                      sobrenome, ID do Facebook.
                    </td>
                    <td>
                      São coletados dados que rastreiam as conversões de
                      anúncios do Facebook.
                    </td>
                    <td>
                      5 meses
                      <br />
                      <a href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0/">
                        Política de Cookies - Facebook
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Gerenciamento dos Cookies
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                A instalação de algumas modalidades de cookies está sujeita ao
                seu consentimento. Embora a maioria dos navegadores esteja
                inicialmente configurada para aceitar cookies automaticamente,
                você pode rever suas permissões a qualquer tempo. Isso significa
                que você pode optar por bloquear, aceitar ou ativar notificações
                para quando alguns cookies forem enviados ao seu dispositivo.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Como você pode ter notado no tópico anterior, o Capitual coleta
                apenas cookies classificados como necessários. Portanto, essa
                coleta é essencial para garantir o bom funcionamento do site que
                você está acessando.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Dessa forma, a continuação da navegação em nossos sites será
                interpretada como consentimento.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Conforme mencionado anteriormente, você tem o direito de, a
                qualquer momento e sem nenhum custo, alterar as permissões,
                bloquear ou recusar os Cookies. Você também pode configurá-los
                de acordo com suas preferências específicas. No entanto, é
                importante observar que não permitir a coleta de determinados
                Cookies pode afetar a funcionalidade alguns recursos da
                plataforma
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Para gerenciar os cookies do seu navegador, basta fazê-lo
                diretamente nas configurações do navegador, na área de gestão de
                Cookies.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Você pode acessar tutoriais sobre o tema diretamente nos links
                abaixo:
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">
                  Internet Explorer
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s">
                  Firefox
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
                  Safari
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR">
                  Google Chrome
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies">
                  Microsoft Edge
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Se você usa o&nbsp;
                <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
                  Opera
                </a>
                .
              </Text.Heading1Regular>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Alteração da Política
              </Text.Heading2>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Para o Capitual a privacidade e confiança são fundamentais para
                a nossa relação com você. Estamos sempre nos atualizando para
                manter os mais altos padrões de segurança.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Reservamo-nos o direito de atualizar esta Política de Cookies
                periodicamente para refletir mudanças em nossas práticas e
                regulamentações. As alterações entrarão em vigor assim que forem
                publicadas neste site, e a data da última atualização será
                revisada acima. Recomendamos que você verifique esta política
                regularmente para se manter informado sobre como usamos cookies.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Em caso de dúvidas sobre esta Política de Cookies, entre em
                contato conosco pelo seguinte e-mail:{' '}
                <a href="mailto:dpo@capitual.com">dpo@capitual.com</a>.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Esta Política de Cookies foi atualizada pela última vez:
                fevereiro de 03 de novembro de 2023.
              </Text.Heading1Regular>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default PoliticaCookie;
