import React from "react";
import { colors } from "~/styles";
import * as Style from "./NegociosProibidos.styles";
import * as Text from "~/styles/text";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";

function NegociosProibidos() {
  const { t, i18n } = useTranslation();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Negócios Proibidos
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col className="mb-30">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Prohibited businesses and prohibited use
              </Text.Heading2>

              <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                This document contains a list of categories of businesses, business practices and sale items (“Prohibited Businesses”) that are not allowed to use Capitual.com services
              </Text.Heading1Regular>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={6}>
              <ul>
                <li><p>Unlicensed money transmitter</p></li>
                <li><p>Unlicensed payment processor</p></li>
                <li><p>Unlicensed e-money institution</p></li>
                <li><p>Unlicensed financial services</p></li>
                <li><p>Counterfeit products</p></li>
                <li><p>Products that infringes upon the copyright, trademark or trade secrets of any party</p></li>
                <li><p>Stolen goods</p></li>
                <li><p>Narcotics</p></li>
                <li><p>Controlled substances, prescription and pharmaceutical services</p></li>
                <li><p>Drug paraphernalia or any substance designed to mimic illegal drugs</p></li>
                <li><p>Gambling or sports forecasting, except where permitted, with written permission issued by Capitual Ltd</p></li>
                <li><p>Betting</p></li>
                <li><p>Prostitution</p></li>
                <li><p>Human trafficking</p></li>
                <li><p>Pornography, except where permitted, with written permission issued by Capitual Ltd</p></li>
                <li><p>Child pornography or pornography involving non-consenting individuals (‘revenge porn’), even if no intercourse is represented</p></li>
                <li><p>Violent acts towards self or others</p></li>
              </ul>            
            </Col>
            
            <Col sm={12} md={12} lg={6}>
              <ul>
                <li><p>Violent media</p></li>
                <li><p>Encouraging, promoting, facilitating or instructing regarding violence against self or others</p></li>
                <li><p>Extortion, ‘blackmail’ or any effort to induce unearned payments</p></li>
                <li><p>Unlicensed sale of firearms and weapons</p></li>
                <li><p>Deceptive marketing practices</p></li>
                <li><p>Providing inaccurate or false information, leading to user’s mistake</p></li>
                <li><p>Any businesses that violates any law, statute, ordinance or regulation</p></li> 
                <li><p>Acting in concert with others or having multiple accounts in order to bypass account limits</p></li>
                <li><p>Interfering with third-parties’ access to any part of our service</p></li>
                <li><p>Defaming, abusing, harassing, stalking, threatening, violating or infringing legal rights of others</p></li>
                <li><p>Publishing, distributing or disseminating unlawful material or information</p></li> 
                <li><p>Transmitting and sharing viruses, malwares, worms, trojan horses, keyloggers or any other malicious softwares</p></li>
                <li><p>Compromising accounts, computer systems or networks that belong to Capitual Ltd’s infrastructure.</p></li>
                <li><p>Using anyone else’s account</p></li>     
              </ul>            
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default NegociosProibidos;