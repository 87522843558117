import styled from "styled-components";
import { colors } from "~/styles";
import { breakpoints, screen } from "~/styles/breakpoints";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  
  margin-top: 34px;
  width: 100%;
  height: 74px;


  z-index: 9999999999;

  @media (max-width: 1045px) {
    margin-top: 0px;
  }  

  .item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 50%;
    max-width: 1000px;
    height: 74px;

    padding: 22px 20px;
    border-radius: 50px;
    transition: all 0.3s ease;

    background-color: ${colors.black};

    @media (max-width: 1045px) {
      padding: 22px 20px;

      width: 100%;
      border-radius: 0;
      background-color: ${props => props.isDarkMenu ? colors.white : colors.transparent};

      &.is-sticky {
        background-color: ${colors.white};
        box-shadow: 2px 5px 5px rgb(22 24 34 / 10%);
        transition: all 0.3s ease;
      }
    }

    @media (min-width: 1046px) and (max-width: 1450px) {
      width: 70%;
    }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 30px;
    height: 20px;

    cursor: pointer;

    span {
      height: 3px;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
  }

  .menu-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .menu-item-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-left: 25px;
        cursor: pointer;

        p {
          margin-right: 9.26px;
        }
      }

      .sub-menu {
        display: none;

        &.visible {
          display: block !important;

          position: fixed;

          padding: 10px;
          margin-left: -30px;
        }

        .sub-menu-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          width: 240px;
          padding: 10px;
          background-color: #ffffff;
          border-radius: 10px;
          transition: all 0.3s ease;
        }

        .sub-menu-content a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          width: 100%;
          padding: 12px 16px;
          color: #272937;

          cursor: pointer;
          transition: all ease-out 0.3s;

          &.disabled {
            opacity: 0.3;
            cursor: not-allowed;

            p {
              color: ${colors.grayLight};
            }
          }
        }

        .sub-menu-content a:hover {
          background-color: #f8f9fb;
          border-radius: 10px;
          transition: all ease-out 0.3s;
        }

        .sub-menu-content a p {
          margin-bottom: 0;
          margin-left: 15px;
          transition: all ease-out 0.3s;
        }

        .sub-menu-content a:hover p {
          margin-left: 20px;
          transition: all ease-out 0.3s;
        }
      }
    }
}
`;
