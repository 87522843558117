import React from "react";
import * as Style from "./PrecatorioSection.styles";
import * as Text from "~/styles/text";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { colors } from "~/styles";
import { precatorio } from "~/data/precatorio";

import GenericButton from "~/components/GenericButton";

import PrecatorioVideo from "~/images/portfolio/precatorio/1_2-23-CBR3.mp4";
import HowtoBannerImage from "~/images/portfolio/precatorio/how-to-banner.png";
import PrecaSymbolIcon from "~/images/portfolio/precatorio/preca-symbol.svg";

function PrecatorioSection() {
  const { t, i18n } = useTranslation();

  return (
    <Style.PrecatorioSectionContainer>
      <div className="how-to-container">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <Text.Heading1Bold marginBottom={40} fontSize="40px">
                {t("precatorio.howItWorks.title")}
              </Text.Heading1Bold>

              <div className="how-to-item">
                <div className="item-number">
                  <Text.Heading1Bold fontSize="18px">1</Text.Heading1Bold>
                </div>

                <Text.Heading1Regular
                  fontSize="18px"
                  lineHeight={135}
                  letterSpacing={1}
                >
                  {t("precatorio.howItWorks.firstStep")}
                </Text.Heading1Regular>
              </div>

              <div className="how-to-item">
                <div className="item-number">
                  <Text.Heading1Bold fontSize="18px">2</Text.Heading1Bold>
                </div>

                <Text.Heading1Regular fontSize="18px">
                  {t("precatorio.howItWorks.secondStep")}
                </Text.Heading1Regular>
              </div>

              <div className="how-to-item">
                <div className="item-number">
                  <Text.Heading1Bold fontSize="18px">3</Text.Heading1Bold>
                </div>

                <Text.Heading1Regular fontSize="18px">
                  {t("precatorio.howItWorks.thirdStep")}
                </Text.Heading1Regular>
              </div>

              <div className="how-to-item">
                <div className="item-number">
                  <Text.Heading1Bold fontSize="18px">4</Text.Heading1Bold>
                </div>

                <Text.Heading1Regular fontSize="18px">
                  {t("precatorio.howItWorks.fourthStep")}
                </Text.Heading1Regular>
              </div>
            </Col>

            <Col xs={12} md={12} lg={6}>
              <video
                className="precatorio-video"
                muted
                autoPlay
                loop
                playsInline
              >
                <source src={PrecatorioVideo} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <div className="how-to-banner">
            <Col xs={{ span: 12, order: "last" }} md={12} lg={5} xl={4}>
              <img
                src={HowtoBannerImage}
                className="how-to-banner-image"
                alt=""
              />
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              md={{ span: 12, order: "first" }}
              lg={{ span: 5, offset: 1, order: "last" }}
              xl={{ span: 6, offset: 1, order: "last" }}
            >
              <Text.Heading3Bold
                fontSize="33px"
                marginBottom={27}
                color={colors.white}
              >
                {t("precatorio.sellToken.title")}
              </Text.Heading3Bold>

              <Text.Heading3 fontSize="21px" color={colors.white}>
                {t("precatorio.sellToken.description")}
              </Text.Heading3>
            </Col>
          </div>
        </Row>
      </Container>

      <div className="precatorio-content" id="PrecatoryAvailable">
        <Container>
          <Row>
            <Col
              xs={12}
              md={12}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
            >
              <div className="text-container">
                <Text.Heading1Bold fontSize="44px" marginBottom={20}>
                  {t("precatorio.precatorios.title")}
                </Text.Heading1Bold>

                <Text.Heading1Regular fontSize="21px">
                  {t("precatorio.precatorios.description")}
                </Text.Heading1Regular>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            {precatorio.map((item) => (
              <Col lg={6}>
                <div className="precatorio-item">
                  <div className="precatorio-item-header">
                    <div className={`preca-container ${item.color}`}>
                      <img src={PrecaSymbolIcon} alt="" />
                    </div>

                    <div className="column">
                      <Text.Heading2Bold fontSize="24.54px" marginBottom={2}>
                        {t(item.title)}
                      </Text.Heading2Bold>

                      <Text.Heading3 fontSize="19px">
                        {t(item.code)}
                      </Text.Heading3>
                    </div>
                  </div>

                  {item.info.map((subitem) => (
                    <>
                      <div className="precatorio-item-content">
                        <div className="item-right-container">
                          <img src={subitem.icon} />

                          <Text.Heading1Regular fontSize="17px">
                            {t(subitem.item)}
                          </Text.Heading1Regular>
                        </div>

                        <Text.Heading4Bold fontSize="17px">
                          {t(subitem.description)}
                        </Text.Heading4Bold>
                      </div>

                      <Text.Smaller marginTop={5}>
                        {t(subitem.extraText)}
                      </Text.Smaller>
                    </>
                  ))}

                  <div className="precatorio-item-footer">
                    <GenericButton buttonType="inline" width="100%">
                      <Text.Heading2Bold fontSize="15px">
                        {t("precatorio.precatorios.firstButton")}
                      </Text.Heading2Bold>
                    </GenericButton>

                    <GenericButton
                      buttonType="secondary"
                      width="100%"
                      marginLeft={19}
                    >
                      <Text.Heading2Bold fontSize="15px" color={colors.white}>
                        {t("precatorio.precatorios.secondButton")}
                      </Text.Heading2Bold>
                    </GenericButton>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Style.PrecatorioSectionContainer>
  );
}

export default PrecatorioSection;
