import React from "react";
import * as Style from './DownloadApp.styles';
import * as Text from '~/styles/text';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { colors } from "~/styles";
import useWindowDimensions from "~/hooks/windowDimension";
import useDetectDevice from "~/hooks/detectDevice";

import GenericButton from "~/components/GenericButton";

import AppleStoreText from "~/images/apple-store-text.svg";
import GooglePlayText from "~/images/google-play-text.svg";
import PlayStoreIcon from "~/images/play-store-icon.svg";
import AppStoreIcon from "~/images/app-store-icon.svg";

function DownloadApp() {
  const { t, i18n } = useTranslation();

  return (
    <Style.DownloadAppContainer>
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} md={12} lg={5} className="center-container">
            <Text.Heading1Bold
              fontSize="44px"
              marginBottom={20}
              color={colors.white}
            >
              {t("precatorio.downloadApp.title")}
            </Text.Heading1Bold>

            <Text.Heading1Regular fontSize="21px" color={colors.white}>
              {t("precatorio.downloadApp.description")}
            </Text.Heading1Regular>

            <div className="downloadapp-footer">
              <GenericButton
                buttonType="third"
                width="180px"
                paddingVertical={30}
                marginRight={27}
              >
                <div className="downloadapp-item">
                  <img src={PlayStoreIcon} alt="" />

                  <div className="downloadapp-item-right">
                    <Text.Heading1Regular fontSize="10px">
                      {t("precatorio.downloadApp.playStore")}
                    </Text.Heading1Regular>
                    <img src={GooglePlayText} alt="" />
                  </div>
                </div>
              </GenericButton>

              <GenericButton
                width="180px"
                buttonType="third"
                paddingVertical={30}
              >
                <div className="downloadapp-item">
                  <img src={AppStoreIcon} alt="" />

                  <div className="downloadapp-item-right">
                    <Text.Heading1Regular fontSize="10px">
                      {t("precatorio.downloadApp.appStore")}
                    </Text.Heading1Regular>
                    <img src={AppleStoreText} alt="" />
                  </div>
                </div>
              </GenericButton>
            </div>
          </Col>
        </Row>
      </Container>
    </Style.DownloadAppContainer>
  );
}

export default DownloadApp;
