import styled from 'styled-components';
import { colors } from '~/styles';

export const Container = styled.div`

  .content {
    padding: 60px 0;

    background-color: ${colors.white};

    .text-regular {
      font-family: "Helvetica Now Display Regular", sans-serif;
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 20px;

      a {
        font-family: "Helvetica Now Display Bold", sans-serif;
        color: #026FF4;
        font-size: 18px;
        line-height: 160%;
      }
    }

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 570px;
      height: 76px;
      padding: 15px 24px;
      border-radius: 10px;
      border: 2px solid ${colors.black};
      cursor: pointer;

      margin-top: 30px;

      &:hover {
        background-color: ${colors.gray};
      }

      a {
        transition: all ease-out 0.3s;
        
        &:hover {
          margin-top: 5px;
          transition: all ease-out 0.3s;

          img {
            background-color: ${colors.gray};
            transition: all ease-out 0.3s;
          }
        }

        img {
          padding: 10px;
          border-radius: 10px;
          background-color: ${colors.white};
          transition: all ease-out 0.3s;
        }
      }
    }

    @media (max-width: 600px) {
      .button-content {
        width: 100%;
        padding: 48px 24px;
      }
    }
  }
`;
export const Header = styled.div`
  background-color: #026FF4;

  .banner {
    margin-top: 80px;

    padding: 96px 0;
  }
`;