export const randomColors = [
  "#FE7275",
  "#41B2FF",
  "#9AA9FE",
  "#FE829C",
  "#3983FE",
  "#6377FC",
  "#FBC0A0",
  "#55BCF2",
  "#5D46B0",
  "#E6BDE7",
  "#627BB4",
  "#9DE6F7",
];

export default {
  transparent: "transparent",
  white: "#ffffff",
  whiteLight: "#f8f9fb",
  gray: "#f3f4f9",
  grayLight: "#c3c6db",
  grayRegular: "#9ea1b9",
  grayMedium: "#757893",
  black: "#272937",
  mainGray: "#f3f4f9",
  primary: "#0f90ff",
  newBlue: "#026FF4",
  warning: "#f3bA2f",
  danger: "#ff6378",
  success: "#00e699",
  blueViolet: "#ECDDFF",
};
