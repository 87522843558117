import React from 'react';
import * as Style from "./GenericButton.styles";

const GenericButton = (props) => {
  return (
    <Style.Container
      className={props.buttonType || 'primary'}
      {...props}
      onClick={props.buttonLoading ? () => { } : props.onClick}
    >
      <div className="move-top">{props.children}</div>
    </Style.Container>
  );
};

export default GenericButton;
