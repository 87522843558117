import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import excerpt from "excerpts";
import { Parallax, useController } from "react-scroll-parallax";
import { Reveal, Fade } from "react-reveal";
import Slider from "react-slick";
import CookieConsent from "react-cookie-consent";
import * as Text from "~/styles/text";

import { Container, Row, Col } from "react-bootstrap";

import asSeenOn from "./asSeenOn";

import useDetectDevice from "./hooks/detectDevice";
import MobileMenu from "./components/MobileMenu";
import useWindowDimensions from "~/hooks/windowDimension";

import Banner from "./images/banner.jpg";
import Wallets from "./images/wallets.png";
import Branches from "./images/branches.png";
import Smartphone from "./images/smartphone.png";
import backgroundSmartphone from "./images/background-smartphone.png";
import PlayStore from "./images/playstore.png";
import AppStore from "./images/appstore.png";
import PlayStoreDark from "./images/playstore-dark.png";
import AppStoreDark from "./images/appstore-dark.png";

import CriptoIcon from "./images/home/cripto.svg";
import FiatIcon from "./images/home/fiat.svg";
import MetalIcon from "./images/home/metal.svg";
import DocumentIcon from "./images/home/document.svg";
import QrCode from "~/images/onlink_to_capitual.svg";
import Close from "~/images/close.png";

import RSSParser from "rss-parser";

import "./style.css";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { colors } from "./styles";

function App(props) {
  const { t, i18n } = useTranslation();

  const [blog, setBlog] = useState([]);
  const [theme, setTheme] = useState("light");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalFade, setModalFade] = useState(false);
  const [showDropdown, setShowDropdown] = useState("");
  const [menuModal, setMenuModal] = useState(false);

  const { width } = useWindowDimensions();

  /*
  const [theme, setTheme] = useState(window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light");
  */

  const productRef = useRef(null);
  const cappayRef = useRef(null);
  const blogRef = useRef(null);
  const walletsRef = useRef(null);
  const bankingRef = useRef(null);

  const { parallaxController } = useController();

  const { isMobile, isDesktop } = useDetectDevice();

  const openModal = () => {
    setModalIsVisible(true);
  };

  const closeModal = () => {
    if (modalIsVisible === true) {
      setModalFade(false);
    }

    setTimeout(() => setModalIsVisible(false), 350);
  };

  useEffect(() => {
    if (modalIsVisible === true) {
      setModalFade(true);
    }
  }, [modalIsVisible]);

  const detectTheme = () => {
    setTheme(
      window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
  };

  const settingsCarousel = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getBlog = () => {
    let parser = new RSSParser();
    parser
      .parseURL(
        "https://cors.capitual.net/?https://blog.capitual.com/" +
          String(i18n.isLanguageChangingTo || i18n.language).substr(0, 2) +
          "/rss/"
      )
      .then((feed) => {
        feed.items.length = Math.min(3, feed.items.length);
        feed.items = feed.items.map((item) => {
          let tmp = document.implementation.createHTMLDocument();
          tmp.body.innerHTML = item["content:encoded"];
          item.picture = tmp.querySelector("img").getAttribute("src");
          return item;
        });
        setBlog(feed.items);
      })
      .catch(console.error);
  };

  useEffect(() => {
    setTimeout(() => parallaxController.update(), 1000);
  }, [blog]);

  const pageScrollTo = (elem) => {
    // find offsetTop to page
    let distance = 0;
    elem = elem.current;
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
    window.scrollTo(0, distance);
  };

  useEffect(() => {
    // fetch latest blog posts
    getBlog();

    // detect dark theme change
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener
    )
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", detectTheme);

    return () => {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener
      )
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .removeEventListener("change", detectTheme);
    };
  }, []);

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <>
      <div className={`re ${theme}`}>
        <header className="ng">
          <Menu />
        </header>
        <main className="ny">
          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="capCookieConsent"
            expires={150}
            style={{
              maxWidth: "80vw",
              backdropFilter: "blur(5px)",
              background: "rgba(0, 0, 0, 0.3)",
            }}
            buttonStyle={{
              borderRadius: "3px",
              background: "#0F90FF",
              color: "white",
            }}
          >
            {t("home.cookie")}
          </CookieConsent>
          <section
            className="rg nb sm tillustration-section-n"
            style={{
              backgroundImage: `url(${Banner})`,
              backgroundColor: "#000000",
              backgroundSize: "cover",
              backgroundPosition: isMobile()
                ? "calc(100% - 25%) center"
                : "right 20%",
              height: "100vh",
            }}
          >
            <div
              className="tcontainern shadow-mobile"
              style={{
                height: "calc(100vh - 80px)",
                display: "flex",
              }}
            >
              <div className="rd align-center banner-texts">
                <div className="rv banner-content">
                  <Fade bottom>
                    <h1
                      className="sw mb-40 home-title p-10 "
                      data-reveal-delay={200}
                      style={{
                        color: "#ffffff",
                        width: "100%",
                        fontSize: isMobile() ? "52px" : "62px",
                      }}
                    >
                      {t("home.title")}
                    </h1>
                  </Fade>

                  {isDesktop() && (
                    <Fade bottom>
                      <p
                        className="sw text-shadow"
                        data-reveal-delay={400}
                        style={{
                          color: "#ffffff",
                          marginBottom: 10,
                          fontSize: 24,
                          paddingRight: 130,
                        }}
                      >
                        {t("home.headline")}
                      </p>
                    </Fade>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="rz nb">
            <div className="tcontainern">
              <div className="testimonial-inner rh">
                <div className="rp">
                  <figure className="parallax-outer tcenter product-title">
                    <h2 className={`sb ${theme}`}>{t("products.title")}</h2>
                  </figure>
                </div>
                {width <= 650 ? (
                  <Slider {...settingsCarousel}>
                    <div>
                      <div className="nv mobile" data-reveal-delay={200}>
                        <div className={`nm ${theme}`}>
                          <div className="testimonial-item-header">
                            <div className="iv">
                              <img
                                src={CriptoIcon}
                                alt={t("products.criptocurrencies.title")}
                                width={30}
                                height={31}
                              />
                            </div>
                          </div>
                          <div className="ie">
                            <h4 className={`sw os ${theme}`}>
                              {t("products.criptocurrencies.title")}
                            </h4>
                            <p className={`sb c ${theme}`}>
                              {t("products.criptocurrencies.text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="nv mobile" data-reveal-delay={200}>
                        <div className={`nm ${theme}`}>
                          <div className="testimonial-item-header">
                            <div className="iv">
                              <img
                                src={FiatIcon}
                                alt={t("products.fiat.title")}
                                width={24}
                                height={34}
                              />
                            </div>
                          </div>
                          <div className="ie">
                            <h4 className={`sw os ${theme}`}>
                              {t("products.fiat.title")}
                            </h4>
                            <p className={`sb c ${theme}`}>
                              {t("products.fiat.text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="nv mobile" data-reveal-delay={400}>
                        <div className={`nm ${theme}`}>
                          <div className="testimonial-item-header">
                            <div className="iv">
                              <img
                                src={MetalIcon}
                                alt={t("products.metals.title")}
                                width={30}
                                height={30}
                              />
                            </div>
                          </div>
                          <div className="ie">
                            <h4 className={`sw os ${theme}`}>
                              {t("products.metals.title")}
                            </h4>
                            <p className={`sb c ${theme}`}>
                              {t("products.metals.text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <div className="nv mobile" data-reveal-delay={600}>
                        <div className={`nm ${theme}`}>
                          <div className="testimonial-item-header">
                            <div className="iv">
                              <img
                                src={DocumentIcon}
                                alt={t("products.tokens.title")}
                                width={28}
                                height={38}
                              />
                            </div>
                          </div>
                          <div className="ie">
                            <h4 className={`sw os ${theme}`}>
                              {t("products.tokens.title")}
                            </h4>
                            <p className={`sb c ${theme}`}>
                              {t("products.tokens.text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Slider>
                ) : (
                  <Parallax y={[30, -30]} tagOuter="figure">
                    <div className="tiles-wrap">
                      <Fade bottom>
                        <div
                          className="nv product-item"
                          data-reveal-delay={200}
                        >
                          <div className="card-item">
                            <div className="ie">
                              <img
                                src={CriptoIcon}
                                alt={t("products.criptocurrencies.title")}
                              />

                              <h4 className="sw os card-title mt-20">
                                {t("products.criptocurrencies.title")}
                              </h4>
                              <p className="sb c card-subtitle mb-20">
                                {t("products.criptocurrencies.text")}
                              </p>
                              {/* <a className="product-link">
                                {t("products.button")}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </Fade>
                      <Fade bottom delay={200}>
                        <div
                          className="nv product-item"
                          data-reveal-delay={200}
                        >
                          <div className="card-item">
                            <img
                              src={FiatIcon}
                              alt={t("products.fiat.title")}
                            />

                            <div className="ie mt-20">
                              <h4 className="sw os card-title">
                                {t("products.fiat.title")}
                              </h4>
                              <p className="sb c card-subtitle mb-20">
                                {t("products.fiat.text")}
                              </p>
                              {/* <a className="product-link">
                                {t("products.button")}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </Fade>
                      <Fade bottom delay={400}>
                        <div
                          className="nv product-item"
                          data-reveal-delay={400}
                        >
                          <div className="card-item">
                            <img
                              src={MetalIcon}
                              alt={t("products.metals.title")}
                            />

                            <div className="ie mt-20">
                              <h4 className="sw os card-title">
                                {t("products.metals.title")}
                              </h4>
                              <p className="sb c card-subtitle mb-20">
                                {t("products.metals.text")}
                              </p>
                              {/* <a className="product-link">
                                {t("products.button")}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </Fade>
                      {/* <Fade bottom delay={600}>
                        <div className="nv product-item" data-reveal-delay={600}>
                          <div className="card-item">
                            <img
                              src={DocumentIcon}
                              alt={t("products.tokens.title")}
                            />

                            <div className="ie mt-20">
                              <h4 className="sw os  card-title">
                                {t("products.tokens.title")}
                              </h4>
                              <p className="sb c card-subtitle mb-20">
                                {t("products.tokens.text")}
                              </p>
                              <a className="product-link">
                                {t("products.button")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Fade> */}
                    </div>
                  </Parallax>
                )}
              </div>
            </div>
          </section>
          <section className="nb sm" ref={productRef}>
            <div className="tcontainern">
              <div className={`rh ig ${theme}`}>
                <div className="rp">
                  <div
                    className="subtitleContainer"
                    style={{ textAlign: "center" }}
                  >
                    <span className={`subtitle ${theme}`}>{t("app.name")}</span>
                  </div>
                  <h2 className={`sb ${theme}`} style={{ textAlign: "center" }}>
                    {t("app.title")}
                  </h2>
                  <div
                    style={{
                      marginTop: 40,
                      backgroundImage: `url(${backgroundSmartphone})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                    }}
                  >
                    <Parallax y={[30, -30]} tagOuter="figure">
                      <img
                        src={Smartphone}
                        className="smartphone"
                        style={{ width: isMobile() ? "100%" : "35%" }}
                        alt={t("app.title")}
                      />
                    </Parallax>
                  </div>
                </div>
                <div className="vbuttonm">
                  <a
                    className="tbuttonn cbuttonh gbuttony"
                    style={
                      theme === "light"
                        ? { backgroundColor: "#061238" }
                        : { backgroundColor: "#ffffff" }
                    }
                    href="https://apps.apple.com/app/id1458793819"
                  >
                    <img
                      src={theme === "light" ? AppStore : AppStoreDark}
                      width={24}
                      height={30}
                      alt={t("app.appStore")}
                    />
                  </a>
                  <a
                    className="tbuttonn cbuttonh gbuttony"
                    style={
                      theme === "light"
                        ? { backgroundColor: "#061238" }
                        : { backgroundColor: "#ffffff" }
                    }
                    href="https://play.google.com/store/apps/details?id=com.capitual"
                  >
                    <img
                      src={theme === "light" ? PlayStore : PlayStoreDark}
                      width={27}
                      height={30}
                      alt={t("app.googlePlay")}
                    />
                  </a>
                </div>
                <p className={`${theme} apt`}>{t("app.text")}</p>
              </div>
            </div>
          </section>

          <section className="ry nb">
            <div className={`features-split-inner rh ig ${theme}`}>
              <div className="nu nc">
                <div className="tcontainern" id="CapWallets" ref={walletsRef}>
                  <div className="split-item">
                    <div
                      className="na nf"
                      data-reveal-container=".split-item"
                      style={{ marginRight: 64 }}
                    >
                      <Parallax y={[30, -30]} tagOuter="figure">
                        <img
                          src={Wallets}
                          alt={t("wallets.title")}
                          style={{ minWidth: isMobile() ? 278 : 470 }}
                        />
                      </Parallax>
                    </div>
                    <div className="nl ab" data-reveal-container=".split-item">
                      <div className="subtitleContainer">
                        <span className={`subtitle ${theme}`}>
                          {t("wallets.title")}
                        </span>
                      </div>
                      <h3
                        className={`sw ol ${theme}`}
                        style={{ marginBottom: 20 }}
                      >
                        {t("wallets.subtitle")}
                      </h3>
                      <p className={`sb ${theme}`}>{t("wallets.text")}</p>
                    </div>
                  </div>
                </div>
                <div className="tcontainern" id="CapConta" ref={bankingRef}>
                  <div className="split-item mb-50">
                    <div className="nl ab" data-reveal-container=".split-item">
                      <div className="subtitleContainer">
                        <span className={`subtitle ${theme}`}>
                          {t("banking.title")}
                        </span>
                      </div>
                      <h3
                        className={`sw ol ${theme}`}
                        style={{ marginBottom: 20 }}
                      >
                        {t("banking.subtitle")}
                      </h3>
                      <p className={`sb ${theme}`}>{t("banking.text")}</p>
                    </div>
                    <div className="na nf" data-reveal-container=".split-item">
                      <Parallax y={[30, -30]} tagOuter="figure">
                        <img src={Branches} alt={t("banking.title")} />
                      </Parallax>
                    </div>
                  </div>
                </div>
                <div className="bbg">
                  <div className="split-item tcontainern" ref={cappayRef}>
                    <div
                      className="na nf"
                      data-reveal-container=".split-item"
                      style={{ marginRight: 64 }}
                    ></div>
                    <div className="nl ab" data-reveal-container=".split-item">
                      <div className="subtitleContainer">
                        <span className={`subtitle ${theme} text-shadow`}>
                          {t("cappay.title")}
                        </span>
                      </div>
                      <h3
                        className={`sw ol ${theme} text-shadow`}
                        style={{ marginBottom: 20 }}
                      >
                        {t("cappay.subtitle")}
                      </h3>
                      <p className={`sb ${theme} text-shadow`}>
                        {t("cappay.text")}
                      </p>

                      <a
                        className="tbuttonn gbuttony readyButton"
                        style={{ width: isMobile() ? "100%" : "43%" }}
                        href="https://cappay.capitual.com"
                        target="_blank"
                      >
                        {t("cappay.link")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="rb nb" ref={blogRef}>
            <div className="tcontainern">
              <div className="features-tiles-inner rh">
                <div className="tiles-wrap">
                  <div className="subtitleContainer">
                    <span
                      className={`subtitle ${theme}`}
                      style={{ marginLeft: 32 }}
                    >
                      {t("blog.name")}
                    </span>
                  </div>
                  <div className="subtitleContainer">
                    <h2
                      className={`sb title-blog ${theme}`}
                      style={{ marginLeft: 32 }}
                    >
                      {t("blog.title")}
                    </h2>
                  </div>

                  {isMobile() ? (
                    <Slider {...settingsCarousel} style={{ width: "90%" }}>
                      {blog.map((item, index) => (
                        <div>
                          <div className="nv blp">
                            <a href={item.link} target="_blank">
                              <div className="nm">
                                <div className="features-tiles-item-header">
                                  <div className="ic ov">
                                    <img
                                      src={item.picture}
                                      style={{ width: "80vw" }}
                                      alt={item.title}
                                      onLoad={() =>
                                        window.dispatchEvent(
                                          new Event("resize")
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="features-tiles-item-content">
                                  <h5 className={`sw os ${theme} ln-25`}>
                                    {item.title}
                                  </h5>
                                  <p
                                    className={`sb c ${theme}`}
                                    style={{ fontSize: 15 }}
                                  >
                                    {excerpt(item.contentSnippet, {
                                      words: 15,
                                      append: "...",
                                    })}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    blog.map((item, index) => (
                      <Fade bottom delay={index * 200}>
                        <div className="nv blp">
                          <a href={item.link} target="_blank">
                            <div className="nm">
                              <div className="features-tiles-item-header">
                                <div className="ic ov">
                                  <img src={item.picture} alt={item.title} />
                                </div>
                              </div>
                              <div className="features-tiles-item-content">
                                <h5 className={`sw os ${theme} ln-25`}>
                                  {item.title}
                                </h5>
                                <p
                                  className={`sb c ${theme}`}
                                  style={{ fontSize: 15 }}
                                >
                                  {excerpt(item.contentSnippet, {
                                    words: 15,
                                    append: "...",
                                  })}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Fade>
                    ))
                  )}

                  {!blog.length &&
                    (isMobile()
                      ? [1]
                      : [1, 2, 3].map((item) => (
                          <div className="nv blp">
                            <a href="#" target="_blank">
                              <div className="nm">
                                <div className="features-tiles-item-header">
                                  <div className="ic ov">
                                    <img />
                                  </div>
                                </div>
                                <div className="features-tiles-item-content">
                                  <h6 className={`sw os ${theme}`}>&nbsp;</h6>
                                  <p className={`sb c ${theme}`}>
                                    <br />
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        )))}

                  <a
                    href="https://blog.capitual.com/"
                    style={
                      isMobile()
                        ? { paddingTop: 60, marginBottom: 30 }
                        : { marginBottom: 100 }
                    }
                  >
                    {t("blog.link")} &nbsp; ➞
                  </a>
                </div>
              </div>
            </div>
          </section> */}
          <section className="io nb mt-50">
            <div className="tcontainern">
              <center>
                <h6 className={`sw os ${theme}`} style={{ fontSize: 20 }}>
                  {t("press.title")}
                </h6>
              </center>
              <div
                className={`iu rh ig sv ${theme}`}
                style={{ textAlign: "center" }}
              >
                {isMobile() ? (
                  <Slider {...settingsCarousel}>
                    {asSeenOn.map((news) => (
                      <div>
                        <a
                          rel="nofollow"
                          href={news.link}
                          target="_blank"
                          tilte={news.title}
                        >
                          <img
                            className="ndc"
                            src={news.picture[theme]}
                            alt={news.title}
                          />
                        </a>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <ul className="ix" style={{ marginBottom: 40 }}>
                    {asSeenOn.map((news) => (
                      <Fade delay={600}>
                        <li className="aso">
                          <a
                            rel="nofollow"
                            href={news.link}
                            target="_blank"
                            tilte={news.title}
                          >
                            <img
                              className="ndc"
                              src={news.picture[theme]}
                              alt={news.title}
                            />
                          </a>
                        </li>
                      </Fade>
                    ))}
                  </ul>
                )}
                <a href="https://blog.capitual.com/imprensa/" target="_blank">
                  {t("press.link")} &nbsp; ➞
                </a>
              </div>
            </div>
          </section>
          <section className="ir nb ab " style={{ paddingTop: 50 }}>
            <div className="tcontainern">
              <div
                className="testimonial-inner rh"
                style={{ paddingBottom: 0 }}
              >
                <div className="rp">
                  <div
                    className="scontainero"
                    style={{
                      textAlign: "center",
                      width: isMobile() ? "100%" : "40%",
                    }}
                  >
                    <h2 className={`sb ${theme}`}>{t("getStarted.title")}</h2>
                    <p
                      className={`sw ${theme}`}
                      style={{ marginTop: 20 }}
                      data-reveal-delay={400}
                    >
                      {t("getStarted.text")}
                    </p>
                  </div>
                  <div className="cta-action">
                    <a
                      className="black-button readyButton"
                      onClick={() =>
                        isMobile()
                  ? redirectTo("https://onelink.to/capitual")
                  : openModal()
                      }
                    >
                      {isMobile()
                        ? t("getStarted.downloadApp")
                        : t("getStarted.register")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer backgroundColor={colors.whiteLight} />
      </div>

      <Fade when={modalIsVisible} duration={350}>
        <div
          className={`modal ${modalIsVisible && `visible`}`}
          style={{ zIndex: 999999999999 }}
        >
          <Fade right when={modalFade} duration={400}>
            <div className="modal-content2">
              <div className="scontainero" style={{ height: "80%" }}>
                <div className="closeButton" onClick={() => closeModal()}>
                  <img
                    src={Close}
                    alt="fechar"
                    style={{ alignSelf: "flex-end" }}
                    width="16"
                  />
                </div>

                <div className="has-container-centered">
                  <Text.Heading1Bold
                    width="70%"
                    fontSize="32px"
                    textAlign="center"
                    marginBottom={30}
                    data-reveal-delay={400}
                  >
                    {t("modal.title")}
                  </Text.Heading1Bold>

                  <Text.Small
                    width="85%"
                    fontSize="16px"
                    textAlign="center"
                    marginBottom={40}
                    data-reveal-delay={400}
                  >
                    {t("modal.description")}
                  </Text.Small>

                  <img src={QrCode} alt="QrCode" width="200" />

                  <Text.Small
                    width="85%"
                    fontSize="16px"
                    color={colors.grayMedium}
                    textAlign="center"
                    marginTop={30}
                    data-reveal-delay={400}
                  >
                    {t("modal.tip")}
                  </Text.Small>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Fade>

      {menuModal && <MobileMenu CloseButton={setMenuModal} />}
    </>
  );
}

export default App;
