import styled from 'styled-components';
import { colors } from '~/styles';

import BannerImage from '~/images/banner.jpg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const BannerSection = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100vh;
  background: url(${BannerImage});
  background-size: cover;
  background-position: right 20%;
`;

export const CardContainer = styled.div`
  padding: 100px 0 0 0;

  .card-item {
    height: 345px;
    padding: 30px;
    border: 2px solid ${colors.mainGray};
    border-radius: 15px;
    margin-bottom: 30px;

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-right: 8px;
      }
    }
  }
`;

export const CapContaContainer = styled.div`
  padding: 100px 0;
`;

export const CapWalletsContainer = styled.div`
  padding: 100px 0 163px 0;
`;

export const CapBusinessContainer = styled.div`
  
  position: relative;
  
  padding: 100px 0;
  height: 645px;
  background-color: #026FF4;

  .business-image {
    position: absolute;
    left: 0;
    top: 0;

    height: 645px;
  }
`;

export const CapPayContainer = styled.div`
  padding: 134px 0 100px 0;
`;

export const CapSettleContainer = styled.div`
  padding: 100px 0 120px 0;
`;

export const TotalMoney = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 100px 0;
  background-color: #026FF4;
`;