import styled from "styled-components";
import colors from "./colors";

const baseHeading = styled.p`
  display: ${(props) => props.display || "flex"};
  align-self: ${(props) => props.alignSelf || "auto"};
  justify-self: ${(props) => props.justifySelf || "auto"};
  text-align: ${(props) => props.textAlign || "auto"};
  width: ${(props) => props.width || "auto"};
  margin: ${(props) => props.marginTop || 0}px
    ${(props) => props.marginRight || 0}px
    ${(props) => props.marginBottom || 0}px
    ${(props) => props.marginLeft || 0}px;
  color: ${(props) => props.color || colors.black};
  letter-spacing: ${(props) => props.letterSpacing || 2}%;
  -webkit-font-smoothing: antialiased !important;
`;

export const Heading1Regular = styled(baseHeading)`
  font-family: "Helvetica Now Display Regular", sans-serif;
  font-size: ${(props) => props.fontSize || "1.813rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading1Medium = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "1.813rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading1Bold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "1.813rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Subtitle1Medium = styled(baseHeading)`
  font-family: "Aeonik Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "17px"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Heading2 = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "1.37rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const H2Medium = styled(baseHeading)`
  font-family: "Aeonik Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "29px"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading2Bold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "1.37rem"};

  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading3 = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "1.18rem"};
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const H3Medium = styled(baseHeading)`
  font-family: "Aeonik Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "22px"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading3Bold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "1.18rem"};
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const Heading4 = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "1.063rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Heading4Bold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "1.063rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Heading5 = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "0.93rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const H5Regular = styled(baseHeading)`
  font-family: "Aeonik Regular", sans-serif;
  font-size: ${(props) => props.fontSize || "17px"};
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const H5Medium = styled(baseHeading)`
  font-family: "Aeonik Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "19px"};
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const Heading5Bold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "0.93rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Paragraph = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "0.875rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const ParagraphBold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "0.875rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Small = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "0.81rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const SmallBold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "0.81rem"};
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Smaller = styled(baseHeading)`
  font-family: "Helvetica Now Display Medium", sans-serif;
  font-size: ${(props) => props.fontSize || "0.75rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const SmallerBold = styled(baseHeading)`
  font-family: "Helvetica Now Display Bold", sans-serif;
  font-size: ${(props) => props.fontSize || "0.75rem"};
  line-height: ${(props) => props.lineHeight || 135}%;
`;
