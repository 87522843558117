import React from "react";
import * as Style from './WhatFor.styles';
import * as Text from '~/styles/text';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { colors } from "~/styles";

import GenericButton from "~/components/GenericButton";

import ArrowRightIcon from "~/images/portfolio/precatorio/arrow-right-white.svg";
import WhatForImage from "~/images/portfolio/precatorio/what-for.png";

function WhatFor() {
  const { t, i18n } = useTranslation();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <Style.WhatForContainer>
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} md={12} lg={6}>
            <img className="what-for-image" src={WhatForImage} />
          </Col>
          <Col xs={12} md={12} lg={6} className="center-container">
            <Text.Heading1Bold fontSize="40px" color={colors.white}>
              {t("precatorio.whatFor.title")}
            </Text.Heading1Bold>

            <Text.Heading3
              fontSize="21px"
              color={colors.white}
              marginTop={17}
              marginBottom={30}
            >
              {t("precatorio.whatFor.firstDescription")}
            </Text.Heading3>

            <Text.Heading3
              fontSize="21px"
              color={colors.white}
              marginBottom={37}
            >
              {t("precatorio.whatFor.secondDescription")}
            </Text.Heading3>

            <GenericButton
              buttonType="inline"
              noInlineBorder
              paddingHorizontal={1}
              onClick={() => redirectTo("#Faq")}
            >
              <div className="button-content">
                <Text.Heading5Bold color={colors.white}>
                  {t("precatorio.whatFor.button")}
                </Text.Heading5Bold>

                <img src={ArrowRightIcon} alt="" />
              </div>
            </GenericButton>
          </Col>
        </Row>
      </Container>
    </Style.WhatForContainer>
  );
}

export default WhatFor;
