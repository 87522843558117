const PRODUCTION_URI = 'https://capitual.com';
const DEVELOPMENT_URI = 'http://localhost:3000';

const PRODUCTION_STRAPI = 'https://lp.capitual.net';
const DEVELOPMENT_STRAPI = 'http://localhost:1337';

const URI = {
  production: [PRODUCTION_URI, PRODUCTION_STRAPI],
  development: [DEVELOPMENT_URI, DEVELOPMENT_STRAPI]
};

const ENV = process.env.NODE_ENV || 'development';

const ENDPOINT = URI[ENV];

export default {
  ENV,
  ENDPOINT,
};