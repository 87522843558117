import styled from "styled-components";
import { colors } from "~/styles";

export const Container = styled.div`
  .accordion-panel-anim {
    overflow: hidden;
    display: block !important;
  }

  .sub-menu-content a {
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;

      p {
        color: ${colors.grayLight};
      }
    }
  }
`;
