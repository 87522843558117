import styled from 'styled-components';

export const WhatForContainer = styled.div`
  background: #026ff4;
  height: 600px;

  .what-for-image {
    position: absolute;
    margin-top: 100px;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      p {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 1199px) {
    .what-for-image {
      display: block;
      position: relative;
      width: 80%;
      margin: 50px auto;
    }
  }

  @media (max-width: 991px) {
    height: auto;
    padding-left: 25px;
    padding-right: 25px;

    .what-for-image {
      display: block;
      position: relative;
      width: 80%;
      margin: 50px auto;
    }

    .center-container {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 500px) {
    height: auto;
    padding-left: 25px;
    padding-right: 25px;

    .what-for-image {
      position: relative;
      width: 100%;
      margin: 50px 0;
    }

    .center-container {
      margin-bottom: 50px;
    }
  }
`;