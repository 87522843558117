import styled from 'styled-components';
import { colors } from '~/styles';
import { transparentize } from "polished";

export const PrecatorioSectionContainer = styled.div`
  background: ${colors.white};
  padding: 200px 0;

  .how-to-container {
    .how-to-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      margin-bottom: 20px;

      .item-number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 28px;
        height: 28px;
        background: ${transparentize(0.85, colors.primary)};
        margin-right: 20px;
        padding: 8px 11px;
        border-radius: 50px;

        p {
          color: ${colors.primary};
        }
      }
    }

    .precatorio-video {
      margin-top: -100px;
      width: 100%;
      
      ::-webkit-media-controls-enclosure {
        border: 2px solid white;
      }
    }

    @media (max-width: 991px) {
      .precatorio-video {
        margin-top: 50px;
        width: 90%;

        ::-webkit-media-controls-enclosure {
          border: 2px solid white;
        }
      }
    }

    @media (max-width: 700px) {
      padding-left: 25px;
      padding-right: 25px;

      .precatorio-video {
        margin-top: 50px;
        width: 100%;

        ::-webkit-media-controls-enclosure {
          border: 2px solid white;
        }
      }
    }
  }

  .how-to-banner {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    height: 332px;
    margin-top: 200px;
    border-radius: 20px;

    background: ${colors.black};

    .how-to-banner-image {
      position: absolute;
      bottom: 0;
    }
  }

  @media (max-width: 1199px) {
    .how-to-banner {
      height: 400px;
    }
  }

  @media (max-width: 991px) {
    .how-to-banner {
      flex-direction: column;

      height: auto;
      margin-top: 100px;
      padding: 60px 19px 0 30px;

      .how-to-banner-image {
        position: relative;
        width: 70%;
        margin: 50px auto 0 auto;
      }
    }
  }

  @media (max-width: 500px) {
    .how-to-banner {
      flex-direction: column;

      height: auto;
      margin-top: 100px;
      padding-top: 60px;
      border-radius: 0;

      .how-to-banner-image {
        position: relative;
        width: 90%;
        margin: 50px auto 0 auto;
      }
    }
  }

  .precatorio-content {
    padding-top: 170px;

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
    }

    .precatorio-item {
      padding: 40px;
      border: 2px solid ${colors.gray};
      border-radius: 15px;
      margin-top: 62px;

      .precatorio-item-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 20px;

        .preca-container {
          display: flex;
          align-items: center;
          justify-content: center;

          position: relative;
          width: 55px;
          height: 55px;
          background: #3983fe;
          border-radius: 40px;
          margin-right: 20px;
          z-index: 99;

          &.pink {
            background: #FE829C;

            &::before {
              background: #FE829C;
            }
          }

          img {
            z-index: 99;
          }

          &::before {
            content: " ";
            position: absolute;
            top: 0px;
            left: 0px;

            width: 30px;
            height: 35px;

            border-radius: 5px;
            border-bottom-left-radius: 10px;
            background: #3983fe;
            z-index: 1;
          }
        }
      }

      .precatorio-item-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 10px 0;

        .item-right-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          p {
            margin-left: 10px;
          }
        }
      }

      .precatorio-item-footer {
        display: flex;
        flex-direction: row;

        margin-top: 15px;
      }
    }
  }
`;