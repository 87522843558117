import styled from 'styled-components';
import { colors } from '~/styles';

import { transparentize } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
`;

export const BannerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
  background-color: ${colors.white};

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    
    width: 95%;
    height: 100%;
    padding: 55px 70px;
    background-image: linear-gradient(180deg, rgba(2, 111, 244, 0) 0%, rgba(2, 111, 244, 0.130208) 30.73%, rgba(2, 111, 244, 0.68) 100%), url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    border-radius: 10px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  padding: 120px 0;
  text-align: center;
  background-color: ${colors.white};

  .description-container-subtitle {
    white-space: pre-wrap;
  }
`;

export const CardContainer = styled.div`
  padding: 100px 0 0 0;
  background-color: ${colors.white};

  .card-item {
    height: 285px;
    width: 369px;
    padding: 30px;
    border: 2px solid ${colors.mainGray};
    border-radius: 15px;
    margin-bottom: 30px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 44px;
      height: 44px;
      background-color: ${colors.newBlue};
      border-radius: 100px;
    }

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-right: 8px;
      }
    }
  }
`;

export const OpportunitiesContainer = styled.div`
  background-color: ${colors.white};
  padding: 120px 0px;
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  .accordion-line {
    border: 1px solid ${transparentize(0.9, colors.black)} !important;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0px 45px;
  }

  .accordion-panel-anim {
    overflow: hidden;
    display: block !important;

    .subitem-accordion-opened {
      padding: 30px 0;
      border-top: 1px solid ${transparentize(0.9, colors.black)};

      .subitem-accordion-opened-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        img {
          margin-left: 7px;
        }
      }

      &:last-child {
        padding-bottom: 50px;
      }

        .buttton {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
    }
  }

  @media (max-width: 991px) {
    .accordion-panel-anim {
      .subitem-accordion-opened {
        .buttton {
          margin-top: 30px;
        }
      }
    }
  }

  .accordion-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: ${colors.transparent};
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    padding: 40px 0;

    position: relative;
    cursor: pointer;
    transition: 0.3s all ease-out;

    &.active {
      padding-bottom: 60px;
      transition: 0.3s all ease-out;
    }

    .accordion-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding-bottom: 10px;
      background-color: ${colors.whiteLight};
      transition: 0.3s all ease-out;
      z-index: 99;
    }

    .accordion-description {
      opacity: 0;
      margin-top: 24px;

      position: absolute;
      top: 10px;
      transition: 0.3s all ease-out;
      z-index: 9;
    }

    .accordion-description.active {
      opacity: 1;
      top: 50px;
      transition: 0.3s all ease-out;
    }
  }

  .accordion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: ${colors.transparent};
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-bottom: 20px;
    padding-top: 40px;

    a {
      font-size: 17px;
      color: #272937;

      &:hover {
        color: #272937;
      }
    }

    .accordion-item-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 13px;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 30px;
        border-radius: 100px;
        margin-left: 13px;
        background-color: ${transparentize(0.9, colors.newBlue)};
      }
    }

    .accordion-arrow {
      width: 32px;
      height: 32px;
      padding: 8px;
      background: ${colors.whiteLight};
      border-radius: 50px;
    }
  }
`;