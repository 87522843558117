import GraphIcon from "~/images/portfolio/precatorio/graph.svg";
import LightningIcon from "~/images/portfolio/precatorio/lightning.svg";
import SmileIcon from "~/images/portfolio/precatorio/smile.svg";
import PrecaIcon from "~/images/portfolio/precatorio/preca.svg";
import SecurityIcon from "~/images/portfolio/precatorio/security.svg";
import CashIcon from "~/images/portfolio/precatorio/cash.svg";
import CalendarIcon from "~/images/portfolio/precatorio/calendar.svg";
import YieldIcon from "~/images/portfolio/precatorio/yield.svg";
import ReceiveMoneyIcon from "~/images/portfolio/precatorio/receive-money.svg";
import DollarIcon from "~/images/portfolio/precatorio/dollar.svg";

export const adavantagesTokens = [
  {
    icon: GraphIcon,
    title: "precatorio.advantages.rentability.title",
    text:
      "precatorio.advantages.rentability.description",
  },
  {
    icon: LightningIcon,
    title: "precatorio.advantages.liquidity.title",
    text: "precatorio.advantages.liquidity.description",
  },
  {
    icon: SmileIcon,
    title: "precatorio.advantages.risk.title",
    text: "precatorio.advantages.risk.description",
  },
  {
    icon: PrecaIcon,
    title: "precatorio.advantages.investiment.title",
    text:
      "precatorio.advantages.investiment.description",
  },
  {
    icon: SecurityIcon,
    title: "precatorio.advantages.security.title",
    text:
      "precatorio.advantages.security.description",
  },
  {
    icon: CashIcon,
    title: "precatorio.advantages.price.title",
    text: "precatorio.advantages.price.description",
  },
];

export const faq = [
  {
    title: "precatorio.faq.whatIs.title",
    description: "precatorio.faq.whatIs.description",
    id: 1,
  },
  {
    title: "precatorio.faq.howItWorks.title",
    description:
      "precatorio.faq.howItWorks.description",
    id: 2,
  },
  {
    title: "precatorio.faq.token.title",
    description:
      "precatorio.faq.token.description",
    id: 3,
  },
  {
    title: "precatorio.faq.profit.title",
    description:
      "precatorio.faq.profit.description",
    id: 4,
  },
  {
    title: "precatorio.faq.sell.title",
    description:
      "precatorio.faq.sell.description",
    id: 5,
  },
  {
    title: "precatorio.faq.trading.title",
    description:
      "precatorio.faq.trading.description",
    id: 6,
  },
  {
    title: "precatorio.faq.fee.title",
    description:
      "precatorio.faq.fee.description",
    id: 7,
  },
];

export const precatorio = [
  {
    title: "precatorio.precatorios.cap1.title",
    code: "precatorio.precatorios.cap1.code",
    color: "",
    info: [
      {
        icon: CalendarIcon,
        item: "precatorio.precatorios.cap1.date.title",
        description: "precatorio.precatorios.cap1.date.description"
      },
      {
        icon: YieldIcon,
        item: "precatorio.precatorios.cap1.value.title",
        description: "precatorio.precatorios.cap1.value.description"
      },
      {
        icon: ReceiveMoneyIcon,
        item: "precatorio.precatorios.cap1.rentability.title",
        description: "precatorio.precatorios.cap1.rentability.description"
      },
      {
        icon: DollarIcon,
        item: "precatorio.precatorios.cap1.period.title",
        description: "precatorio.precatorios.cap1.period.description",
        extraText: "precatorio.precatorios.cap1.period.extraText"
      },
    ]
  },
  {
    title: "precatorio.precatorios.cap2.title",
    code: "precatorio.precatorios.cap2.code",
    color: "pink",
    info: [
      {
        icon: CalendarIcon,
        item: "precatorio.precatorios.cap2.date.title",
        description: "precatorio.precatorios.cap2.date.description",
      },
      {
        icon: YieldIcon,
        item: "precatorio.precatorios.cap2.value.title",
        description: "precatorio.precatorios.cap2.value.description",
      },
      {
        icon: ReceiveMoneyIcon,
        item: "precatorio.precatorios.cap2.rentability.title",
        description: "precatorio.precatorios.cap2.rentability.description",
      },
      {
        icon: DollarIcon,
        item: "precatorio.precatorios.cap2.period.title",
        description: "precatorio.precatorios.cap2.period.description",
        extraText: "precatorio.precatorios.cap2.period.extraText"
      },
    ]
  },
];