import React, { useState, useEffect } from 'react';
import { colors } from '~/styles';
import * as Style from './PoliticaPrivacidade.styles';
import * as Text from '~/styles/text';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation, Trans } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import useDetectDevice from '~/hooks/detectDevice';

import Menu from '~/components/Menu';
import Footer from '~/components/Footer';
import baseUrl from '~/config';
import axios from 'axios';

function PoliticaPrivacidade() {
  const { t, i18n } = useTranslation();

  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);

  const { isMobile, isDesktop } = useDetectDevice();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl.ENDPOINT[1]}/api/capitual-com-legal-privacy-policy?populate=deep`
      )
      .then((res) => {
        setPrivacyPolicyData(res.data.data);
      });
  }, []);

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Política de Privacidade
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col>
              <iframe src="https://storage.googleapis.com/static.capitual.net/site/files/politica-privacidade.pdf#view=FitH" width="100%" height="800px" type="application/pdf"></iframe>

              {/* <Text.Heading1Medium
                fontSize="31px"
                lineHeight={130}
                marginTop={60}
                marginBottom={30}
              >
                Política de Privacidade
              </Text.Heading1Medium>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A presente “"Política de Privacidade” faz parte dos Termos e
                Condições de Uso e descreve como o CAPITUAL INSTITUIÇÃO DE
                PAGAMENTO S.A, inscrita no CNPJ/ME sob o n. 34.942.560/0001-87,
                com sede na Cidade de Barueri, Estado de São Paulo, na Alameda
                Rio Negro, 1477, Edifício Amazônia Empresarial – 5º Andar, Conj.
                503 CEP 06010-170, coleta, armazena, utiliza, processa, associa,
                protege, compartilha e trata seus dados pessoais, em conexão com
                as Aplicações e Serviços regidos pelos Termos e Condições de
                Uso.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
                display="inline-block"
              >
                Se você é um profissional de segurança da informação e deseja
                reportar problemas relacionados à segurança, envie um e-mail
                para{' '}
                <a href="mailto:security@capitual.com">security@capitual.com</a>{' '}
                ou{' '}
                <a href="mailto:bugbounty@capitual.com">
                  bugbounty@capitual.com
                </a>
                .
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Definições
              </Text.Heading2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive borderless>
                {isMobile() ? (
                  <tbody>
                    <tr>
                      <td>
                        Dados Pessoais - são informações relacionadas à pessoa
                        natural, identificada ou identificável. São dados
                        relativos a um indivíduo que, isoladamente ou em
                        conjunto com outras informações, são ou podem ser
                        utilizados para identificá-lo, coletados e/ou tratados
                        de forma física ou eletrônica. Essa definição está de
                        acordo com a LGPD. Dados Pessoais Sensíveis: Dados
                        pessoais sobre origem racial ou étnica, crença
                        religiosa, opinião política, filiação a um sindicato ou
                        organização religiosa, filosófica ou política, relativos
                        à saúde ou à vida sexual, dados genéticos ou
                        biométricos.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Informações - são todas as Informações, sejam elas dados
                        pessoais ou não que Você irá nos fornecer, que obteremos
                        de terceiros durante o seu uso do Serviço ou ainda
                        criadas a partir do uso do Serviço. Tratamento: É
                        qualquer forma de uso que o Capitual possa fazer dos
                        Dados Pessoais, incluindo, mas não se limitando, às
                        seguintes atividades: coleta, armazenamento, consulta,
                        uso, compartilhamento, transmissão, classificação,
                        reprodução, exclusão e avaliação.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Titular - é a pessoa natural a quem se referem os dados
                        pessoais que são objeto de tratamento.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Você - quando falamos “Você” nesta Política de
                        Privacidade, estamos falando de qualquer pessoa que se
                        cadastrou no Serviço ou simplesmente acessou o Serviço.
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td style={{ width: '55%' }}>
                        Dados Pessoais - são informações relacionadas à pessoa
                        natural, identificada ou identificável. São dados
                        relativos a um indivíduo que, isoladamente ou em
                        conjunto com outras informações, são ou podem ser
                        utilizados para identificá-lo, coletados e/ou tratados
                        de forma física ou eletrônica. Essa definição está de
                        acordo com a LGPD.
                      </td>
                      <td>
                        Informações - são todas as Informações, sejam elas dados
                        pessoais ou não que Você irá nos fornecer, que obteremos
                        de terceiros durante o seu uso do Serviço ou ainda
                        criadas a partir do uso do Serviço.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Titular - é a pessoa natural a quem se referem os dados
                        pessoais que são objeto de tratamento.
                      </td>
                      <td>
                        Você - quando falamos “Você” nesta Política de
                        Privacidade, estamos falando de qualquer pessoa que se
                        cadastrou no Serviço ou simplesmente acessou o Serviço
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Objeto
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual a fim de estabelecer uma relação transparente com
                Você, explicará, na presente Política de Privacidade, como os
                seus dados são utilizados por nós.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os Dados Pessoais dos usuários dos serviços e produtos ofertados
                pelo Capitual são protegidos, tratados e mantidos em segurança,
                em conformidade com a Lei Geral de Proteção de Dados (LGPD - Lei
                13.709/18). Procuramos estabelecer as melhores práticas de
                segurança da informação disponíveis no mercado, e observamos a
                legislação brasileira aplicável, em especial a Lei nº
                12.965/2014, conhecida como “Marco Civil da Internet”.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Pretendemos descrever aqui os tipos de Informações que obtemos
                em todos os nossos canais institucionais, à medida que Você
                acessa os conteúdos ou contrata os serviços (“Serviços”). Também
                informamos para qual finalidade as utilizamos, as medidas que
                tomamos para proteger as Informações, e como Você pode entrar em
                contato conosco para acessar, gerenciar e excluir seus dados e,
                caso necessário, esclarecer nossas práticas de privacidade.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Essa Política de Privacidade foi elaborada com o objetivo de ser
                útil, transparente e mais simples possível.
              </Text.Heading1Regular>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Identidade e Dados de Contato do Controlador
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Controlador dos seus dados pessoais é o CAPITUAL INSTITUIÇÃO
                DE PAGAMENTO S.A. Caso haja alguma dúvida após a leitura desta
                Política de Privacidade ou por qualquer motivo você queira se
                comunicar com a Instituição a respeito das questões envolvendo
                seus dados pessoais, você pode entrar em contato por meio do
                e-mail dpo@capitual.com.
              </Text.Heading1Regular>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Da Aceitação da Política
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                As regras estabelecidas pela Política de Privacidade estão
                relacionadas ao uso das aplicações e serviços fornecidos pelo
                Capitual no Brasil, conforme descrito nos Termos e Condições de
                Uso.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ao navegar em nossos sites e aplicativos, bem como fazer uso de
                nossos serviços, Você entende que o Capitual pode coletar,
                armazenar, processar, associar, compartilhar, usar, divulgar ou
                tratar seus dados pessoais, nas formas previstas na legislação e
                na presente política de privacidade.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ao utilizar as aplicações e serviços fornecidos pelo Capitual,
                Você está aceitando integralmente as regras desta Política de
                Privacidade. Assim, caso Você não concorde com as condições de
                tratamento de seus dados nos termos estabelecidos nesta Política
                de Privacidade, Você não poderá usar os serviços ofertados pelo
                Capitual.
              </Text.Heading1Regular>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Aplicabilidade da Política
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Essa política de privacidade é aplicável a:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Clientes: pessoas naturais que efetivamente contratem,
                    utilizem ou acessem um ou mais serviços e produtos
                    oferecidos pelo Capitual (seja para Você ou para sua pessoa
                    jurídica);
                  </p>
                </li>
                <li>
                  <p>
                    Terceiros: pessoas naturais que já tenham solicitado a
                    contratação de um dos serviços ou produtos, mas que por
                    alguma razão não tenha se tornado cliente e pessoas que
                    utilizam a plataforma para realizar operações com parceiros
                    do Capitual;
                  </p>
                </li>
                <li>
                  <p>Candidatos a vagas de emprego.</p>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Direitos dos Titulares
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os titulares dos dados pessoais utilizados pelo Capitual possuem
                os direitos previstos pelo art. 18 da Lei Geral de Proteção de
                Dados (LGPD), a seguir especificados, os quais podem ser
                exercidos por meio do e-mail dpo@capitual.com.
              </Text.Heading1Regular>
              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Confirmar a existência de tratamento e acesso aos dados: o
                titular poderá requisitar ao Capitual a confirmação da
                existência de tratamento de seus dados pessoais pela
                Instituição, e caso a resposta haja, poderá solicitar o acesso
                às informações;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar a correção de dados incompletos, inexatos ou
                desatualizados: o titular poderá solicitar a correção ou
                atualização dos seus dados que considerar incompletos ou
                equivocados.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Caso a atualização não seja possível no próprio aplicativo do
                Capitual, o usuário precisará encaminhar ao time do Capitual um
                documento que comprove a forma correta e atual do dado pessoal
                questionado.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Requisitar a anonimização, bloqueio ou eliminação de dados
                desnecessários, excessivos ou tratados em desconformidade com a
                Lei: o titular poderá requisitar a anonimização, bloqueio ou a
                eliminação de dados que considere excessivo, desnecessários ou
                tratados em desconformidade com a LGPD, desde que seja
                efetivamente comprovado o excesso, a falta de necessidade ou a
                desconformidade com a lei;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar a portabilidade dos dados: o titular poderá solicitar
                a portabilidade dos seus dados, observadas as normas aplicáveis
                e os segredos comerciais e industriais;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O exercício do direito à portabilidade de dados está
                condicionado à regulamentação pela Autoridade Nacional, que, até
                então, não foi normatizada;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar a eliminação de dados pessoais tratados com o
                consentimento: o titular poderá solicitar a eliminação dos dados
                pessoais que sejam tratados pelo Capitual por meio da base legal
                do consentimento, disposta no artigo 7, I da Lei Geral de
                Proteção de Dados;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O titular não poderá dispor sobre os seus dados pessoais quando
                eles possuírem bases de tratamento diferentes do consentimento;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar informação sobre as entidades públicas e privadas com
                as quais o controlador realizou a transferência dos dados: o
                titular poderá solicitar ao Capitual informações sobre o
                compartilhamento dos seus dados com terceiros, desde que não
                exista impeditivo legal para tal;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar informação sobre a possibilidade não fornecer o
                consentimento: o titular poderá solicitar ao Capitual que
                esclareça se é possível fornecer determinado produto ou serviço
                sem consentir com o tratamento dos seus dados pessoais, ou quais
                serão as consequências do não fornecimento;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Revogar o consentimento concedido anteriormente: o titular
                poderá revogar o consentimento dado anteriormente para
                tratamento dos seus dados pessoais;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A revogação do consentimento pode impossibilitar o uso de certas
                funcionalidades do aplicativo, ou até mesmo o encerramento dos
                serviços prestados. Contudo, não impedirá que o Capitual utilize
                os dados anonimizados e os dados cujo tratamento esteja baseado
                em outra hipótese legal, que não a do consentimento, prevista na
                LGPD;
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Solicitar a revisão de decisões automatizadas: O titular poderá
                solicitar a revisão das decisões tomadas unicamente com base no
                tratamento automatizado de dados pessoais que afetem seus
                interesses. No Capitual os modelos tecnológicos utilizados são
                consistentes e pautados em princípios legais e éticos.
              </Text.Heading1Regular>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Dados Coletados
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ao se registrar e utilizar do aplicativo ou os serviços
                ofertados pelo Capitual, Você nos fornece informações
                estatísticas sobre seu uso dos serviços, dados agregados e dados
                pessoalmente identificáveis. Isto acontece, pois o fornecimento
                dessas informações é essencial para operação do aplicativo.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais serão coletados nas seguintes situações:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Ao acessar a plataforma do Capitual por meio da aplicação;
                  </p>
                </li>
                <li>
                  <p>
                    Ao navegar nos nossos websites -{' '}
                    <a href="https://www.capitual.com/">
                      https://www.capitual.com/
                    </a>
                    ;
                  </p>
                </li>
                <li>
                  <p>
                    Ao entrar em contato conosco por meio dos nossos canais de
                    atendimento;
                  </p>
                </li>

                <li>
                  <p>
                    Ao participar de pesquisas ou promoções realizadas pelo
                    Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    Ao utilizar serviços de parceiros que utilizam a API do
                    Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    No processo seletivo para contratação de novos
                    colaboradores;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ao navegar em nosso aplicativo, o titular poderá ser
                redirecionado para sites ou aplicativos outros, ocasião em que o
                titular deverá se atentar as políticas de privacidade e aos
                termos de uso desses terceiros, uma vez que o Capitual não se
                responsabiliza pelas práticas e conteúdo de terceiros.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O usuário disponibiliza, ou pode ter que disponibilizar, os
                seguintes dados para utilização dos serviços do Capitual:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Dados de contato: nome completo, número de telefone e
                    e-mail;
                  </p>
                </li>
                <li>
                  <p>
                    Dados de identificação: ao se cadastrar, você fornece
                    algumas informações que podem incluir seu nome, endereço
                    completo, CEP, número de telefone, e-mail, CPF, sexo, data
                    de nascimento, Registro Geral, estado civil, filiação e
                    selfie;
                  </p>
                </li>
                <li>
                  <p>
                    Dados governamentais: comprovante de inscrição e de situação
                    cadastral junto à Receita Federal e quadro social da
                    empresa;
                  </p>
                </li>

                <li>
                  <p>
                    Dados demográficos: poderão ser coletados dados demográficos
                    sobre os usuários por meio de pesquisas encaminhadas por
                    e-mail ou no próprio aplicativo;
                  </p>
                </li>

                <li>
                  <p>
                    Conteúdo do usuário: serão coletados dados enviados pelo
                    usuário quando este entrar em contato com o suporte do
                    Capitual e/ou fizerem avaliações. Esses dados podem ser de
                    feedback, fotografias encaminhadas pelo usuário ao serviço
                    de suporte ao cliente;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Dados criados durante o uso dos serviços pelo aplicativo:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Dados do dispositivo: serão coletados dados sobre os
                    dispositivos usados para acessar nossos serviços, endereço
                    de IP, Device ID e métricas de comportamento. Esses dados
                    são usados para proteger sua conta contra acesso não
                    autorizado, bem como melhorar sua experiência junto à
                    plataforma;
                  </p>
                </li>
                <li>
                  <p>
                    Dados de transação: serão coletados dados de transação
                    relacionados ao uso dos nossos serviços, como por exemplo:
                    tipo de produto adquirido, informações sobre o pedido (data
                    da compra, valor e quantidade), histórico de crédito,
                    histórico de atendimento ao cliente, transações e
                    movimentações financeiras (débito, saques, pagamento,
                    aplicações financeiras e transferência, origem e destino dos
                    valores);
                  </p>
                </li>
                <li>
                  <p>
                    Dados de uso: são dados de interação do usuário com os
                    nossos serviços, por exemplo, data e horário de acesso,
                    recursos do aplicativo que são utilizados, páginas
                    visualizadas e falhas do app;
                  </p>
                </li>

                <li>
                  <p>
                    Metadados relacionados ao canal que você utiliza para se
                    comunicação com o Capitual
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Em algumas situações o Capitual poderá realizar a coleta de
                dados por meio de pixels tags ou tecnologias similares que criam
                e mantêm identificadores únicos.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais dos titulares poderão ser coletados de
                terceiros nas seguintes situações:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Parceiros comerciais do Capitual por meio dos quais os
                    clientes criam ou acessam sua conta, como serviços de redes
                    sociais ou apps e sites que usam as APIs do Capitual ou
                    cujas APIs o Capitual utiliza;
                  </p>
                </li>

                <li>
                  <p>
                    Fornecedores que nos ajudam a confirmar a identidade dos
                    usuários, bem como realizar as verificações referentes a
                    sanções, lavagem de direito ou requisitos de Know Your
                    Cliente desses usuários.
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Cumprir com as normas anti-lavagem de dinheiro e financiamento
                do terrorismo (KYC), verificações de perfil e histórico de
                transações, de acordo com os regulamentos aplicáveis sobre
                prevenção de lavagem de dinheiro.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual poderá coletar informações dos seus clientes que
                estejam disponíveis publicamente ou que foram tornadas públicas
                pelo próprio usuário, por exemplo, quando o usuário faz parte de
                alguma lista de Pessoa Politicamente Exposta (PPE) ou lista de
                restrição (OFAC, CSNU e outras listas internacionais), bem como
                quando interage com as redes sociais do Capitual.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Caso Você se conecte sua conta conosco a uma rede de mídia
                social relevante de terceiros (como Facebook, Instagram,
                LinkedIn), poderemos obter certas Informações sobre Você nessas
                redes de mídias sociais como seu ID de usuário e/ou nome de
                usuário associado, além de informações contidas em seu perfil
                público e conexões.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ressalta-se que as Informações específicas que obtemos dependem
                de suas configurações de privacidade na rede de mídia social
                aplicável. Nesses casos o uso de cookies ocorrerá de acordo com
                as Políticas de Privacidades dos titulares das redes sociais
                respectivas, as quais deverão ser previamente consultadas por
                Você.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Como os Dados são Utilizados por Nós
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados coletados pelo Capitual serão utilizados com o objetivo
                de prestar um serviço de excelência, respeitando as diretrizes
                estabelecidas pelo Banco Central e normativas vigentes. Logo,
                seus dados poderão ser utilizados nas seguintes situações:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>Prestação dos serviços;</p>
                </li>

                <li>
                  <p>Melhorar a segurança dos nossos usuários e serviços;</p>
                </li>

                <li>
                  <p>Suporte ao cliente;</p>
                </li>

                <li>
                  <p>Pesquisa e desenvolvimento;</p>
                </li>

                <li>
                  <p>
                    Enviar comunicações, inclusive de marketing, aos usuários;
                  </p>
                </li>

                <li>
                  <p>Cumprimento de exigência legal;</p>
                </li>

                <li>
                  <p>
                    Em processos seletivos para contratação de novos
                    colaboradores;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais informados por Você serão ou poderão ser
                utilizados pelo Capitual para:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Identificar e autenticar se o titular preenche os requisitos
                    exigidos pela legislação para contratar os serviços e
                    produtos ofertados pelo Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    Verificar a identidade do titular no ato da contratação,
                    realizando os procedimentos de Know Your Client (KYC) e Anti
                    Money Laundering (AML);
                  </p>
                </li>

                <li>
                  <p>Prestar o serviço e oferecer o produto contratado;</p>
                </li>

                <li>
                  <p>
                    Confirmar a identidade e a elegibilidade dos usuários que
                    pretendem utilizar dos serviços ofertados pelo Capitual,
                    inclusive por meio de dados biométricos (autorretrato),
                    quando permitido por lei, para prevenir fraude e o uso dos
                    nossos serviços para atos ilícitos;
                  </p>
                </li>

                <li>
                  <p>
                    Autenticar as transações financeiras realizadas dentro da
                    plataforma do Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    Entrar em contato com o titular por meio de telefone,
                    e-mail, SMS, WhatsApp ou qualquer outro meio de comunicação
                    para envio de notificação ou push de uso dos serviços do
                    Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    Atender as solicitações dos titulares e esclarecer suas
                    dúvidas;
                  </p>
                </li>

                <li>
                  <p>
                    Aprimorar os serviços prestados pelo Capitual, inclusive com
                    o cruzamento de informações sobre produtos contratados por
                    pessoa física e pessoa jurídica para oferta de novos
                    produtos ou serviços;
                  </p>
                </li>

                <li>
                  <p>
                    Marketing, prospecção, pesquisas de mercado, de opinião,
                    promoção dos produtos e serviços ofertados pelo Capitual ou
                    pelos seus parceiros;
                  </p>
                </li>

                <li>
                  <p>
                    Envio de novidades, funcionalidades, conteúdos e notícias
                    relevantes para manutenção da relação entre o Capitual com
                    Você;
                  </p>
                </li>

                <li>
                  <p>Prevenir e remediar problemas técnicos e de segurança;</p>
                </li>

                <li>
                  <p>
                    Estabelecer medidas de prevenção e combates à ilícitos,
                    fraudes, crimes financeiros, assegurando a segurança dos
                    usuários da Plataforma do Capitual e do sistema financeiro;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual coleta dados de reconhecimento facial durante a etapa
                de 'Prova de Vida' de nosso procedimento de verificação de
                contas. Esses detalhes incluem indicadores faciais únicos que
                podem ser utilizados para identificá-lo.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual pode usar ferramentas adicionais de reconhecimento
                facial ou reconhecimento de digitais para segurança local em
                nossos aplicativos para smartphones, incluindo ferramentas que
                não se limitam ao reconhecimento através de imagens, mas por
                meio de mapeamento 3D ou outras formas. No entanto, tais
                ferramentas não compartilham conosco detalhes coletados pelos
                sensores biométricos, e sim, apenas o resultado do
                reconhecimento como um valor absoluto (sucesso ou falha).
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Exercícios regular dos direitos do Capitual em processos
                    judiciais e administrativos, caso necessário;
                  </p>
                </li>

                <li>
                  <p>
                    Cumprir as obrigações legais de coleta, compartilhamento e
                    armazenamento de dados com o governo, na forma de leis e
                    regulamentos aplicáveis;
                  </p>
                </li>

                <li>
                  <p>Cumprimento de obrigação legal ou regulatória.</p>
                </li>

                <li>
                  <p>
                    Se Você se candidatar a uma vaga no Capitual, ou recebermos
                    suas Informações em conexão com uma possível função com o
                    Capitual, poderemos usar tais Informações para avaliar a
                    candidatura e entrar em contato com Você. Se Você for
                    candidato, no momento da inscrição, receberá mais
                    Informações sobre como o Capitual lida com as Informações
                    pessoais de candidatos, e poderá ser direcionado a uma
                    plataforma administrada por um terceiro parceiro do
                    Capitual.
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os documentos pessoais fornecidos pelo titular podem ser
                compartilhados com terceiros, incluindo, entre outros, órgão
                executores da lei, em caso de suspeita e envolvimento com
                atividades ilegais.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais coletados de terceiros serão ou poderão ser
                utilizados pelo Capitual para:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Os dados pessoais dos clientes poderão ser compartilhados
                    com terceiros para realização dos processos acima descritos
                    (KYC e AML), bem como as autoridades competentes em caso de
                    suspeita de envolvimento com atividades ilegais;
                  </p>
                </li>
                <li>
                  <p>
                    Aprimorar os serviços e produtos ofertados pelo Capitual;
                  </p>
                </li>
                <li>
                  <p>
                    Prospectar novos clientes, divulgação dos produtos e
                    serviços ofertados pelo Capitual, pesquisa de mercado e de
                    opinião;
                  </p>
                </li>
                <li>
                  <p>Prevenir e resolver problemas técnicos e de segurança;</p>
                </li>
                <li>
                  <p>
                    Investigar e adotar medidas de prevenção e combate a
                    ilícitos, fraudes, crimes financeiros;
                  </p>
                </li>
                <li>
                  <p>Cumprimento de obrigação legal ou regulatória;</p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais coletados do dispositivo do titular serão ou
                poderão ser utilizados pelo Capitual para:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>Prestar o serviço e oferecer dos produtos contratados; </p>
                </li>
                <li>
                  <p>
                    Aperfeiçoamento do uso e experiência do usuário com o
                    aplicativo;
                  </p>
                </li>
                <li>
                  <p>
                    Recomendar de novos serviços, produtos ou funcionalidades do
                    aplicativo para o usuário;
                  </p>
                </li>
                <li>
                  <p>
                    Solucionar problemas operacionais de software, fazer testes,
                    pesquisas e análises de dados, além de monitorar e analisar
                    tendências de uso e atividade;
                  </p>
                </li>
                <li>
                  <p>Prevenir e remediar problemas técnicos e de segurança;</p>
                </li>
                <li>
                  <p>
                    Identificar utilização fraudulenta de nossos serviços, além
                    de prevenir e combater o acesso não autorizado à conta dos
                    usuários.
                  </p>
                </li>
                <li>
                  <p>
                    Exercício regular dos direitos do Capitual em processos
                    judiciais e administrativos, caso necessário;
                  </p>
                </li>
                <li>
                  <p>
                    Cumprir ordem judicial, de autoridade competente ou de órgão
                    fiscalizador;{' '}
                  </p>
                </li>
                <li>
                  <p>Cumprir obrigação legal ou regulatória;</p>
                </li>

                <li>
                  <p>
                    Associar seu navegador e/ou dispositivo a outros navegadores
                    ou dispositivos que Você usa, com o objetivo de fornecer
                    acesso relevante e fácil ao conteúdo, publicidade em
                    navegadores e dispositivos e outros fins
                    operacionais/comerciais legítimos;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Dados pessoais originados pela utilização dos serviços e
                produtos ofertados pelo Capitual
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Prestação dos serviços e entrega dos produtos contratados;
                  </p>
                </li>

                <li>
                  <p>Autenticar transações realizadas no aplicativo;</p>
                </li>

                <li>
                  <p>Atender e ofertar o devido suporte ao usuário;</p>
                </li>

                <li>
                  <p>
                    Aprimorar os serviços prestados pelo Capitual, bem como
                    desenvolver novos produtos e serviços;
                  </p>
                </li>

                <li>
                  <p>
                    Solucionar problemas operacionais de software, fazer testes,
                    pesquisas e análises de dados, além de monitorar e analisar
                    tendências de uso e atividade;
                  </p>
                </li>

                <li>
                  <p>Prevenir e resolver problemas técnicos e de segurança;</p>
                </li>

                <li>
                  <p>
                    Investigar e adotar medidas de prevenção e combate a
                    ilícitos, fraudes, crimes financeiros;
                  </p>
                </li>

                <li>
                  <p>
                    Exercício regular dos direitos do Capitual em processos
                    judiciais e administrativos, caso necessário;
                  </p>
                </li>

                <li>
                  <p>
                    Cumprir ordem judicial, de autoridade competente ou de órgão
                    fiscalizador;
                  </p>
                </li>

                <li>
                  <p>Cumprir obrigação legal ou regulatória;</p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Dados pessoais obtidos em fontes públicas
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Divulgar produtos e serviços prestados pelo Capitual em
                    redes sociais, websites, aplicativos ou materiais
                    institucionais e publicitários;
                  </p>
                </li>

                <li>
                  <p>
                    Identificar e autenticar se o titular preenche os requisitos
                    exigidos pela legislação para contratar os serviços e
                    produtos ofertados pelo Capitual;
                  </p>
                </li>

                <li>
                  <p>
                    Verificar a identidade do titular no ato da contratação,
                    realizando os procedimentos de Know Your Client (KYC) e Anti
                    Money Laundering (AML);
                  </p>
                </li>

                <li>
                  <p>
                    Investigar e adotar medidas de prevenção e combate a
                    ilícitos, fraudes, crimes financeiros; Exercício regular dos
                    direitos do Capitual em processos judiciais e
                    administrativos, caso necessário;
                  </p>
                </li>

                <li>
                  <p>
                    Exercício regular dos direitos do Capitual em processos
                    judiciais e administrativos, caso necessário; e
                  </p>
                </li>

                <li>
                  <p>Cumprir obrigação legal ou regulatória;</p>
                </li>

                <li>
                  <p>
                    Complementar suas Informações pessoais com dados adicionais
                    de fontes disponíveis publicamente e comercialmente e/ou
                    Informações de empresas pertencentes ao grupo econômico do
                    Capitual e de parceiros comerciais;
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Tratamento de dados de terceiros
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Atender solicitações de nossos clientes, se Você for
                    beneficiário ou interessado em Serviços contratados por
                    terceiro (como transferência entre contas, emissão de
                    boletos bancários, por exemplo);
                  </p>
                </li>

                <li>
                  <p>
                    Apoio e promoção das nossas atividades, se Você for sócio,
                    administrador, procurador ou representante de empresa que é
                    cliente, fornecedora, parceira ou mantém qualquer outro
                    vínculo com o Capitual, como parceiro comercial ou prestador
                    de serviços, incluindo instituições do sistema financeiro e
                    do mercado de meios de pagamento (instituições financeiras,
                    instituições de pagamento, entre outras), correspondentes no
                    país e exterior;
                  </p>
                </li>

                <li>
                  <p>
                    Em sua conta do Capitual é possível adicionar outros
                    usuários, e com isso você poderá efetuar e solicitar
                    pagamentos de forma mais rápida, ou enviar fundos em
                    criptomoeda para qualquer carteira, seja ela do Capitual ou
                    não.
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Na hipótese de compartilhamento de dados pessoais de terceiros
                com o Capitual em conexão com atividades econômicas, Você deverá
                obter o consentimento livre, expresso e informado do titular dos
                dados pessoais, ou de seu representante legal, ou embasar cada
                tratamento em algum hipótese legal trazida pela legislação
                aplicável.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual efetuará o registro e o tratamento de dados e
                informações relacionados a ocorrências, tentativas e/ou indícios
                de fraudes, com o propósito de preveni-las e assegurar a
                segurança das operações realizadas na plataforma, em
                conformidade com as obrigações legais aplicáveis.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Compartilhamento dos Dados Pessoais
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A base de dados formada pelo Capitual não será compartilhada,
                vendida, cedida, transferida, informada ou alugada a terceiros,
                exceto nas situações previstas nesta política de privacidade.
                Sempre que se fizer necessário, o compartilhamento dos seus
                dados pessoais será realizado dentro dos limites e propósitos
                dos nossos negócios e de acordo com o que autoriza a legislação
                aplicável.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Informações podem ser compartilhadas entre as empresas do grupo
                econômico do Capitual ou com parceiros, como forma de garantir a
                excelência de nossos Serviços, bem como sempre atender Você da
                melhor forma possível.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Assim, poderá haver compartilhamento dos seus dados pessoais:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    em caso de transferência de propriedade do Capitual ou de
                    suas empresas correspondentes, que podem ou não fazer parte
                    do grupo de companhias que operam em conjunto em prol da
                    disponibilidade dos serviços prestados;
                  </p>
                </li>

                <li>
                  <p>
                    Com fornecedores e prestadores de serviços contratados pelo
                    Capitual, para executar atividades complementares aos nossos
                    Serviços.
                  </p>
                </li>

                <li>
                  <p>
                    Provedores de serviços no Brasil e em outras jurisdições que
                    prestam serviços em nosso nome.
                  </p>
                </li>

                <li>
                  <p>
                    prestadores de serviços, instituições bancárias,
                    fornecedores, consultores, parceiros comerciais, entre
                    outras, tais como: provedores de armazenamento em nuvem;
                    empresas de pagamento; parceiros de marketing e provedores
                    de plataformas de marketing, inclusive serviços de
                    publicidade em redes sociais, redes de publicidade,
                    provedores de dados de terceiros; parceiros de pesquisa;
                    provedores de serviço de análise; e consultores, advogados,
                    contadores.
                  </p>
                </li>

                <li>
                  <p>
                    Com o Banco Central do Brasil, o Conselho de Controle de
                    Atividades Financeiras ou outros órgãos que a legislação
                    previr, no âmbito de comunicados referentes às operações que
                    possam estar configuradas na Lei 9.613/98 (que dispõe sobre
                    os crimes de lavagem ou ocultação de bens, direitos e
                    valores) e demais disposições legais pertinentes à matéria.
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O compartilhamento dos dados dos titulares com terceiros pode
                ter as seguintes finalidades:
              </Text.Heading1Regular>
              <ul>
                <li>
                  <p>
                    Checar a identidade do titular e a elegibilidade para
                    contratar os serviços prestados pelo Capitual;
                  </p>
                </li>
                <li>
                  <p>
                    Viabilizar a prestação de serviço, uma vez que o Capitual
                    utiliza de provedores na nuvem para desenvolver e operar o
                    aplicativo;
                  </p>
                </li>
                <li>
                  <p>
                    Viabilizar o contato por telefone, e-mail, SMS, WhatsApp,
                    notificação push ou outros meios de comunicação;
                  </p>
                </li>
                <li>
                  <p>
                    Investigar e adotar medidas de prevenção e combate a
                    ilícitos, fraudes, crimes financeiros;
                  </p>
                </li>
                <li>
                  <p>Exercício regular de direitos do Capitual;</p>
                </li>
                <li>
                  <p>
                    Cumprir ordem judicial, de autoridade competente ou de órgão
                    fiscalizador;
                  </p>
                </li>
                <li>
                  <p>Cumprimento de obrigação legal ou regulatória;</p>
                </li>
                <li>
                  <p>
                    Marketing, prospecção, pesquisa de mercado e de opinião;
                  </p>
                </li>
                <li>
                  <p>
                    Evitar danos físicos, danos ou perdas financeiras ou em
                    conexão com uma investigação de atividade fraudulenta ou
                    ilegal, suspeita ou real.
                  </p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O compartilhamento dos dados dos titulares com as autoridades
                públicas e órgãos reguladores pode ter as seguintes finalidades:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <p>
                    Investigar e adotar medidas de prevenção e combate a
                    ilícitos, fraudes, crimes financeiros;
                  </p>
                </li>
                <li>
                  <p>Exercício regular de direitos do Capitual; </p>
                </li>
                <li>
                  <p>
                    Cumprir ordem judicial, de autoridade competente ou de órgão
                    fiscalizador/regulatório;
                  </p>
                </li>
                <li>
                  <p>Cumprimento de obrigação legal ou regulatória.</p>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual poderá, ainda, utilizar os dados pessoais dos seus
                usuários para extrair informações estatísticas sobre o negócio,
                destaca-se que nesse caso os dados divulgados nunca estarão
                relacionados à indivíduo específico.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual poderá compartilhar, com outras instituições do
                sistema financeiro e órgãos reguladores, seus dados e
                informações relacionados a ocorrências, tentativas ou indícios
                de fraudes com a finalidade de preveni-las e garantir a
                segurança das operações realizadas na plataforma, em
                conformidade com as legislações e regulamentos vigentes.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Como Seus Dados São Protegidos
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Mantemos salvaguardas administrativas, técnicas e físicas
                projetadas para proteger as Informações que temos sobre Você
                contra destruição, perda, alteração, acesso, divulgação ou uso
                acidental, ilegal ou não autorizado. Contudo, não é possível
                garantir completamente a não ocorrência de interceptações e
                violações dos sistemas e bases de dados do Capitual, uma vez que
                a internet e a proteção de dados possuem sua estrutura de
                segurança em permanente aperfeiçoamento.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Por essa razão, além das medidas técnicas e administrativas,
                estamos constantemente promovendo a cultura de proteção de dados
                pessoais, treinamento de pessoal, melhoria dos nossos processos
                e relações contratuais com parceiros. Nossa Central de Ajuda
                dispõe de materiais de apoio para auxiliar nossos clientes com
                Dicas & Tutoriais, Guias passo-à-passo sobre como usufruir de
                sua conta e mantê-la segura.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O acesso às Informações estará restrito a pessoas autorizadas,
                treinadas para o uso adequado dessas Informações. Os
                colaboradores que utilizarem as Informações indevidamente,
                infringindo essa Política de Privacidade, estarão sujeitas às
                medidas legais, bem como as penalidades do processo disciplinar
                e ético do Capitual.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O Capitual não se responsabiliza pelo envio de falsos e-mails em
                seu nome, com promessas mentirosas, ofertas falsas, formulários
                fraudulentos e/ou qualquer tipo de comunicação encaminhada por
                terceiros.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Por isso, em caso dúvida, favor entrar em contato através dos
                nossos canais oficiais.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Transferência Internacional de Dados Pessoais
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais dos titulares poderão ser transferidos para o
                exterior, por exemplo quando são armazenados pelo Capitual em
                servidores de computação localizados fora do Brasil. De toda
                forma, o Capitual cumpre com todos os requisitos exigidos pela
                Lei Geral de Proteção de Dados, bem como possui uma avaliação
                criteriosa quanto aos seus prestadores de serviço, exigindo a
                adoção das melhores práticas de segurança e privacidade com o
                objetivo de garantir a integridade e confidencialidade dos dados
                pessoais dos titulares.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Retenção e Exclusão dos Dados Pessoais
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Os dados pessoais dos titulares serão tratados de acordo com os
                termos previstos na presente política até o encerramento do
                vínculo entre o titular e o Capitual, armazenando-os e
                mantendo-os em ambiente seguro e controlado, enquanto a
                Instituição detiver as suas posses.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A fim de determinar o período de retenção adequado para cada
                tipo de dado pessoal coletado, o Capitual elaborou a sua tabela
                de temporalidade com base em uma análise técnica realizada pela
                entidade, considerando a natureza do dado, a necessidade de
                coletá-lo, a finalidade de processamento, bem como se a retenção
                se faz necessária para cumprir obrigações ou proteger direitos
                individuais ou coletivos.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A solicitação de exclusão dos dados do titular poderá ser
                realizada por meio do e-mail&nbsp;
                <a href="mailto:dpo@capitual.com">dpo@capitual.com</a>.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                O time de privacidade do Capitual irá analisar a solicitação,
                bem como informar quais dados poderão ser excluídos e os que
                deverão continuar armazenados no banco de dados da instituição.
              </Text.Heading1Regular>

              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Atualizações da nossa política de privacidade
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A solicitação de exclusão dos dados do titular poderá ser Esta
                Política de Privacidade pode ser atualizada periodicamente e sem
                aviso prévio para refletir alterações em nossas práticas. Para
                alterações significativas, notificaremos você publicando um
                aviso em destaque em nossos Serviços.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Ao continuar utilizando os produtos e serviços ofertados pelo
                Capitual após a alteração da política de privacidade, Você
                concordará com as novas condições, contudo, nosso canal de
                atendimento está sempre à disposição para receber os
                questionamentos dos usuários.
              </Text.Heading1Regular>
            </Col>
          </Row>

          <Row>
            <Col>
              <Text.Heading2
                fontSize="25px"
                lineHeight={130}
                marginTop={60}
                marginBottom={20}
              >
                Atualizações da nossa política de privacidade
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Esta Política de Privacidade pode ser atualizada periodicamente
                e sem aviso prévio para refletir alterações em nossas práticas.
                Para alterações significativas, notificaremos você publicando um
                aviso em destaque em nossos Serviços.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                A utilização dos Serviços do Capitual implicará na irrestrita
                aceitação das condições desta Política de Privacidade, incluindo
                as alterações que porventura venham a ser realizadas.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={30}
              >
                Atualizado em 03/11/2023
              </Text.Heading1Regular> */}
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default PoliticaPrivacidade;
