import React from "react";
import { colors } from "~/styles";
import * as Style from "./ExercicioDosDireitosDosTitulares.styles";
import * as Text from "~/styles/text";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";

import LinkIcon from "~/images/legal/link.svg";

function ExercicioDosDireitosDosTitulares() {
  const { t, i18n } = useTranslation();

  const redirectTo = (route) => {
    window.location.href = route;
  };

  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Exercício dos Direitos dos Titulares
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row className="mb-20">
            <Col>
              <Text.Heading1Regular fontSize="18px" lineHeight={160} marginBottom={20}>
              Bem-vindo à nossa aba dedicada ao exercício dos seus direitos como titular de dados pessoais. Reconhecemos a importância da privacidade e transparência no tratamento de suas informações e estamos comprometidos em garantir que você possa exercer seus direitos de maneira simples e eficaz.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Aqui, você encontrará dois formulários que facilitarão o exercício dos seus direitos de privacidade:
              </Text.Heading1Regular>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col>
              <Text.Heading1Regular fontSize="18px" lineHeight={160} marginBottom={20} display="inline-block">
                <b>1. Formulário de Acesso aos Dados Pessoais:</b> Utilize este formulário para solicitar acesso às informações que mantemos sobre você. Estamos empenhados em fornecer transparência sobre como seus dados são utilizados e armazenados.
              </Text.Heading1Regular>           
            </Col>
          </Row>

          <Row className="mb-20">
            <Col>
              <Text.Heading1Regular fontSize="18px" lineHeight={160} marginBottom={20} display="inline-block">
                <b>2. Formulário de Solicitação de Exclusão da Conta e dos Dados:</b> Caso deseje encerrar sua conta e solicitar a exclusão de seus dados pessoais, este formulário simplificado permitirá que você faça essa solicitação de forma rápida e conveniente.
              </Text.Heading1Regular>           
            </Col>
          </Row>

          <Row>
            <Col>
              <Text.Heading1Regular fontSize="18px" lineHeight={160} marginBottom={20}>
                Estamos aqui para ajudá-lo durante todo o processo e garantir que suas solicitações sejam tratadas com o devido cuidado e conformidade com as leis de proteção de dados aplicáveis.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Por favor, selecione o formulário correspondente à sua solicitação abaixo e siga as instruções para começar. Se tiver alguma dúvida ou precisar de assistência, não hesite em entrar em contato com nossa equipe de atendimento ao cliente.
              </Text.Heading1Regular>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="button-content" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdi8FjsdkVpBElA4kD_xpmoiJ3jlvf_4Cz8Lyry8YPXIVUXdw/viewform", "_blank").focus()}>
                <Text.Heading4Bold
                  fontSize="17px"
                  lineHeight={135}
                >
                  Formulário de Acesso aos Dados Pessoais
                </Text.Heading4Bold>

                <img src={LinkIcon} alt="" />
              </div> 
            </Col> 
            <Col md={6}>
              <div className="button-content" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdrXPQy_G_CchCeNk70i4KG67K49plxCvabq4mgJBYUISKnpQ/viewform", "_blank").focus()}>
                <Text.Heading4Bold
                  fontSize="17px"
                  lineHeight={135}
                >
                  Formulário de Solicitação de Exclusão da Conta e dos Dados
                </Text.Heading4Bold>

                <img src={LinkIcon} alt="" />
              </div>             
            </Col>      
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default ExercicioDosDireitosDosTitulares;