import styled from 'styled-components';
import { colors } from '~/styles';

export const Container = styled.div`

  .content {
    padding: 60px 0;

    background-color: ${colors.white};

    ul {
      padding-left: 24px;

      li {
        list-style-type: square;
        font-size:13px;

        p {
          font-family: "Helvetica Now Display Regular", sans-serif;
          font-size: 18px;
          line-height: 160%;
          margin-bottom: 20px;
        }
      }
    }
  }
`;
export const Header = styled.div`
  background-color: #026FF4;

  .banner {
    margin-top: 80px;

    padding: 96px 0;
  }
`;