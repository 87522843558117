const news = [{
	title: "Yahoo! Finance",
	picture: { light: require("./images/media/yahoo-finance.png"), dark: require("./images/media/yahoo-finance-dark.png") },
	link: "https://br.financas.yahoo.com/noticias/banco-digital-capitual-vai-dar-120445673.html"
},{
	title: "The CoinTelegraph",
	picture: { light: require("./images/media/cointelegraph.png"), dark: require("./images/media/cointelegraph-dark.png") },
	link: "https://cointelegraph.com.br/news/banco-capitual-lanca-plataforma-financeira-para-empresas-operarem-com-criptomoedas"
},{
	title: "Valor Investe",
	picture: { light: require("./images/media/valor-investe.png"), dark: require("./images/media/valor-investe-dark.png") },
	link: "https://valorinveste.globo.com/mercados/cripto/noticia/2020/10/28/banco-digital-capitual-e-binance-fecham-parceria-que-permite-depositos-em-reais-na-exchange.ghtml?utm_source=Capitual.com"
},{
	title: "Portal do Bitcoin",
	picture: { light: require("./images/media/portal-do-bitcoin.png"), dark: require("./images/media/portal-do-bitcoin-dark.png") },
	link: "https://portaldobitcoin.uol.com.br/parceria-entre-huobi-e-capitual-permite-compra-de-criptomoedas-com-reais/"
},
{
	title: "Investing.com",
	picture: { light: require("./images/media/investing.com.png"), dark: require("./images/media/investing.com.png") },
	link: "https://br.investing.com/news/cryptocurrency-news/binance-e-capitual-ampliam-parceria-para-atender-empresas-885015"
},
{
	title: "Olhar Digital",
	picture: { light: require("./images/media/olhardigital.png"), dark: require("./images/media/olhardigital.png") },
	link: "https://olhardigital.com.br/2021/12/03/pro/capitual-possibilita-uma-conta-digital-bancaria-completa-e-servicos-com-criptomoedas-em-um-so-lugar/"
},
{
	title: "Money Times",
	picture: { light: require("./images/media/money-times.png"), dark: require("./images/media/money-times.png") },
	link: "https://www.moneytimes.com.br/conteudo-de-marca/pix-e-boletos-com-bitcoin-conheca-a-plataforma-que-ja-integra-servicos-bancarios-tradicionais-com-criptomoedas/"
},
{
	title: "Valor Econômico",
	picture: { light: require("./images/media/valoreconomico.png"), dark: require("./images/media/valoreconomico.png") },
	link: "https://valor.globo.com/patrocinado/capitual/noticia/2021/12/20/criptomoedas-podem-ser-usadas-no-dia-a-dia-das-pessoas-e-das-empresas.ghtml"
},
]

export default news