import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Reveal, Fade } from "react-reveal";
import useWindowDimensions from "~/hooks/windowDimension";
import useDetectDevice from "~/hooks/detectDevice";
import { breakpoints } from "~/styles/breakpoints";
import colors from "~/styles/colors";
import { If, Then, Else } from "react-if";

import { menuOptions } from "~/data/menu";

import * as Style from "./Menu.styles";
import * as Text from "~/styles/text";

import GenericButton from "../GenericButton";
import MobileMenu from "../MobileMenu";

import Logo from "~/images/logo.svg";
import LogoDark from "~/images/logo-dark.svg";
import ArrowDownGrayIcon from "~/images/menu/arrow-down-gray.svg";
import ArrowDownIcon from "~/images/menu/arrow-down.svg";
import QrCode from "~/images/onlink_to_capitual.svg";
import Close from "~/images/close.png";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const pageWidth = 1045;

function Menu({ isDarkMenu, newButton }) {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const { isMobile, isDesktop } = useDetectDevice();

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalFade, setModalFade] = useState(false);
  const [showDropdown, setShowDropdown] = useState("");
  const [menuModal, setMenuModal] = useState(false);
  const [scrollWhiteMenu, setScrollWhiteMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const header = document.querySelector(".item-container");
    const scrollTop = window.scrollY;

    if (scrollTop >= 150 && !isDesktop()) {
      header.classList.add("is-sticky");
      setScrollWhiteMenu(true);
    } else {
      header.classList.remove("is-sticky");
      setScrollWhiteMenu(false);
    }
  };

  const openModal = () => {
    setModalIsVisible(true);
  };

  const closeModal = () => {
    if (modalIsVisible === true) {
      setModalFade(false);
    }

    setTimeout(() => setModalIsVisible(false), 350);
  };

  useEffect(() => {
    if (modalIsVisible === true) {
      setModalFade(true);
    }
  }, [modalIsVisible]);

  const redirectTo = (route) => {
    window.location.href = route;
  };

  const handleLinkMenu = (subItem) => {
    if (
      location.pathname === "/" &&
      (subItem.label === "menu.products.capConta" ||
        subItem.label === "menu.products.capWallets")
    ) {
      redirectTo(subItem.route2);
    } else {
      redirectTo(subItem.route);
    }
  };

  return (
    <>
      {menuModal ? (
        <MobileMenu CloseButton={setMenuModal} />
      ) : (
        <Style.Container className="header-section" isDarkMenu={isDarkMenu}>
          <div className="item-container">
              <img
                onClick={() => (window.location.href = "/")}
                src={isDarkMenu || scrollWhiteMenu ? LogoDark : Logo}
                width={130}
                alt="Capitual"
              />

              {isMobile() ||
                (pageWidth < width && (
                  <>
                    <div className="menu-container">
                      {menuOptions.map((item) => (
                        <div
                          key={item.menuTitle}
                          onMouseOver={() => setShowDropdown(item.menuTitle)}
                          onMouseLeave={() => setShowDropdown("")}
                        >
                          <div className="menu-item-content">
                            <Text.Heading5Bold
                              fontSize="15px"
                              color={
                                isDarkMenu || scrollWhiteMenu
                                  ? colors.black
                                  : colors.white
                              }
                            >
                              {t(item.menuTitle)}
                            </Text.Heading5Bold>

                            <img
                              src={
                                isDarkMenu || scrollWhiteMenu
                                  ? ArrowDownGrayIcon
                                  : ArrowDownIcon
                              }
                              alt=""
                            />
                          </div>

                          <AnimatePresence>
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.4 }}
                              key={showDropdown}
                            >
                              {showDropdown === item.menuTitle && (
                                <div className={"sub-menu visible"}>
                                  <div className="sub-menu-content">
                                    {item.submenu.map((subItem) => (
                                      <a
                                        className={
                                          !subItem.enbabled && `disabled`
                                        }
                                        key={subItem.label}
                                        onClick={() => handleLinkMenu(subItem)}
                                      >
                                        <img src={subItem.icon} alt="" />
                                        <Text.Heading5 fontSize="15px">
                                          {t(subItem.label)}
                                        </Text.Heading5>
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </motion.div>
                          </AnimatePresence>
                        </div>
                      ))}
                    </div>
                  </>
                ))}

            <If condition={!!newButton}>
              <Then>
                {newButton}
              </Then>

              <Else>
                {isMobile() || pageWidth > width ? (
                  <div
                    className="mobile-menu"
                    onClick={() => setMenuModal(!menuModal)}
                  >
                    <span
                      style={{
                        background:
                          isDarkMenu || scrollWhiteMenu ? colors.black : colors.white,
                      }}
                    />
                    <span
                      style={{
                        background:
                          isDarkMenu || scrollWhiteMenu ? colors.black : colors.white,
                      }}
                    />
                    <span
                      style={{
                        background:
                          isDarkMenu || scrollWhiteMenu ? colors.black : colors.white,
                      }}
                    />
                  </div>
                ) : (
                  <GenericButton
                    grayHover
                    buttonType="fourth"
                    onClick={() =>
                      isMobile()
                        ? redirectTo("https://onelink.to/capitual")
                        : openModal()
                    }
                    paddingVertical={20}
                    borderRadius={50}
                  >
                    <Text.Heading5Bold
                      fontSize="15px"
                      color={ colors.white }
                    >
                      {t("menu.signup")}
                    </Text.Heading5Bold>
                  </GenericButton>
                )}
              </Else>
            </If>
          </div>
        </Style.Container>
      )}

      <Fade when={modalIsVisible} duration={350}>
        <div
          className={`modal ${modalIsVisible && `visible`}`}
          style={{ zIndex: 999999999999 }}
        >
          <Fade right when={modalFade} duration={400}>
            <div className="modal-content2">
              <div className="scontainero" style={{ height: "80%" }}>
                <div className="closeButton" onClick={() => closeModal()}>
                  <img
                    src={Close}
                    alt="fechar"
                    style={{ alignSelf: "flex-end" }}
                    width="16"
                  />
                </div>

                <div className="has-container-centered">
                  <Text.Heading1Bold
                    width="70%"
                    fontSize="32px"
                    textAlign="center"
                    marginBottom={30}
                    data-reveal-delay={400}
                  >
                    {t("modal.title")}
                  </Text.Heading1Bold>

                  <Text.Small
                    width="85%"
                    fontSize="16px"
                    textAlign="center"
                    marginBottom={40}
                    data-reveal-delay={400}
                  >
                    {t("modal.description")}
                  </Text.Small>

                  <img src={QrCode} alt="QrCode" width="200" />

                  <Text.Small
                    width="85%"
                    fontSize="16px"
                    color={colors.grayMedium}
                    textAlign="center"
                    marginTop={30}
                    data-reveal-delay={400}
                  >
                    {t("modal.tip")}
                  </Text.Small>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Fade>
    </>
  );
}

export default Menu;
