import React from "react";
import { colors } from "~/styles";
import * as Style from "./Kyc.styles";
import * as Text from "~/styles/text";
import { Container, Row, Col } from "react-bootstrap";

import Menu from "~/components/Menu";
import Footer from "~/components/Footer";

function Kyc() {
  return (
    <Style.Container>
      <Style.Header>
        <Menu />

        <Container>
          <Row>
            <Col>
              <div className="banner">
                <Text.Heading1Medium
                  fontSize="45px"
                  lineHeight={130}
                  color={colors.white}
                >
                  Política de KYC (Conheça Seu Cliente)
                </Text.Heading1Medium>
              </div>
            </Col>
          </Row>
        </Container>
      </Style.Header>

      <div className="content">
        <Container>
          <Row>
            <Col className="mb-60">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Introdução
              </Text.Heading2>

              <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                O KYC - Know Your Client (Conheça Seu Cliente) é um dos mais
                importantes pilares na prevenção à lavagem de dinheiro e ao
                financiamento do terrorismo e também recomendado pelo Comitê da
                Basiléia, pelo qual os bancos devem estabelecer um conjunto de
                regras e procedimentos adequados, com o objetivo de identificar
                e conhecer a origem e constituição do patrimônio e dos recursos
                financeiros do cliente. Pelos procedimentos adotados, os bancos
                visam prover direcionamento e padronização para o início, a
                manutenção e o monitoramento do relacionamento com aqueles que
                utilizam ou que pretendam utilizar os produtos e serviços, de
                modo a prevenir qualquer forma de colaboração com a lavagem de
                dinheiro e ao financiamento do terrorismo ou quaisquer outras
                atividades ilícitas.
              </Text.Heading1Regular>
            </Col>
          </Row>

          <Row>
            <Col className="mb-60">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Identificação de clientes
              </Text.Heading2>

              <ul>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Conheça seu cliente: Tem como objetivo conhecer detalhes
                    reputacionais da vida pessoal ou empresarial do potencial
                    cliente, e ajudar a proteger a reputação e a integridade da
                    Capitual. Nesse processo são extraídas informações de
                    diversas fontes externas e avaliados os riscos de ocorrência
                    de práticas de atos ilícitos.
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                    Cadastro de Clientes: Responsável pela obtenção de
                    informações e documentos, análise e registro de
                    identificação de clientes com os quais a Capitual mantém
                    relacionamento através dos serviços e produtos.
                  </Text.Heading1Regular>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col className="mb-60">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Cadastro do Cliente
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Para início de relacionamento com a Capitual, o cliente deverá
                fornecer todas as informações e documentos cadastrais
                solicitados. Quanto mais precisas forem as informações coletadas
                e registradas no início do seu relacionamento, maior será a
                capacidade de detectar indícios de fraudes, lavagem de dinheiro
                e possível financiamento ao terrorismo.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                O cadastro não compreende apenas a identificação formal ou
                pessoal do cliente, mas também o conhecimento de seus negócios e
                atividades.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                No cadastro deverá conter informações sobre os beneficiários,
                representantes, procuradores e intervenientes, que permitam
                verificar sua adequada identificação, responsabilidade,
                atividade econômica e capacidade financeira.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Para toda e qualquer transação ou relacionamento, deverá ser
                solicitada e verificada, no mínimo:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Pessoas Naturais: nome completo, filiação, nacionalidade,
                    data e local de nascimento, sexo, estado civil, nome do
                    cônjuge, se casado, profissão, documento de identificação
                    (tipo, número, data de emissão e órgão expedidor), número de
                    inscrição no Cadastro de Pessoas Físicas - CPF, nome e CPF
                    (quando aplicável) de seus representantes e procuradores,
                    endereço residencial, endereço de correspondência, número de
                    telefone e código DDD, valores de renda mensal e patrimônio;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                    Pessoas Jurídicas: razão social, atividade principal, forma
                    e data de constituição, número de inscrição no Cadastro
                    Nacional de Pessoa Jurídica – CNPJ, endereço principal,
                    endereço de correspondência, número de telefone e código
                    DDD, valores de faturamento médio mensal dos doze meses
                    anteriores, nome e CPF (quando aplicável) de seus
                    representantes, procuradores e cadeia de participação
                    societária, até alcançar a pessoa natural caracterizada como
                    beneficiário final, e, se companhia aberta ou entidade sem
                    fins lucrativos, adicionalmente deverá ser solicitado nome e
                    CPF (quando aplicável) de seus controladores,
                    administradores e diretores, quando houver;
                  </Text.Heading1Regular>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col className="mb-60">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Identificação de sua natureza como Pessoa Exposta Politicamente.
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A Capitual deverá solicitar os documentos aos seus clientes para
                confirmação das informações cadastrais e manter as suas cópias.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A Capitual deverá adotar procedimentos para a confirmação das
                informações cadastrais coletadas ou atualizadas, nos termos das
                disposições normativas vigentes, contemplando, inclusive, se
                necessário, a solicitação de documentos comprobatórios das
                informações, de acordo com o risco do cliente, do produto ou da
                operação, respeitando as determinações da regulamentação
                vigente.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A Capitual deverá adotar procedimentos contínuos de atualização
                e adequação das informações cadastrais de seus clientes.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A verificação de informações fornecidas pelo cliente visa:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Avaliar se os dados por ele fornecidos são verídicos e
                    minimizar a possibilidade de aceitação de pessoas envolvidas
                    em crimes de lavagem de dinheiro, financiamento ao
                    terrorismo ou com outros tipos de ilícitos;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                    Colher informações baseadas em documentos, quando existirem
                    indícios ou certeza de que os clientes ou pessoas são
                    representados legalmente por terceiros, verificando e
                    registrando a identidade dos representantes, procuradores e
                    autorizados;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Verificar se a natureza do negócio ou atividade do cliente
                    não se opõe às normas sobre a prevenção à lavagem de
                    dinheiro e financiamento ao terrorismo, estabelecidas nesta
                    política;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Colher e validar as informações e documentos que permitam a
                    completa identificação dos clientes, devendo adotar as
                    medidas que permitam comprovar a veracidade das informações
                    fornecidas;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Validar firmas e poderes dos documentos solicitados;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Analisar dados cadastrais e assinar os formulários e
                    documentos utilizados como suporte nas operações realizadas
                    com clientes;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Evitar o recebimento de informações falsas ou omissão de
                    informações.
                  </Text.Heading1Regular>
                </li>
              </ul>

              <Text.Heading1Bold
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Os dados cadastrais devem ser atualizados no mínimo a cada 24
                meses ou sempre que for identificado informações desatualizadas.
              </Text.Heading1Bold>
            </Col>
          </Row>

          <Row>
            <Col className="mb-20">
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Qualificação e classificação de cliente
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                O potencial cliente antes de iniciar seu relacionamento com a
                Capitual passa por uma avaliação.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Este será submetido a verificação em listas obrigatórias, sendo
                elas: PEPs, Restritivas e Sanções nacionais e Internacionais,
                análise de processos judiciais, mídias, inquéritos policiais,
                país de constituição e outras fontes de dados externos de
                informações reputacionais.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Para as pessoas jurídicas (PJ) a pesquisa se estende para a
                cadeia de participação societária até seu beneficiário final,
                conforme definição do Grupo De Ação Financeira Internacional |
                GAFI.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Para realização de tal análise são utilizadas informações
                dispostas em sistemas contratados e pesquisa em sites públicos
                dispostos na rede mundial de computadores.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Esta análise visa identificar e classificar os potenciais riscos
                de ocorrência de práticas de atos ilícitos.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                O potencial cliente é classificado em 4 níveis de riscos: Baixo,
                Médio, Alto e Crítico.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A classificação do risco do potencial cliente é recomendada pela
                área de PLDCFT, que leva em consideração os seguintes critérios:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Risco Baixo: Não foi encontrado nenhum fato relevante em
                    mídias, não é apontado em nenhuma lista (PPE, Restritiva e
                    Sanção), não possui processos criminais/penais*, não possui
                    inquéritos policiais, não possui restrições socioambientais
                    e em casos de PJ não está constituída em países que possuem
                    leis societárias mais brandas. Para PJ estas diretrizes se
                    estendem por toda a cadeia societária.
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                    Risco Médio: Possui mídia negativa de baixa relevância e não
                    possui processos criminais ou inquéritos. Possui processos
                    criminais ou inquéritos que não envolvam lavagem de dinheiro
                    ou financiamento ao terrorismo de forma direta ou indireta.
                    PJ situadas em países que possuem leis societárias mais
                    brandas, porém com a identificação de seus beneficiários
                    finais. Apontados em listas PPE e restritivas relacionadas a
                    questão socioambiental. Possui restrição socioambiental,
                    porém com justificativa plausível.
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Risco Alto: Possui mídia negativa ou apontado em lista
                    restritiva e possui processos criminais ou inquéritos.
                    Possui processos criminais em andamento sem sentença
                    transitada em julgado ou inquéritos que envolvam lavagem de
                    dinheiro de forma direta ou indireta. PJ situada em países
                    que possuem leis societárias mais brandas, sem a
                    possibilidade de identificação de seus beneficiários finais.
                    Possui restrição socioambiental sem justificativa plausível.
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Risco Crítico: Citado em listas de sanções, condenado em
                    questões relacionadas a lavagem de dinheiro e financiamento
                    do terrorismo ou contra o Sistema Financeiro Nacional.
                  </Text.Heading1Regular>
                </li>
              </ul>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Processos cujo teor/acordão constem como extinta a punibilidade
                do cliente são considerados inócuo, e o analista poderá seguir
                com a aprovação.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Para situações que a área PLDCFT necessite de maiores
                informações para a classificação do risco, o colaborador
                responsável pelo relacionamento será acionado e este irá obter e
                fornecer as informações necessárias solicitadas.
              </Text.Heading1Regular>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                A aprovação do relacionamento com o cliente respeita uma alçada
                de decisão, conforme tabela abaixo:
              </Text.Heading1Regular>
            </Col>
          </Row>

          <Row>
            <Col xd={12} md={12} lg={10} xl={7} className="mb-40">
            <table>
              <tr>
                <th>
                  <Text.Heading2Bold
                    fontSize="18px"
                    line-height={160}
                  >
                    Nível
                  </Text.Heading2Bold>
                </th>
                <th style={{ width: "80%" }}>
                  <Text.Heading2Bold
                    fontSize="18px"
                    line-height={160}
                  >
                    Alçada Mínima de Aprovação
                  </Text.Heading2Bold>
                </th>
              </tr>
              <tr>
                <td>Risco Baixo</td>
                <td>Analista de PLDCFT ou sistema de background check</td>
              </tr>
              <tr>
                <td>Risco Médio</td>
                <td>Diretor de PLDCFT</td>                
              </tr>
              <tr>
                <td>Risco Alto</td>
                <td>Comitê De Gestão De Riscos</td>                
              </tr>
              <tr>
                <td>Risco Crítico</td>
                <td>Reprovado automaticamente e para os casos de pessoas identificadas em listas de Sanções Internacionais, comunicação imediata ao COAF.</td>
              </tr>
            </table>
            </Col>
          </Row>

          <Row>
            <Col>
              <Text.Heading2 fontSize="31px" lineHeight={130} marginBottom={30}>
                Situações não permitidas
              </Text.Heading2>

              <Text.Heading1Regular
                fontSize="18px"
                lineHeight={160}
                marginBottom={20}
              >
                Abaixo estão descritas as situações não permitidas para
                relacionamento com a Capitual:
              </Text.Heading1Regular>

              <ul>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Pessoa Física ou Jurídica, cuja identidade não possa ser
                    confirmada e/ou que não apresentem todos os documentos
                    exigidos;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular fontSize="18px" lineHeight={160}>
                    Pessoa Física ou Jurídica que conste em listas restritivas
                    e/ou de sanções legais;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Segmentos econômicos e ou atividades ilegais que violem a
                    legislação vigente;
                  </Text.Heading1Regular>
                </li>
                <li>
                  <Text.Heading1Regular
                    fontSize="18px"
                    lineHeight={160}
                    marginBottom={20}
                  >
                    Pessoa Física ou Jurídica que possua condenação relacionada
                    aos crimes antecedentes listados a seguir:
                  </Text.Heading1Regular>

                  <ul>
                    <li>
                      <Text.Heading1Regular
                        fontSize="18px"
                        lineHeight={160}
                        marginBottom={20}
                      >
                        Terrorismo e seu financiamento;
                      </Text.Heading1Regular>
                    </li>
                    <li>
                      <Text.Heading1Regular
                        fontSize="18px"
                        lineHeight={160}
                        marginBottom={20}
                      >
                        Contrabando ou tráfico ilícito de armas e munições, de
                        material destinado à sua produção e de substâncias
                        ilegais;
                      </Text.Heading1Regular>
                    </li>
                    <li>
                      <Text.Heading1Regular
                        fontSize="18px"
                        lineHeight={160}
                        marginBottom={20}
                      >
                        Extorsão mediante sequestro;
                      </Text.Heading1Regular>
                    </li>
                    <li>
                      <Text.Heading1Regular
                        fontSize="18px"
                        lineHeight={160}
                        marginBottom={20}
                      >
                        Crime contra o Sistema Financeiro Nacional.
                      </Text.Heading1Regular>
                    </li>
                    <li>
                      <Text.Heading1Regular
                        fontSize="18px"
                        lineHeight={160}
                        marginBottom={20}
                      >
                        Shell Banking (Instituição Financeira sem presença
                        física em uma jurisdição).
                      </Text.Heading1Regular>
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer backgroundColor={colors.whiteLight} />
    </Style.Container>
  );
}

export default Kyc;
