import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '~/styles';

export const Container = styled.div`

  .content {
    padding: 60px 0;

    background-color: ${colors.white};

    .description-container {
      border-top: 1px solid ${transparentize(0.9, colors.black)};
      padding: 30px 0; 

      &:last-child {
        border-bottom: 1px solid ${transparentize(0.9, colors.black)};
      }
    }

    @media (max-width: 900px) {
      .description-container {
        .description-container-title {
          margin-bottom: 30px;
        }
      }
    }
  }

  .description-content {
      p {
        white-space: pre-wrap;
      }
    }

  .link-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 387px;
    height: 66px;
    padding: 20px 50px;
    background-color: #026FF4;
    color: ${colors.white};
    border-radius: 200px;
  }
`;

export const Header = styled.div`
  background-color: #026FF4;

  .banner {
    margin-top: 80px;

    padding: 96px 0;

    .header-icon-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 10px;

      img {
        margin-right: 13px;
        filter: brightness(0) invert(1);
      }
    }
  }
`;

export const WorkWithUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 120px 0;
  background-color: ${colors.white};
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  .copy-text-container {
    width: 50%;
    margin: 60px 0;
    padding: 30px;
    border: 1px solid ${transparentize(0.9, colors.black)};
    border-radius: 13px;

    hr {
      color: ${transparentize(0.7, colors.black)};
      margin: 30px 0;
    }

    .copy-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .right-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .link {
          color: ${colors.newBlue};
          text-decoration: underline;
        }

        .copy-button {
          margin-left: 25px;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.2);
            transition: all ease 0.2s;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .copy-text-container {
      width: 90%;
    }
  }

  @media (max-width: 700px) {
  .copy-content {
      .right-container {
        width: 70%;

        .copy-button {
          margin-left: 0px;
        }
      }
    }
  }
`;