import styled from 'styled-components';

import AppBackgroundImage from "~/images/portfolio/precatorio/app-background-banner.png";

export const DownloadAppContainer = styled.div`
  height: 474px;
  background: ${`url(${AppBackgroundImage})`};
  background-size: cover;
  background-position: 0 80%;

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @media (max-width: 991px) {
    .center-container {
      align-items: center;
      justify-content: center;

      padding-left: 25px;
      padding-right: 25px;

      p {
        text-align: center;
      }
    }
  }

  .downloadapp-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 37px;

    .downloadapp-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .downloadapp-item-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        margin-left: 8px;

        p {
          margin-bottom: 3.44px;
          margin-left: 3px;
        }
      }
    }
  }
`;