import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import App from './App';
import Home from './pages/Home/Home';
import Precatorio from './pages/Portfolio/Precatorio';
import Careers from './pages/Careers/1.Careers';
import DetailsVacancy from './pages/Careers/2.DetailsVacancy';
import {
  Kyc,
  ExercicioDosDireitosDosTitulares,
  NegociosProibidos,
  PoliticaPrivacidade,
  TabelaDeTemporalidade,
  TermosDeUso,
} from './pages/Legal';
import { CapOtc } from './pages/Products';
import PoliticaCookie from './pages/Legal/PoliticaCookie/PoliticaCookie';

export default function Routes() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={App} />
          <Route path="/precatorio" component={Precatorio} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/careers/:id" component={DetailsVacancy} />
          <Route path="/legal/kyc" component={Kyc} />
          <Route path="/legal/exercise-of-the-rights-of-the-data-subjects" component={ExercicioDosDireitosDosTitulares} />
          <Route path="/legal/terms-of-use" component={TermosDeUso} />
          <Route
            path="/legal/prohibited-businesses"
            component={NegociosProibidos}
          />
          <Route path="/legal/privacy-policy" component={PoliticaPrivacidade} />
          <Route path="/legal/cookie-policy" component={PoliticaCookie} />
          <Route
            path="/legal/temporality-table"
            component={TabelaDeTemporalidade}
          />
          <Route path="/products/capotc" component={CapOtc} />
          <Route path="/*" component={App} />
        </Switch>
      </BrowserRouter>
    </ParallaxProvider>
  );
}
